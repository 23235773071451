@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,600,700&display=swap);
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: #1890ff;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}
.clearfix::after {
  clear: both;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1890ff;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 0 #1890ff;
          box-shadow: 0 0 0 0 #1890ff;
  -webkit-box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
          box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 #1890ff;
            box-shadow: 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 #1890ff;
            box-shadow: 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
.swing-enter,
.swing-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.swing-enter.swing-enter-active,
.swing-appear.swing-appear-active {
  -webkit-animation-name: antSwingIn;
          animation-name: antSwingIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
@keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-back-top {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-spin {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #1890ff;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
          transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    -ms-flex: 1;
        flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-close {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper {
  zoom: 1;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active,
.ant-btn.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
.ant-btn:active > a:only-child,
.ant-btn.active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after,
.ant-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-disabled > a:only-child,
.ant-btn.disabled > a:only-child,
.ant-btn[disabled] > a:only-child,
.ant-btn-disabled:hover > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn-disabled:focus > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn-disabled:active > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn-disabled.active > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-disabled > a:only-child::after,
.ant-btn.disabled > a:only-child::after,
.ant-btn[disabled] > a:only-child::after,
.ant-btn-disabled:hover > a:only-child::after,
.ant-btn.disabled:hover > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn-disabled:focus > a:only-child::after,
.ant-btn.disabled:focus > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn-disabled:active > a:only-child::after,
.ant-btn.disabled:active > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after,
.ant-btn-disabled.active > a:only-child::after,
.ant-btn.disabled.active > a:only-child::after,
.ant-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > i,
.ant-btn > span {
  display: inline-block;
  -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}
.ant-btn-primary {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary:active > a:only-child,
.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after,
.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-primary-disabled > a:only-child,
.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: #40a9ff;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9;
}
.ant-btn-ghost:active > a:only-child,
.ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after,
.ant-btn-ghost.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost-disabled,
.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-ghost-disabled > a:only-child,
.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost-disabled:hover > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost-disabled:focus > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost-disabled:active > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost-disabled.active > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost-disabled > a:only-child::after,
.ant-btn-ghost.disabled > a:only-child::after,
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost-disabled:hover > a:only-child::after,
.ant-btn-ghost.disabled:hover > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost-disabled:focus > a:only-child::after,
.ant-btn-ghost.disabled:focus > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost-disabled:active > a:only-child::after,
.ant-btn-ghost.disabled:active > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after,
.ant-btn-ghost-disabled.active > a:only-child::after,
.ant-btn-ghost.disabled.active > a:only-child::after,
.ant-btn-ghost[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
.ant-btn-dashed:active > a:only-child,
.ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after,
.ant-btn-dashed.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed-disabled,
.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-dashed-disabled > a:only-child,
.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed-disabled:hover > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed-disabled:focus > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed-disabled:active > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed-disabled.active > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed-disabled > a:only-child::after,
.ant-btn-dashed.disabled > a:only-child::after,
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed-disabled:hover > a:only-child::after,
.ant-btn-dashed.disabled:hover > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed-disabled:focus > a:only-child::after,
.ant-btn-dashed.disabled:focus > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed-disabled:active > a:only-child::after,
.ant-btn-dashed.disabled:active > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after,
.ant-btn-dashed-disabled.active > a:only-child::after,
.ant-btn-dashed.disabled.active > a:only-child::after,
.ant-btn-dashed[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background-color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: #fff;
  background-color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after,
.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-danger-disabled > a:only-child,
.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #1890ff;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: #40a9ff;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9;
}
.ant-btn-link:active > a:only-child,
.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after,
.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn-icon-only > i {
  vertical-align: middle;
}
.ant-btn-round {
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
  margin-left: -14px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
.ant-btn-group {
  position: relative;
  display: inline-block;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > .ant-btn.active,
.ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group > .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
  line-height: 38px;
}
.ant-btn-group-lg > .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 22px;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn-group-sm > .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  background-color: transparent;
  border-color: #1890ff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: #40a9ff;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  background-color: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  background-color: transparent;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: #d9363e;
  background-color: transparent;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link {
  color: #1890ff;
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  color: #fff;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-link:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:active,
.ant-btn-background-ghost.ant-btn-link.active {
  color: #096dd9;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link-disabled,
.ant-btn-background-ghost.ant-btn-link.disabled,
.ant-btn-background-ghost.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-link-disabled:hover,
.ant-btn-background-ghost.ant-btn-link.disabled:hover,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-link-disabled:focus,
.ant-btn-background-ghost.ant-btn-link.disabled:focus,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-link-disabled:active,
.ant-btn-background-ghost.ant-btn-link.disabled:active,
.ant-btn-background-ghost.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-link-disabled.active,
.ant-btn-background-ghost.ant-btn-link.disabled.active,
.ant-btn-background-ghost.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  vertical-align: top;
}
a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-message {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice:first-child {
  margin-top: -8px;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #f5222d;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  -webkit-animation-name: MessageMoveOut;
          animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tooltip {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(-6.53553391px) rotate(45deg);
      -ms-transform: translateY(-6.53553391px) rotate(45deg);
          transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
  -webkit-box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(6.53553391px) rotate(45deg);
      -ms-transform: translateX(6.53553391px) rotate(45deg);
          transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(-6.53553391px) rotate(45deg);
      -ms-transform: translateX(-6.53553391px) rotate(45deg);
          transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
  -webkit-box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(6.53553391px) rotate(45deg);
      -ms-transform: translateY(6.53553391px) rotate(45deg);
          transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
.ant-dropdown-wrap .anticon-down::before {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
  padding: 0;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #1890ff;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-trigger > .anticon.anticon-down,
:root .ant-dropdown-link > .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-breadcrumb {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #40a9ff;
}
.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  list-style: none;
  background: #fff;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transition: background 0.3s, width 0.2s;
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
.ant-menu::before,
.ant-menu::after {
  display: table;
  content: '';
}
.ant-menu::after {
  clear: both;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  -webkit-transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6f7ff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item > a:hover {
  color: #1890ff;
}
.ant-menu-item > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge > a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item > .ant-badge > a:hover {
  color: #1890ff;
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #1890ff;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #1890ff;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #1890ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  padding: 0;
  border-right: 0;
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: #fff;
  border-radius: 4px;
}
.ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 4px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background: #fff;
  background: rgba(0, 0, 0, 0.65) \9;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  background-image: none \9;
  border-radius: 2px;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateY(-2px);
      -ms-transform: rotate(45deg) translateY(-2px);
          transform: rotate(45deg) translateY(-2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateY(2px);
      -ms-transform: rotate(-45deg) translateY(2px);
          transform: rotate(-45deg) translateY(2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from(#1890ff), to(#1890ff));
  background: linear-gradient(to right, #1890ff, #1890ff);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateX(2px);
      -ms-transform: rotate(-45deg) translateX(2px);
          transform: rotate(-45deg) translateX(2px);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateX(-2px);
      -ms-transform: rotate(45deg) translateX(-2px);
          transform: rotate(45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  -webkit-transform: translateY(-2px);
      -ms-transform: translateY(-2px);
          transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateX(-2px);
      -ms-transform: rotate(-45deg) translateX(-2px);
          transform: rotate(-45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateX(2px);
      -ms-transform: rotate(45deg) translateX(2px);
          transform: rotate(45deg) translateX(2px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #1890ff;
}
.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #1890ff;
}
.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #1890ff;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  -webkit-transform: scaleY(0.0001);
      -ms-transform: scaleY(0.0001);
          transform: scaleY(0.0001);
  opacity: 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
          transform: scaleY(1);
  opacity: 1;
  -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
      flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  -ms-flex-direction: row;
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-header,
.ant-layout-footer {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  -ms-flex: auto;
      flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  -ms-flex-order: 1;
      order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e;
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 4px 0 0 4px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}

.sendbird-word {
  display: inline;
  height: -moz-fit-content;
  height: fit-content;
}

.sendbird-word__url {
  display: inline;
  color: inherit;
  word-break: break-all;
}
.sendbird-openchannel-conversation-header {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-openchannel-conversation-header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-openchannel-conversation-header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-openchannel-conversation-header .sendbird-openchannel-conversation-header__left__title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
}

.sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  position: absolute;
  top: 16px;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  left: 20px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  right: 20px;
}

.sendbird-openchannel-conversation-header__left__title {
  position: absolute;
  top: 12px;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__left__title {
  left: 60px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__left__title {
  right: 60px;
}

.sendbird-openchannel-conversation-header__left__sub-title {
  position: absolute;
  top: 36px;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__left__sub-title {
  left: 60px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__left__sub-title {
  right: 60px;
}

.sendbird-openchannel-conversation-header__right {
  position: absolute;
  top: 0px;
  width: 64px;
  height: 64px;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__right {
  right: 0px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__right {
  left: 0px;
}

.sendbird-openchannel-conversation-header__right__trigger {
  position: absolute;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__right__trigger {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__right__trigger {
  left: 16px;
}

.sendbird-openchannel-conversation-header__left__cover-image--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-openchannel-conversation-header__left__cover-image--icon {
  background-color: var(--sendbird-light-background-300);
}

.sendbird-theme--dark .sendbird-openchannel-conversation-header__left__cover-image--icon {
  background-color: var(--sendbird-dark-background-300);
}
.sendbird-open-channel-preview {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 264px;
  height: 58px;
  min-width: 264px;
  min-height: 58px;
  box-sizing: border-box;
}

.sendbird--mobile-mode .sendbird-open-channel-preview {
  width: 100%;
}

.sendbird-theme--light .sendbird-open-channel-preview {
  background-color: var(--sendbird-light-background-50);
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-open-channel-preview {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-open-channel-preview:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-open-channel-preview:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-open-channel-preview.selected {
  background-color: var(--sendbird-light-primary-100);
}

[dir="ltr"] .sendbird-theme--light .sendbird-open-channel-preview.selected {
  border-left: solid 4px var(--sendbird-light-primary-300);
}

[dir="rtl"] .sendbird-theme--light .sendbird-open-channel-preview.selected {
  border-right: solid 4px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-open-channel-preview.selected {
  background-color: var(--sendbird-dark-primary-300);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-open-channel-preview.selected {
  border-left: solid 4px var(--sendbird-dark-primary-200);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-open-channel-preview.selected {
  border-right: solid 4px var(--sendbird-dark-primary-200);
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__cover-image {
  position: relative;
  margin-top: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-open-channel-preview .sendbird-open-channel-preview__cover-image {
  margin-left: 16px;
}

[dir="rtl"] .sendbird-open-channel-preview .sendbird-open-channel-preview__cover-image {
  margin-right: 16px;
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__cover-image .sendbird-open-channel-preview__cover-image__avatar,
.sendbird-open-channel-preview .sendbird-open-channel-preview__cover-image .sendbird-open-channel-preview__cover-image__avatar--default {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__context {
  position: relative;
  margin-top: 8px;
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 100px);
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-open-channel-preview .sendbird-open-channel-preview__context {
  margin-left: 16px;
}

[dir="rtl"] .sendbird-open-channel-preview .sendbird-open-channel-preview__context {
  margin-right: 16px;
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__title {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 16px;
  box-sizing: border-box;
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__title .sendbird-open-channel-preview__context__title__channel-name {
  position: relative;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.sendbird--mobile-mode .sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__title .sendbird-open-channel-preview__context__title__channel-name {
  overflow-y: hidden;
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__title .sendbird-open-channel-preview__context__title__channel-name.frozen {
  max-width: calc(100% - 20px);
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__title .sendbird-open-channel-preview__context__title__frozen {
  position: relative;
  display: inline-flex;
}

[dir="ltr"] .sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__title .sendbird-open-channel-preview__context__title__frozen {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__title .sendbird-open-channel-preview__context__title__frozen {
  margin-right: 4px;
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__participants {
  position: relative;
  margin-top: 8px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 14px;
  box-sizing: border-box;
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__participants .sendbird-open-channel-preview__context__participants__icon {
  position: relative;
  display: inline-flex;
  width: 14px;
  height: 14px;
  box-sizing: border-box;
}

.sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__participants .sendbird-open-channel-preview__context__participants__count {
  position: relative;
  display: inline-flex;
  width: 160px;
  max-width: 160px;
  height: 12px;
  box-sizing: border-box;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}

[dir="ltr"] .sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__participants .sendbird-open-channel-preview__context__participants__count {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__participants .sendbird-open-channel-preview__context__participants__count {
  margin-right: 4px;
}

.sendbird--mobile-mode .sendbird-open-channel-preview .sendbird-open-channel-preview__context .sendbird-open-channel-preview__context__participants .sendbird-open-channel-preview__context__participants__count {
  overflow-y: hidden;
}
.sendbird-message-item-menu__list__menu-item:focus-visible {
  outline: none;
}
.sendbird-create-open-channel-ui {
  position: relative;
  display: inline-flex;
}

.sendbird-create-open-channel-ui__profile-input {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__img-section {
  position: relative;
  margin-top: 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__img-section .sendbird-create-open-channel-ui__profile-input__img-section__avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__img-section .sendbird-create-open-channel-ui__profile-input__img-section__avatar--default {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__img-section .sendbird-create-open-channel-ui__profile-input__img-section__avatar--default {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__img-section .sendbird-create-open-channel-ui__profile-input__img-section__avatar--default {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__img-section .sendbird-create-open-channel-ui__profile-input__img-section__button {
  position: relative;
  display: inline-flex;
}

[dir="ltr"] .sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__img-section .sendbird-create-open-channel-ui__profile-input__img-section__button {
  margin-left: 18px;
}

[dir="rtl"] .sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__img-section .sendbird-create-open-channel-ui__profile-input__img-section__button {
  margin-right: 18px;
}

.sendbird-create-open-channel-ui__profile-input .sendbird-create-open-channel-ui__profile-input__name-section {
  position: relative;
  margin-top: 8px;
  display: inline-flex;
  flex-direction: row;
}
.sendbird-open-channel-list,
.sendbird-open-channel-list-ui {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 256px;
  min-width: 256px;
  height: 100%;
  box-sizing: border-box;
}

.sendbird--mobile-mode .sendbird-open-channel-list,
.sendbird--mobile-mode .sendbird-open-channel-list-ui {
  width: 100%;
}

[dir="ltr"] .sendbird-theme--light .sendbird-open-channel-list,
[dir="ltr"] .sendbird-theme--light .sendbird-open-channel-list-ui {
  border-right: solid 1px var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-open-channel-list,
[dir="rtl"] .sendbird-theme--light .sendbird-open-channel-list-ui {
  border-left: solid 1px var(--sendbird-light-onlight-04);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-open-channel-list,
[dir="ltr"] .sendbird-theme--dark .sendbird-open-channel-list-ui {
  border-right: solid 1px var(--sendbird-dark-ondark-04);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-open-channel-list,
[dir="rtl"] .sendbird-theme--dark .sendbird-open-channel-list-ui {
  border-left: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird-open-channel-list .sendbird-open-channel-list-ui__header,
.sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 64px;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-open-channel-list .sendbird-open-channel-list-ui__header,
.sendbird-theme--light .sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header {
  background-color: var(--sendbird-light-background-50);
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-open-channel-list .sendbird-open-channel-list-ui__header,
.sendbird-theme--dark .sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__title,
.sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__title {
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__title,
[dir="ltr"] .sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__title {
  margin-left: 24px;
}

[dir="rtl"] .sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__title,
[dir="rtl"] .sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__title {
  margin-right: 24px;
}

.sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-refresh,
.sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-refresh {
  position: absolute;
  top: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-refresh,
[dir="ltr"] .sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-refresh {
  right: 56px;
}

[dir="rtl"] .sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-refresh,
[dir="rtl"] .sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-refresh {
  left: 56px;
}

.sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-create-channel,
.sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-create-channel {
  position: absolute;
  top: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-create-channel,
[dir="ltr"] .sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-create-channel {
  right: 16px;
}

[dir="rtl"] .sendbird-open-channel-list .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-create-channel,
[dir="rtl"] .sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__header .sendbird-open-channel-list-ui__header__button-create-channel {
  left: 16px;
}

.sendbird-open-channel-list .sendbird-open-channel-list-ui__channel-list,
.sendbird-open-channel-list-ui .sendbird-open-channel-list-ui__channel-list {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 64px);
  box-sizing: border-box;
  overflow-y: scroll;
}

.sendbird-open-channel-list-ui__channel-list--place-holder--loading {
  position: relative;
  min-height: 62px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sendbird-theme--light .sendbird-open-channel-list-ui__channel-list--place-holder--loading {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-open-channel-list-ui__channel-list--place-holder--loading {
  background-color: var(--sendbird-dark-background-500);
}

:root {
  --sendbird-light-primary-500: #491389;
  --sendbird-light-primary-400: #6211c8;
  --sendbird-light-primary-300: #742ddd;
  --sendbird-light-primary-200: #c2a9fa;
  --sendbird-light-primary-100: #dbd1ff;
  --sendbird-light-secondary-500: #066858;
  --sendbird-light-secondary-400: #027d69;
  --sendbird-light-secondary-300: #259c72;
  --sendbird-light-secondary-200: #69c085;
  --sendbird-light-secondary-100: #a8e2ab;
  --sendbird-light-information-100: #adc9ff;
  --sendbird-highlight-100: #fff2b6;
  --sendbird-light-error-500: #9d091e;
  --sendbird-light-error-400: #bf0711;
  --sendbird-light-error-300: #de360b;
  --sendbird-light-error-200: #f66161;
  --sendbird-light-error-100: #fdaaaa;
  --sendbird-light-background-700: #000000;
  --sendbird-light-background-600: #161616;
  --sendbird-light-background-500: #2C2C2C;
  --sendbird-light-background-400: #393939;
  --sendbird-light-background-300: #bdbdbd;
  --sendbird-light-background-200: #e0e0e0;
  --sendbird-light-background-100: #eeeeee;
  --sendbird-light-background-50: #FFFFFF;
  --sendbird-light-overlay-01: rgba(0, 0, 0, 0.55);
  --sendbird-light-overlay-02: rgba(0, 0, 0, 0.32);
  --sendbird-light-onlight-01: rgba(0, 0, 0, 0.88);
  --sendbird-light-onlight-02: rgba(0, 0, 0, 0.50);
  --sendbird-light-onlight-03: rgba(0, 0, 0, 0.38);
  --sendbird-light-onlight-04: rgba(0, 0, 0, 0.12);
  --sendbird-light-ondark-01: rgba(255, 255, 255, 0.88);
  --sendbird-light-ondark-02: rgba(255, 255, 255, 0.50);
  --sendbird-light-ondark-03: rgba(255, 255, 255, 0.38);
  --sendbird-light-ondark-04: rgba(255, 255, 255, 0.12);
  --sendbird-light-shadow-01: 0 1px 5px 0 rgba(33, 34, 66, 0.04), 0 0 3px 0 rgba(0, 0, 0, 0.08), 0 2px 1px 0 rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-02: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-03: 0 6px 10px -5px rgba(0, 0, 0, 0.04), 0 6px 30px 5px rgba(0, 0, 0, 0.08), 0 16px 24px 2px rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-04: 0 9px 15px -7px rgba(0, 0, 0, 0.04), 0 9px 46px 8px rgba(0, 0, 0, 0.08), 0 24px 38px 3px rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-05: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 4px 6px 0 rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-message-input: 0 1px 5px 0 rgba(33, 34, 66, 0.12), 0 0 1px 0 rgba(33, 34, 66, 0.16), 0 2px 1px 0 rgba(33, 34, 66, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

:root {
  --sendbird-dark-primary-500: #491389;
  --sendbird-dark-primary-400: #6211c8;
  --sendbird-dark-primary-300: #742ddd;
  --sendbird-dark-primary-200: #c2a9fa;
  --sendbird-dark-primary-100: #dbd1ff;
  --sendbird-dark-secondary-500: #066858;
  --sendbird-dark-secondary-400: #027d69;
  --sendbird-dark-secondary-300: #259c72;
  --sendbird-dark-secondary-200: #69c085;
  --sendbird-dark-secondary-100: #a8e2ab;
  --sendbird-dark-information-100: #adc9ff;
  --sendbird-highlight-100: #fff2b6;
  --sendbird-dark-error-500: #9d091e;
  --sendbird-dark-error-400: #bf0711;
  --sendbird-dark-error-300: #de360b;
  --sendbird-dark-error-200: #f66161;
  --sendbird-dark-error-100: #fdaaaa;
  --sendbird-dark-background-700: #000000;
  --sendbird-dark-background-600: #161616;
  --sendbird-dark-background-500: #2C2C2C;
  --sendbird-dark-background-400: #393939;
  --sendbird-dark-background-300: #bdbdbd;
  --sendbird-dark-background-200: #e0e0e0;
  --sendbird-dark-background-100: #eeeeee;
  --sendbird-dark-background-50: #FFFFFF;
  --sendbird-dark-overlay-01: rgba(0, 0, 0, 0.55);
  --sendbird-dark-overlay-02: rgba(0, 0, 0, 0.32);
  --sendbird-dark-onlight-01: rgba(0, 0, 0, 0.88);
  --sendbird-dark-onlight-02: rgba(0, 0, 0, 0.50);
  --sendbird-dark-onlight-03: rgba(0, 0, 0, 0.38);
  --sendbird-dark-onlight-04: rgba(0, 0, 0, 0.12);
  --sendbird-dark-ondark-01: rgba(255, 255, 255, 0.88);
  --sendbird-dark-ondark-02: rgba(255, 255, 255, 0.50);
  --sendbird-dark-ondark-03: rgba(255, 255, 255, 0.38);
  --sendbird-dark-ondark-04: rgba(255, 255, 255, 0.12);
  --sendbird-dark-shadow-01: 0 1px 5px 0 rgba(33, 34, 66, 0.04), 0 0 3px 0 rgba(0, 0, 0, 0.08), 0 2px 1px 0 rgba(0, 0, 0, 0.12);
  --sendbird-dark-shadow-02: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12);
  --sendbird-dark-shadow-03: 0 6px 10px -5px rgba(0, 0, 0, 0.04), 0 6px 30px 5px rgba(0, 0, 0, 0.08), 0 16px 24px 2px rgba(0, 0, 0, 0.12);
  --sendbird-dark-shadow-04: 0 9px 15px -7px rgba(0, 0, 0, 0.04), 0 9px 46px 8px rgba(0, 0, 0, 0.08), 0 24px 38px 3px rgba(0, 0, 0, 0.12);
  --sendbird-light-shadow-05: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 4px 6px 0 rgba(0, 0, 0, 0.12);
  --sendbird-dark-shadow-message-input: 0 1px 5px 0 rgba(33, 34, 66, 0.12), 0 0 1px 0 rgba(33, 34, 66, 0.16), 0 2px 1px 0 rgba(33, 34, 66, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

:root {
  --sendbird-iconbutton-color: #825eeb;
  --sendbird-message-input-border-active: #7B53EF;
  --sendbird-selected-reaction-button-border-hover: #9E8CF5;
  --sendbird-add-reaction-button-border-hover: #9E8CF5;
  --sendbird-tooltip-background: #000000;
  --sendbird-tooltip-text-color: rgba(255, 255, 255, 0.88);
  --sendbird-button-border-focus: #FFFFFF;
  --sendbird-file-message-icon-background: #FFFFFF;
  --sendbird-font-family-default: "Roboto", sans-serif;
  --sendbird-message-balloon-width: 404px;
}

.sendbird-message-content .sendbird-avatar-img {
  top: 50%;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content .sendbird-avatar-img {
  left: 50%;
  transform: translate(-50%, -50%);
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content .sendbird-avatar-img {
  right: 50%;
  transform: translate(50%, -50%);
}

.sendbird-message-content .sendbird-voice-message-item-body__playback-time {
  position: absolute;
  top: 15px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content .sendbird-voice-message-item-body__playback-time {
  right: 12px;
  left: unset;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content .sendbird-voice-message-item-body__playback-time {
  left: 12px;
  right: unset;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content .sendbird-reaction-badge__inner {
  padding-left: 20px;
  padding-right: 4px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content .sendbird-reaction-badge__inner {
  padding-right: 20px;
  padding-left: 4px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  left: 4px;
  right: unset;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  right: 4px;
  left: unset;
}

.sendbird-thread-list-item-content .sendbird-avatar-img {
  top: 50%;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content .sendbird-avatar-img {
  left: 50%;
  transform: translate(-50%, -50%);
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content .sendbird-avatar-img {
  right: 50%;
  transform: translate(50%, -50%);
}

.sendbird-thread-list-item-content .sendbird-voice-message-item-body__playback-time {
  position: absolute;
  top: 15px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content .sendbird-voice-message-item-body__playback-time {
  right: 12px;
  left: unset;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content .sendbird-voice-message-item-body__playback-time {
  left: 12px;
  right: unset;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content .sendbird-reaction-badge__inner {
  padding-left: 20px;
  padding-right: 4px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content .sendbird-reaction-badge__inner {
  padding-right: 20px;
  padding-left: 4px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  left: 4px;
  right: unset;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  right: 4px;
  left: unset;
}

.sendbird-parent-message-info .sendbird-avatar-img {
  top: 50%;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info .sendbird-avatar-img {
  left: 50%;
  transform: translate(-50%, -50%);
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info .sendbird-avatar-img {
  right: 50%;
  transform: translate(50%, -50%);
}

.sendbird-parent-message-info .sendbird-voice-message-item-body__playback-time {
  position: absolute;
  top: 15px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info .sendbird-voice-message-item-body__playback-time {
  right: 12px;
  left: unset;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info .sendbird-voice-message-item-body__playback-time {
  left: 12px;
  right: unset;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info .sendbird-reaction-badge__inner {
  padding-left: 20px;
  padding-right: 4px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info .sendbird-reaction-badge__inner {
  padding-right: 20px;
  padding-left: 4px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  left: 4px;
  right: unset;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  right: 4px;
  left: unset;
}
.sendbird-experimental__rem__units .sendbird-label--h-1 {
  font-size: 1.25rem;
}

.sendbird-experimental__rem__units .sendbird-label--h-2 {
  font-size: 1.125rem;
}

.sendbird-experimental__rem__units .sendbird-label--subtitle-1 {
  font-size: 1rem;
}

.sendbird-experimental__rem__units .sendbird-label--subtitle-2 {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-label--body-1 {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-label--body-2 {
  font-size: 0.75rem;
}

.sendbird-experimental__rem__units .sendbird-label--button-1 {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-label--button-2 {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-label--caption-1 {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-label--caption-2 {
  font-size: 0.75rem;
}

.sendbird-experimental__rem__units .sendbird-label--caption-3 {
  font-size: 0.75rem;
}

.sendbird-experimental__rem__units .sendbird-message-search-pannel .sendbird-message-search-pannel__input__container__input-area {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-checkbox {
  font-size: 1.375rem;
}

.sendbird-experimental__rem__units .sendbird-mention-user-label {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-mention-user-label.purple {
  font-size: 1.125rem;
}

.sendbird-experimental__rem__units .sendbird-message-input .sendbird-message-input--textarea,
.sendbird-experimental__rem__units .sendbird-message-input .sendbird-message-input--placeholder {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-input .sendbird-input__input,
.sendbird-experimental__rem__units .sendbird-input .sendbird-input__placeholder {
  font-size: 0.875rem;
}

.sendbird-experimental__rem__units .sendbird-tooltip__text {
  font-size: 0.75rem;
}

.sendbird-experimental__rem__units .sendbird-quote-message .sendbird-quote-message__replied-to .sendbird-quote-message__replied-to__text {
  font-size: 0.75rem;
}

.sendbird-experimental__rem__units .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__text-message {
  font-size: 0.75rem;
}

.sendbird-experimental__rem__units .sendbird-quote-message .sendbird-quote-message__replied-message__file-message {
  font-size: 0.75rem;
}
.sendbird-modal--full-mobile .sendbird-modal__content {
  width: 100vw;
  max-width: 100%;
  height: 100%;
}

.sendbird-modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir="ltr"] .sendbird-modal {
  left: 0;
}

[dir="rtl"] .sendbird-modal {
  right: 0;
}

.sendbird-modal__backdrop--clickoutside {
  cursor: pointer;
}

.sendbird-modal__content {
  width: 480px;
  border-radius: 4px;
  z-index: 10001;
  padding: 18px 24px 24px 24px;
  position: relative;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-modal__content {
  background-color: var(--sendbird-light-background-50);
  box-shadow: var(--sendbird-light-shadow-04);
}

.sendbird-theme--dark .sendbird-modal__content {
  background-color: var(--sendbird-dark-background-500);
  box-shadow: var(--sendbird-dark-shadow-04);
}

.sendbird-modal__content .sendbird-modal__close {
  position: absolute;
  top: 16px;
  cursor: pointer;
}

[dir="ltr"] .sendbird-modal__content .sendbird-modal__close {
  right: 16px;
}

[dir="rtl"] .sendbird-modal__content .sendbird-modal__close {
  left: 16px;
}

.sendbird-theme--light .sendbird-modal__content .sendbird-modal__close path {
  fill: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-modal__content .sendbird-modal__close path {
  fill: var(--sendbird-dark-ondark-01);
}

.sendbird-modal__content .sendbird-modal__header {
  margin-bottom: 16px;
}

.sendbird-modal__content .sendbird-modal__body {
  height: calc(100% - 116px);
  overflow: auto;
}

.sendbird-modal__content .sendbird-modal__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 32px;
}

[dir="ltr"] .sendbird-modal__content .sendbird-modal__footer {
  text-align: right;
}

[dir="rtl"] .sendbird-modal__content .sendbird-modal__footer {
  text-align: left;
}

.sendbird--mobile-mode .sendbird-modal__content .sendbird-modal__footer {
  position: sticky;
  bottom: 24px;
}

.sendbird-modal__content .sendbird-modal__footer .sendbird-button:last-child {
  min-width: 80px;
  height: 40px;
}

[dir="ltr"] .sendbird-modal__content .sendbird-modal__footer .sendbird-button:last-child {
  margin-left: 8px;
}

[dir="rtl"] .sendbird-modal__content .sendbird-modal__footer .sendbird-button:last-child {
  margin-right: 8px;
}

.sendbird-modal__backdrop {
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  top: 0;
}

[dir="ltr"] .sendbird-modal__backdrop {
  left: 0;
}

[dir="rtl"] .sendbird-modal__backdrop {
  right: 0;
}

.sendbird-theme--light .sendbird-modal__backdrop {
  background-color: var(--sendbird-light-overlay-02);
}

.sendbird-theme--dark .sendbird-modal__backdrop {
  background-color: var(--sendbird-dark-overlay-02);
}
.sendbird-iconbutton {
  color: var(--sendbird-iconbutton-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  background-color: transparent;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: 0px;
}

.sendbird-iconbutton .sendbird-iconbutton__inner {
  height: 100%;
}

.sendbird-theme--light .sendbird-iconbutton .sendbird-iconbutton__inner svg {
  fill: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-iconbutton .sendbird-iconbutton__inner svg {
  fill: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-iconbutton:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-iconbutton:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-iconbutton:active {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-iconbutton:active {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-iconbutton:disabled {
  cursor: not-allowed !important;
}

.sendbird-theme--light .sendbird-iconbutton:disabled .sendbird-iconbutton__inner svg, .sendbird-theme--light .sendbird-iconbutton:disabled .sendbird-iconbutton__inner path {
  fill: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-iconbutton:disabled .sendbird-iconbutton__inner svg, .sendbird-theme--dark .sendbird-iconbutton:disabled .sendbird-iconbutton__inner path {
  fill: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-iconbutton--pressed {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-iconbutton--pressed {
  background-color: var(--sendbird-dark-background-700);
}
.sendbird-button {
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  font-family: var(--sendbird-font-family-default);
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.sendbird-button:hover {
  box-shadow: none;
}

.sendbird-button:focus {
  outline: none;
}

.sendbird-button:active {
  box-shadow: none;
}

.sendbird-button.sendbird-button__disabled {
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-button.sendbird-button__disabled {
  background-color: var(--sendbird-light-background-200);
  border: none;
}

.sendbird-theme--dark .sendbird-button.sendbird-button__disabled {
  background-color: var(--sendbird-dark-background-400);
  border: none;
}

.sendbird-theme--light .sendbird-button.sendbird-button__disabled:hover {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-button.sendbird-button__disabled:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-button--big {
  height: 40px;
}

.sendbird-button--big .sendbird-button__text {
  margin: 10px 16px;
}

.sendbird-button--small {
  height: 32px;
}

.sendbird-button--small .sendbird-button__text {
  margin: 6px 16px;
}

.sendbird-theme--light .sendbird-button--primary {
  background-color: var(--sendbird-light-primary-300);
  border: 1px solid var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-button--primary {
  background-color: var(--sendbird-dark-primary-200);
  border: 1px solid var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-button--primary .sendbird-button__text {
  color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-button--primary .sendbird-button__text {
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-button--primary:hover {
  background-color: var(--sendbird-light-primary-400);
  border-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-button--primary:hover {
  background-color: var(--sendbird-dark-primary-300);
  border-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-button--primary:focus {
  border: 1px solid var(--sendbird-button-border-focus);
  box-shadow: 0 0 0 2px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-button--primary:focus {
  border: 1px solid var(--sendbird-button-border-focus);
  box-shadow: 0 0 0 2px var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-button--primary:active {
  background-color: var(--sendbird-light-primary-500);
}

.sendbird-theme--dark .sendbird-button--primary:active {
  background-color: var(--sendbird-dark-primary-400);
}

.sendbird-theme--light .sendbird-button--primary:focus .sendbird-button--primary:hover {
  box-shadow: 0 0 0 2px var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-button--primary:focus .sendbird-button--primary:hover {
  box-shadow: 0 0 0 2px var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-button--primary:focus .sendbird-button--primary:active {
  box-shadow: 0 0 0 2px var(--sendbird-light-primary-500);
}

.sendbird-theme--dark .sendbird-button--primary:focus .sendbird-button--primary:active {
  box-shadow: 0 0 0 2px var(--sendbird-dark-primary-400);
}

.sendbird-button--secondary {
  background-color: transparent;
}

.sendbird-theme--light .sendbird-button--secondary {
  border: 1px solid var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-button--secondary {
  border: 1px solid var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-button--secondary .sendbird-button__text {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-button--secondary .sendbird-button__text {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-button--secondary:hover {
  background-color: transparent;
}

.sendbird-theme--light .sendbird-button--secondary:hover {
  border: 1px solid var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-button--secondary:hover {
  border: 1px solid var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-button--secondary:hover .sendbird-button__text {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-button--secondary:hover .sendbird-button__text {
  color: var(--sendbird-dark-primary-200);
}

.sendbird-button--secondary.sendbird-button__disabled {
  border: none;
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-button--secondary.sendbird-button__disabled {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-button--secondary.sendbird-button__disabled {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-button--secondary.sendbird-button__disabled .sendbird-button__text {
  color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-button--secondary.sendbird-button__disabled .sendbird-button__text {
  color: var(--sendbird-dark-ondark-02);
}

.sendbird-button--secondary.sendbird-button__disabled:hover {
  border: none;
}

.sendbird-theme--light .sendbird-button--secondary.sendbird-button__disabled:hover {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-button--secondary.sendbird-button__disabled:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-button--secondary.sendbird-button__disabled:hover .sendbird-button__text {
  color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-button--secondary.sendbird-button__disabled:hover .sendbird-button__text {
  color: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-button--secondary:hover .sendbird-button--secondary:focus .sendbird-button__text {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-button--secondary:hover .sendbird-button--secondary:focus .sendbird-button__text {
  color: var(--sendbird-dark-primary-200);
}

.sendbird-button--secondary:focus {
  background-color: transparent;
  border: 1px solid transparent;
}

.sendbird-theme--light .sendbird-button--secondary:focus {
  box-shadow: var(--sendbird-light-primary-300) 0 0 0 2px;
}

.sendbird-theme--dark .sendbird-button--secondary:focus {
  box-shadow: var(--sendbird-dark-primary-200) 0 0 0 2px;
}

.sendbird-theme--light .sendbird-button--secondary:focus .sendbird-button__text {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-button--secondary:focus .sendbird-button__text {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-button--secondary:active {
  box-shadow: none;
}

.sendbird-theme--light .sendbird-button--secondary:active {
  border: 1px solid var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-button--secondary:active {
  border: 1px solid var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-button--secondary:active .sendbird-button__text {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-button--secondary:active .sendbird-button__text {
  color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-button--warning {
  background-color: transparent;
  border: 1px solid var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-button--warning {
  background-color: transparent;
  border: 1px solid var(--sendbird-dark-error-200);
}

.sendbird-theme--light .sendbird-button--warning .sendbird-button__text {
  color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-button--warning .sendbird-button__text {
  color: var(--sendbird-dark-error-200);
}

.sendbird-theme--light .sendbird-button--warning:hover {
  border-color: var(--sendbird-light-error-400);
}

.sendbird-theme--dark .sendbird-button--warning:hover {
  border-color: var(--sendbird-dark-error-100);
}

.sendbird-theme--light .sendbird-button--warning:hover .sendbird-button__text {
  color: var(--sendbird-light-error-400);
}

.sendbird-theme--dark .sendbird-button--warning:hover .sendbird-button__text {
  color: var(--sendbird-dark-error-100);
}

.sendbird-theme--light .sendbird-button--warning:focus {
  border-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--light .sendbird-theme--light .sendbird-button--warning:focus .sendbird-button__text {
  color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-theme--light .sendbird-button--warning:focus .sendbird-button__text {
  color: var(--sendbird-dark-error-200);
}

.sendbird-theme--dark .sendbird-button--warning:focus {
  border-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-theme--dark .sendbird-button--warning:focus .sendbird-button__text {
  color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-theme--dark .sendbird-button--warning:focus .sendbird-button__text {
  color: var(--sendbird-dark-error-200);
}

.sendbird-theme--light .sendbird-button--warning:hover .sendbird-button--warning:focus {
  border-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--light .sendbird-theme--light .sendbird-button--warning:hover .sendbird-button--warning:focus .sendbird-button__text {
  color: var(--sendbird-light-error-400);
}

.sendbird-theme--dark .sendbird-theme--light .sendbird-button--warning:hover .sendbird-button--warning:focus .sendbird-button__text {
  color: var(--sendbird-dark-error-100);
}

.sendbird-theme--dark .sendbird-button--warning:hover .sendbird-button--warning:focus {
  border-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-theme--dark .sendbird-button--warning:hover .sendbird-button--warning:focus .sendbird-button__text {
  color: var(--sendbird-light-error-400);
}

.sendbird-theme--dark .sendbird-theme--dark .sendbird-button--warning:hover .sendbird-button--warning:focus .sendbird-button__text {
  color: var(--sendbird-dark-error-100);
}

.sendbird-theme--light .sendbird-button--danger {
  background-color: var(--sendbird-light-error-300);
  border: 1px solid var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-button--danger {
  background-color: var(--sendbird-dark-error-200);
  border: 1px solid var(--sendbird-dark-error-200);
}

.sendbird-theme--light .sendbird-button--danger .sendbird-button__text {
  color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-button--danger .sendbird-button__text {
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-button--danger:hover {
  background-color: var(--sendbird-light-error-400);
  border-color: var(--sendbird-light-error-400);
}

.sendbird-theme--dark .sendbird-button--danger:hover {
  background-color: var(--sendbird-dark-error-400);
  border-color: var(--sendbird-dark-error-400);
}

.sendbird-theme--light .sendbird-button--danger:focus {
  border-color: var(--sendbird-button-border-focus);
  box-shadow: 0 0 0 2px var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-button--danger:focus {
  border-color: var(--sendbird-button-border-focus);
  box-shadow: 0 0 0 2px var(--sendbird-dark-error-300);
}

.sendbird-button--danger:active {
  box-shadow: none;
}

.sendbird-theme--light .sendbird-button--danger:active {
  background-color: var(--sendbird-light-error-500);
}

.sendbird-theme--dark .sendbird-button--danger:active {
  background-color: var(--sendbird-dark-error-500);
}

.sendbird-theme--light .sendbird-button--danger:hover .sendbird-button--danger:focus {
  box-shadow: 0 0 0 2px var(--sendbird-light-error-400);
}

.sendbird-theme--dark .sendbird-button--danger:hover .sendbird-button--danger:focus {
  box-shadow: 0 0 0 2px var(--sendbird-dark-error-400);
}

.sendbird-theme--light .sendbird-button--danger:active .sendbird-button--danger:focus {
  box-shadow: 0 0 0 2px var(--sendbird-light-error-500);
}

.sendbird-theme--dark .sendbird-button--danger:active .sendbird-button--danger:focus {
  box-shadow: 0 0 0 2px var(--sendbird-dark-error-500);
}

.sendbird-button--disabled {
  border: 1px solid transparent;
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-button--disabled {
  background-color: var(--sendbird-light-background-200);
  color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-button--disabled {
  background-color: var(--sendbird-dark-background-400);
  color: var(--sendbird-dark-onlight-01);
}
.sendbird--mobile-mode .sendbird-label {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

[class*=sendbird-label] {
  font-family: var(--sendbird-font-family-default);
}

.sendbird-label--h-1 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.2px;
}

.sendbird-label--h-2 {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
}

.sendbird-label--subtitle-1 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.2px;
}

.sendbird-label--subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.2px;
}

.sendbird-label--body-1 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.sendbird-label--body-2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.sendbird-label--button-1 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.sendbird-label--button-2 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.sendbird-label--button-3 {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.sendbird-label--caption-1 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.sendbird-label--caption-2 {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.sendbird-label--caption-3 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.sendbird-theme--light .sendbird-label--color-onbackground-1 {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-label--color-onbackground-1 {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-label--color-onbackground-2 {
  color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-label--color-onbackground-2 {
  color: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-label--color-onbackground-3 {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-label--color-onbackground-3 {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-label--color-onbackground-4 {
  color: var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-label--color-onbackground-4 {
  color: var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-label--color-oncontent-1 {
  color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-label--color-oncontent-1 {
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-label--color-oncontent-2 {
  color: var(--sendbird-light-ondark-02);
}

.sendbird-theme--dark .sendbird-label--color-oncontent-2 {
  color: var(--sendbird-dark-onlight-02);
}

.sendbird-theme--light .sendbird-label--color-oncontent-3 {
  color: var(--sendbird-light-ondark-03);
}

.sendbird-theme--dark .sendbird-label--color-oncontent-3 {
  color: var(--sendbird-dark-onlight-03);
}

.sendbird-theme--light .sendbird-label--color-oncontent-inverse-1 {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-label--color-oncontent-inverse-1 {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-label--color-oncontent-inverse-3 {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-label--color-oncontent-inverse-3 {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-label--color-primary {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-label--color-primary {
  color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-label--color-error {
  color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-label--color-error {
  color: var(--sendbird-dark-error-200);
}

.sendbird-theme--light .sendbird-label--color-secondary-3 {
  color: var(--sendbird-light-secondary-300);
}

.sendbird-theme--dark .sendbird-label--color-secondary-3 {
  color: var(--sendbird-dark-secondary-200);
}
.sendbird-icon {
  display: inline-block;
}

.sendbird-icon:focus {
  outline: none;
}

.sendbird-theme--light .sendbird-icon-color--primary [class*=fill] {
  fill: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-icon-color--primary [class*=fill] {
  fill: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-icon-color--primary-2 [class*=fill] {
  fill: var(--sendbird-light-primary-200);
}

.sendbird-theme--dark .sendbird-icon-color--primary-2 [class*=fill] {
  fill: var(--sendbird-dark-primary-400);
}

.sendbird-theme--light .sendbird-icon-color--primary [class*=stroke] {
  stroke: var(--sendbird-light-primary-300);
  stroke-width: 2px;
  stroke-linejoin: round;
}

.sendbird-theme--dark .sendbird-icon-color--primary [class*=stroke] {
  stroke: var(--sendbird-dark-primary-200);
  stroke-width: 2px;
  stroke-linejoin: round;
}

.sendbird-theme--light .sendbird-icon-color--secondary [class*=fill] {
  fill: var(--sendbird-light-secondary-300);
}

.sendbird-theme--dark .sendbird-icon-color--secondary [class*=fill] {
  fill: var(--sendbird-dark-secondary-200);
}

.sendbird-theme--light .sendbird-icon-color--secondary-2 [class*=fill] {
  fill: var(--sendbird-light-secondary-200);
}

.sendbird-theme--dark .sendbird-icon-color--secondary-2 [class*=fill] {
  fill: var(--sendbird-dark-secondary-200);
}

.sendbird-theme--light .sendbird-icon-color--content [class*=fill] {
  fill: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-icon-color--content [class*=fill] {
  fill: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-icon-color--content-inverse [class*=fill] {
  fill: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-icon-color--content-inverse [class*=fill] {
  fill: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-icon-color--content-inverse-3 [class*=fill] {
  fill: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-icon-color--content-inverse-3 [class*=fill] {
  fill: var(--sendbird-dark-ondark-03);
}

.sendbird-icon-color--white [class*=fill] {
  fill: #ffffff;
}

.sendbird-icon-color--gray [class*=fill] {
  fill: var(--sendbird-light-onlight-04);
}

.sendbird-icon-color--thumbnail-icon [class*=fill] {
  fill: var(--sendbird-light-onlight-02);
}

.sendbird-theme--light .sendbird-icon-color--sent [class*=fill] {
  fill: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-icon-color--sent [class*=fill] {
  fill: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-icon-color--read [class*=fill] {
  fill: var(--sendbird-light-secondary-300);
}

.sendbird-theme--dark .sendbird-icon-color--read [class*=fill] {
  fill: var(--sendbird-dark-secondary-200);
}

.sendbird-theme--light .sendbird-icon-color--on-background-1 [class*=fill] {
  fill: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-icon-color--on-background-1 [class*=fill] {
  fill: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-icon-color--on-background-2 [class*=fill] {
  fill: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-icon-color--on-background-2 [class*=fill] {
  fill: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-icon-color--on-background-3 [class*=fill] {
  fill: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-icon-color--on-background-3 [class*=fill] {
  fill: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-icon-color--on-background-4 [class*=fill] {
  fill: var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-icon-color--on-background-4 [class*=fill] {
  fill: var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-icon-color--background-3 [class*=fill] {
  fill: var(--sendbird-light-background-300);
}

.sendbird-theme--dark .sendbird-icon-color--background-3 [class*=fill] {
  fill: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-icon-color--error [class*=fill] {
  fill: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-icon-color--error [class*=fill] {
  fill: var(--sendbird-dark-error-200);
}
.sendbird-context-menu {
  display: inline;
}

.sendbird-message-content__sendbird-ui-container-type__default__header-container .sendbird-context-menu {
  display: flex;
}

.sendbird__offline .sendbird-dropdown__menu .sendbird-dropdown__menu-item {
  cursor: not-allowed;
}

.sendbird__offline .sendbird-message-item-menu__list__menu-item.menu-item-delete {
  cursor: pointer;
}

.sendbird-dropdown__menu {
  z-index: 99999;
  position: absolute;
  top: 100%;
  min-width: 140px;
  margin: 0px;
  padding: 8px 0px;
  border-radius: 4px;
  list-style: none;
}

.sendbird-theme--light .sendbird-dropdown__menu {
  box-shadow: var(--sendbird-light-shadow-02);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-dropdown__menu {
  box-shadow: var(--sendbird-dark-shadow-02);
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-dropdown__menu .sendbird-dropdown__menu-item {
  cursor: pointer;
  white-space: nowrap;
  padding: 8px 16px;
}

.sendbird-dropdown__menu .sendbird-dropdown__menu-item.disable {
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-dropdown__menu .sendbird-dropdown__menu-item.disable:hover {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-dropdown__menu .sendbird-dropdown__menu-item.disable:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-dropdown__menu .sendbird-dropdown__menu-item:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-dropdown__menu .sendbird-dropdown__menu-item:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-dropdown__reaction-bar {
  position: absolute;
  top: 100%;
  min-width: 44px;
  max-width: 352px;
  max-height: 208px;
  overflow-y: scroll;
  margin: 0px;
  padding: 8px;
  border-radius: 8px;
  list-style: none;
}

[dir="ltr"] .sendbird-dropdown__reaction-bar {
  left: 0;
}

[dir="rtl"] .sendbird-dropdown__reaction-bar {
  right: 0;
}

.sendbird-theme--light .sendbird-dropdown__reaction-bar {
  box-shadow: var(--sendbird-light-shadow-02);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-dropdown__reaction-bar {
  box-shadow: var(--sendbird-dark-shadow-02);
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-dropdown__reaction-bar .sendbird-dropdown__reaction-bar__row .sendbird-reaction-button, .sendbird-dropdown__reaction-bar .sendbird-dropdown__reaction-bar__row .sendbird-reaction-button--selected {
  margin: 4px;
}

.sendbird-dropdown__reaction-bar .sendbird-dropdown__reaction-bar__emptyLabel {
  width: -moz-fit-content;
  width: fit-content;
}

.sendbird-dropdown__menu-backdrop {
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

[dir="ltr"] .sendbird-dropdown__menu-backdrop {
  left: 0;
}

[dir="rtl"] .sendbird-dropdown__menu-backdrop {
  right: 0;
}
.sendbird-sort-by-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sendbird__offline .sendbird-menu-item {
  cursor: not-allowed;
}

.sendbird-menu-item {
  cursor: pointer;
  white-space: nowrap;
  padding: 8px 16px;
  display: flex;
}

.sendbird-menu-item.disable {
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-menu-item.disable:hover {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-menu-item.disable:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-menu-item:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-menu-item:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-menu-item > * {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sendbird-menu-item__text {
  display: flex;
  justify-content: space-between;
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-conversation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-conversation {
  background-color: var(--sendbird-light-background-50);
  border: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-conversation {
  background-color: var(--sendbird-dark-background-600);
  border: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-conversation__messages {
  overflow: hidden;
  flex: 1 1;
}

.sendbird-conversation__messages-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.sendbird-conversation__footer {
  width: 100%;
  padding: 0px 0px 24px 0px;
}

.sendbird-msg-hoc__animated .sendbird-message-content {
  animation: bounce 1s ease;
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.sendbird-conversation__scroll-container {
  display: flex;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}

.sendbird-conversation__scroll-container .sendbird-conversation__padding {
  flex: 1 1;
}

.sendbird-conversation__footer {
  position: relative;
  box-sizing: border-box;
  margin-top: 6px;
}

.sendbird-conversation__footer .sendbird-conversation__footer__typing-indicator {
  position: absolute;
  display: inline-flex;
  bottom: 8px;
}

[dir="ltr"] .sendbird-conversation__footer .sendbird-conversation__footer__typing-indicator .sendbird-conversation__footer__typing-indicator__text {
  margin-left: 24px;
  margin-right: 10px;
}

[dir="rtl"] .sendbird-conversation__footer .sendbird-conversation__footer__typing-indicator .sendbird-conversation__footer__typing-indicator__text {
  margin-right: 24px;
  margin-left: 10px;
}
.sendbird-connection-status {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.sendbird-place-holder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.sendbird-place-holder .sendbird-place-holder__body {
  display: flex;
  flex-direction: column;
  height: 104px;
  align-items: center;
}

.sendbird-place-holder .sendbird-place-holder__body .sendbird-place-holder__body__icon {
  margin-bottom: 10px;
}

.sendbird-place-holder .sendbird-place-holder__body .sendbird-place-holder__body__text {
  margin-top: 10px;
}

.sendbird-place-holder .sendbird-place-holder__body__reconnect {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

[dir="ltr"] .sendbird-place-holder .sendbird-place-holder__body__reconnect .sendbird-place-holder__body__reconnect__icon {
  margin-right: 4px;
}

[dir="rtl"] .sendbird-place-holder .sendbird-place-holder__body__reconnect .sendbird-place-holder__body__reconnect__icon {
  margin-left: 4px;
}

.sendbird-place-holder .sendbird-place-holder__body--align-top {
  position: absolute;
  top: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.sendbird-place-holder .sendbird-place-holder__body--align-top .sendbird-place-holder__body--align-top__text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
}

.sendbird-place-holder .sendbird-place-holder__body--align-top .sendbird-place-holder__body--align-top__text .sendbird-place-holder__body--align-top__text__search-in {
  display: flex;
  min-width: 60px;
}

[dir="ltr"] .sendbird-place-holder .sendbird-place-holder__body--align-top .sendbird-place-holder__body--align-top__text .sendbird-place-holder__body--align-top__text__search-in {
  margin-right: 2px;
}

[dir="rtl"] .sendbird-place-holder .sendbird-place-holder__body--align-top .sendbird-place-holder__body--align-top__text .sendbird-place-holder__body--align-top__text__search-in {
  margin-left: 2px;
}

.sendbird-place-holder .sendbird-place-holder__body--align-top .sendbird-place-holder__body--align-top__text .sendbird-place-holder__body--align-top__text__channel-name {
  display: block;
  max-width: calc(100% - 62px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-place-holder .sendbird-place-holder__body--align-top .sendbird-place-hlder__body--align-top__searching,
.sendbird-place-holder .sendbird-place-holder__body--align-top .sendbird-place-hlder__body--align-top__no-result {
  display: flex;
  justify-content: center;
}
.sendbird-loader {
  display: inline-block;
  animation: 1s infinite linear;
  animation-name: rotate;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-chat-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  min-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-chat-header {
  padding-left: 20px;
  padding-right: 16px;
}

[dir="rtl"] .sendbird-chat-header {
  padding-right: 20px;
  padding-left: 16px;
}

.sendbird-theme--light .sendbird-chat-header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-chat-header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-chat-header__left {
  margin-right: 24px;
}

[dir="rtl"] .sendbird-chat-header__left {
  margin-left: 24px;
}

.sendbird-chat-header__left, .sendbird-chat-header__right {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sendbird-chat-header .sendbird-chat-header__left__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 780px;
}

[dir="ltr"] .sendbird-chat-header .sendbird-chat-header__left__title {
  margin-left: 8px;
  margin-right: 16px;
}

[dir="rtl"] .sendbird-chat-header .sendbird-chat-header__left__title {
  margin-right: 8px;
  margin-left: 16px;
}

.sendbird--mobile-mode .sendbird-chat-header .sendbird-chat-header__left__title {
  max-width: calc(100vw - 240px);
}

.sendbird-chat-header .sendbird-chat-header__left__subtitle {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir="ltr"] .sendbird-chat-header .sendbird-chat-header__right__mute {
  margin-right: 18px;
}

[dir="rtl"] .sendbird-chat-header .sendbird-chat-header__right__mute {
  margin-left: 18px;
}

.sendbird-chat-header .sendbird-iconbutton--pressed {
  background-color: inherit;
}

[dir="ltr"] .sendbird-chat-header .sendbird-chat-header__right__info {
  margin-left: 16px;
}

[dir="rtl"] .sendbird-chat-header .sendbird-chat-header__right__info {
  margin-right: 16px;
}

[dir="ltr"] .sendbird-chat-header__icon_back {
  margin-right: 8px;
}

[dir="rtl"] .sendbird-chat-header__icon_back {
  margin-left: 8px;
}
.sendbird-chat-header--default-avatar {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

[dir="rtl"] .sendbird-chat-header--default-avatar {
  .sendbird-icon {
    &.sendbird-icon-broadcast {
      transform: rotateY(180deg);
    }
  }
}

.sendbird-theme--light .sendbird-chat-header--default-avatar {
  background-color: var(--sendbird-light-secondary-300);
}

.sendbird-theme--dark .sendbird-chat-header--default-avatar {
  background-color: var(--sendbird-dark-secondary-200);
}
.sendbird-image-renderer {
  overflow: hidden;
  position: relative;
}

.sendbird-image-renderer__hidden-image-loader {
  display: none;
}

.sendbird-image-renderer,
.sendbird-image-renderer__image {
  width: 320px;
  height: 180px;
}

.sendbird-multiple-files-image-renderer__image-cover {
  position: absolute;
  top: 0px;
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--sendbird-light-overlay-01);
}

.sendbird-image-renderer:hover .sendbird-multiple-files-image-renderer__image-cover {
  display: inline-flex;
}

.sendbird-multiple-files-image-renderer__icon-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.sendbird-multiple-files-image-renderer__icon-wrapper .sendbird-multiple-files-image-renderer__icon-wrapper__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--sendbird-light-ondark-01);
  z-index: 2;
}
.sendbird-avatar {
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.sendbird-avatar:focus {
  outline: none;
}

.sendbird-avatar .sendbird-avatar-img {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar-img {
  left: 50%;
  transform: translate(-50%, -50%);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar-img {
  right: 50%;
  transform: translate(50%, -50%);
}

.sendbird-theme--light .sendbird-avatar .sendbird-avatar-img {
  background-color: var(--sendbird-light-background-300);
}

.sendbird-theme--dark .sendbird-avatar .sendbird-avatar-img {
  background-color: var(--sendbird-dark-background-300);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__two-child .sendbird-avatar-img:first-child {
  transform: translate(2%, -50%);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__two-child .sendbird-avatar-img:first-child {
  transform: translate(-2%, -50%);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__two-child .sendbird-avatar-img:last-child {
  transform: translate(-102%, -50%);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__two-child .sendbird-avatar-img:last-child {
  transform: translate(102%, -50%);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__three-child--upper .sendbird-avatar-img:first-child {
  transform: translate(-50%, -102%);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__three-child--upper .sendbird-avatar-img:first-child {
  transform: translate(50%, -102%);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__three-child--lower .sendbird-avatar-img:first-child {
  transform: translate(-77%, -23%) scale(0.5);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__three-child--lower .sendbird-avatar-img:first-child {
  transform: translate(77%, -23%) scale(0.5);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__three-child--lower .sendbird-avatar-img:last-child {
  transform: translate(-23%, -23%) scale(0.5);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__three-child--lower .sendbird-avatar-img:last-child {
  transform: translate(23%, -23%) scale(0.5);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:first-child {
  transform: translate(-77%, -77%) scale(0.5);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:first-child {
  transform: translate(77%, -77%) scale(0.5);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:nth-child(2) {
  transform: translate(-23%, -77%) scale(0.5);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:nth-child(2) {
  transform: translate(23%, -77%) scale(0.5);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:nth-child(3) {
  transform: translate(-77%, -23%) scale(0.5);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:nth-child(3) {
  transform: translate(77%, -23%) scale(0.5);
}

[dir="ltr"] .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:last-child {
  transform: translate(-23%, -23%) scale(0.5);
}

[dir="rtl"] .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:last-child {
  transform: translate(23%, -23%) scale(0.5);
}

.sendbird-avatar .sendbird-avatar-img--default {
  align-items: center;
  display: flex;
  justify-content: center;
}

.sendbird-theme--light .sendbird-avatar .sendbird-avatar-img--default {
  background-color: var(--sendbird-light-background-300);
}

.sendbird-theme--dark .sendbird-avatar .sendbird-avatar-img--default {
  background-color: var(--sendbird-dark-background-300);
}

.sendbird-theme--light .sendbird-avatar .sendbird-avatar-img--default.text {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-avatar .sendbird-avatar-img--default.text {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-avatar .sendbird-avatar-text {
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: bold;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
}

.sendbird-theme--light .sendbird-avatar .sendbird-avatar-text {
  color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-avatar .sendbird-avatar-text {
  color: var(--sendbird-dark-ondark-02);
}
.sendbird-ui-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  min-height: 64px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-theme--light .sendbird-ui-header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-ui-header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-ui-header--is-desktop {
  max-width: 176px;
}

.sendbird-ui-header--is-mobile {
  max-width: 80px;
}

.sendbird-ui-header__left {
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
}

[dir="ltr"] .sendbird-ui-header__left {
  left: 0px;
  margin-right: 8px;
}

[dir="rtl"] .sendbird-ui-header__left {
  right: 0px;
  margin-left: 8px;
}

.sendbird-ui-header__middle {
  flex: 1 1;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sendbird-ui-header__middle__subtitle__container {
  display: flex;
  width: 100%;
}

.sendbird-ui-header__middle__title,
.sendbird-ui-header__middle__subtitle {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.sendbird-ui-header__right {
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
}

[dir="ltr"] .sendbird-ui-header__right {
  right: 0px;
  margin-left: 8px;
}

[dir="rtl"] .sendbird-ui-header__right {
  left: 0px;
  margin-right: 8px;
}
.sendbird-textbutton {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}

.sendbird-textbutton--not-underline {
  text-decoration: none;
  cursor: pointer;
}

.sendbird-textbutton--disabled {
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-textbutton--disabled {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-textbutton--disabled {
  color: var(--sendbird-dark-ondark-03);
}
.sendbird-theme--light .sendbird-color--onbackground-1 {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-color--onbackground-1 {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-color--onbackground-1--background-color {
  background-color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-color--onbackground-1--background-color {
  background-color: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-color--onbackground-2 {
  color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-color--onbackground-2 {
  color: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-color--onbackground-2--background-color {
  background-color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-color--onbackground-2--background-color {
  background-color: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-color--onbackground-3 {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-color--onbackground-3 {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-color--onbackground-3--background-color {
  background-color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-color--onbackground-3--background-color {
  background-color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-color--onbackground-4 {
  color: var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-color--onbackground-4 {
  color: var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-color--onbackground-4--background-color {
  background-color: var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-color--onbackground-4--background-color {
  background-color: var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-color--oncontent-1 {
  color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-color--oncontent-1 {
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-color--oncontent-1--background-color {
  background-color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-color--oncontent-1--background-color {
  background-color: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-color--primary {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-color--primary {
  color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-color--primary--background-color {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-color--primary--background-color {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-color--error {
  color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-color--error {
  color: var(--sendbird-dark-error-200);
}

.sendbird-theme--light .sendbird-color--error--background-color {
  background-color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-color--error--background-color {
  background-color: var(--sendbird-dark-error-300);
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-conversation__messages {
  position: relative;
}

.sendbird-conversation__messages .sendbird-conversation__messages-padding {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  padding: 0 24px;
}

.sendbird--mobile-mode .sendbird-conversation__messages .sendbird-conversation__messages-padding {
  padding: 0 12px;
}

.sendbird-conversation__messages .sendbird-separator,
.sendbird-conversation__messages .sendbird-admin-message {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sendbird-conversation__messages__notification {
  position: fixed;
  top: 0px;
  width: calc(100% - 50px);
}

[dir="ltr"] .sendbird-conversation__messages__notification {
  margin-left: 25px;
}

[dir="rtl"] .sendbird-conversation__messages__notification {
  margin-right: 25px;
}

.sendbird-conversation__scroll-bottom-button {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  width: 40px;
  height: 40px;
  border-radius: 24px;
}

[dir="ltr"] .sendbird-conversation__scroll-bottom-button {
  left: calc(100% - 65px);
}

[dir="rtl"] .sendbird-conversation__scroll-bottom-button {
  right: calc(100% - 65px);
}

.sendbird-theme--light .sendbird-conversation__scroll-bottom-button {
  background-color: var(--sendbird-light-background-50);
  box-shadow: var(--sendbird-light-shadow-05);
}

.sendbird-theme--dark .sendbird-conversation__scroll-bottom-button {
  background-color: var(--sendbird-dark-background-400);
  box-shadow: var(--sendbird-dark-shadow-05);
}

.sendbird-conversation__scroll-bottom-button:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-conversation__scroll-bottom-button:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-conversation__scroll-bottom-button:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-conversation__scroll-bottom-button:active {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-conversation__scroll-bottom-button:active {
  background-color: var(--sendbird-dark-background-700);
}

.sendbird-conversation__scroll-bottom-button:focus {
  outline: none;
}
.sendbird-separator {
  width: 100%;
  display: flex;
  align-items: center;
}

.sendbird-separator .sendbird-separator__left {
  border: none;
  height: 1px;
  display: inline-block;
  width: 100%;
}

.sendbird-separator .sendbird-separator__right {
  border: none;
  height: 1px;
  display: inline-block;
  width: 100%;
}

.sendbird-separator .sendbird-separator__text {
  margin: 0px 16px;
  display: flex;
  white-space: nowrap;
}
.sendbird-message-input {
  display: inline-block;
  width: 100%;
  position: relative;
}

.sendbird-message-input .sendbird-message-input--textarea {
  position: relative;
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-height: 92px;
  min-height: 58px;
  overflow-y: scroll;
  letter-spacing: normal;
  box-sizing: border-box;
  resize: none;
  font-family: var(--sendbird-font-family-default);
  background-color: inherit;
  white-space: pre-wrap;
}

[dir="ltr"] .sendbird-message-input .sendbird-message-input--textarea {
  padding: 18px 64px 18px 16px;
}

[dir="rtl"] .sendbird-message-input .sendbird-message-input--textarea {
  padding: 18px 16px 18px 64px;
}

.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea {
  color: var(--sendbird-light-onlight-01);
  caret-color: var(--sendbird-light-primary-300);
  border: solid 1px var(--sendbird-light-onlight-03);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea {
  color: var(--sendbird-dark-ondark-01);
  caret-color: var(--sendbird-dark-primary-200);
  border: solid 1px var(--sendbird-dark-ondark-03);
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-message-input .sendbird-message-input--textarea:focus {
  outline: none;
}

.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:focus {
  color: var(--sendbird-light-onlight-01);
  border: solid 1px var(--sendbird-light-primary-300);
  box-shadow: var(--sendbird-light-shadow-message-input);
}

.sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea:focus {
  color: var(--sendbird-dark-ondark-01);
  border: solid 1px var(--sendbird-dark-primary-200);
  box-shadow: var(--sendbird-dark-shadow-message-input);
}

.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:active {
  border: solid 1px var(--sendbird-message-input-border-active);
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea:active {
  border: solid 1px var(--sendbird-message-input-border-active);
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-message-input .sendbird-message-input--placeholder {
  position: absolute;
  pointer-events: none;
  top: 18px;
  font-family: var(--sendbird-font-family-default);
  font-size: 14px;
  line-height: 1.43;
}

[dir="ltr"] .sendbird-message-input .sendbird-message-input--placeholder {
  left: 16px;
}

[dir="rtl"] .sendbird-message-input .sendbird-message-input--placeholder {
  right: 16px;
}

.sendbird-message-input .sendbird-message-input--attach {
  position: absolute;
  padding: 6px;
  bottom: 14px;
}

[dir="ltr"] .sendbird-message-input .sendbird-message-input--attach {
  right: 16px;
}

[dir="rtl"] .sendbird-message-input .sendbird-message-input--attach {
  left: 16px;
}

[dir="ltr"] .sendbird-message-input .sendbird-message-input--attach.is-voice-message-enabled {
  right: 48px;
}

[dir="rtl"] .sendbird-message-input .sendbird-message-input--attach.is-voice-message-enabled {
  left: 48px;
}

.sendbird-message-input .sendbird-message-input--voice-message {
  position: absolute;
  padding: 6px;
  bottom: 14px;
}

[dir="ltr"] .sendbird-message-input .sendbird-message-input--voice-message {
  right: 16px;
}

[dir="rtl"] .sendbird-message-input .sendbird-message-input--voice-message {
  left: 16px;
}

.sendbird-message-input .sendbird-message-input--send {
  position: absolute;
  padding: 6px;
  bottom: 14px;
}

[dir="ltr"] .sendbird-message-input .sendbird-message-input--send {
  right: 16px;
}

[dir="rtl"] .sendbird-message-input .sendbird-message-input--send {
  left: 16px;
  transform: rotate(180deg);
}

.sendbird-message-input .sendbird-message-input--attach-input {
  display: none;
}

.sendbird-message-input__edit {
  padding: 16px;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-message-input__edit {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-message-input__edit {
  background-color: var(--sendbird-dark-background-700);
}

.sendbird-message-input--edit-action {
  margin-top: 4px;
}

[dir="ltr"] .sendbird-message-input--edit-action {
  text-align: right;
}

[dir="rtl"] .sendbird-message-input--edit-action {
  text-align: left;
}

[dir="ltr"] .sendbird-message-input--edit-action button:last-child {
  margin-left: 8px;
}

[dir="rtl"] .sendbird-message-input--edit-action button:last-child {
  margin-right: 8px;
}

.sendbird-message-input--edit-action .sendbird-message-input--edit-action__cancel {
  width: 80px;
}

.sendbird-theme--light .sendbird-message-input--edit-action .sendbird-message-input--edit-action__cancel {
  border: solid 1px var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-message-input--edit-action .sendbird-message-input--edit-action__cancel {
  border: solid 1px var(--sendbird-dark-ondark-03);
}

.sendbird-message-input--edit-action .sendbird-message-input--edit-action__save {
  width: 80px;
}

.sendbird-message-input-form__disabled {
  cursor: not-allowed;
}

.sendbird-message-input__disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-message-input__disabled .sendbird-message-input--textarea {
  color: var(--sendbird-light-onlight-04);
  border: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-message-input__disabled .sendbird-message-input--textarea {
  color: var(--sendbird-dark-ondark-04);
  border: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-message-content {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.sendbird-message-content.incoming {
  justify-content: flex-start;
}

.sendbird-message-content.outgoing {
  justify-content: flex-end;
}

.sendbird-message-content .sendbird-message-content__middle {
  max-width: 400px;
}

.sendbird--mobile-mode .sendbird-message-content .sendbird-message-content__middle {
  max-width: calc(100vw - 140px);
}

.sendbird-message-content .sendbird-message-content__middle .sendbird-message-content__middle__quote-message.use-quote {
  bottom: -8px;
  margin-top: -8px;
}

.sendbird-message-content__middle__quote-message__quote {
  width: 100%;
}

.sendbird-message-content.incoming .sendbird-message-content__left {
  display: inline-flex;
  min-width: 40px;
  position: relative;
}

.sendbird-message-content.incoming .sendbird-message-content__left .sendbird-message-content__left__avatar {
  bottom: 2px;
  position: absolute;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__left .sendbird-message-content__left__avatar {
  left: 0;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.incoming .sendbird-message-content__left .sendbird-message-content__left__avatar {
  right: 0;
}

.sendbird-message-content.incoming .sendbird-message-content__left .sendbird-message-content__left__avatar.multiple {
  border: 2px solid;
}

.sendbird-theme--light .sendbird-message-content.incoming .sendbird-message-content__left .sendbird-message-content__left__avatar.multiple {
  border-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message-content.incoming .sendbird-message-content__left .sendbird-message-content__left__avatar.multiple {
  border-color: var(--sendbird-dark-background-600);
}

.sendbird-message-content.incoming .sendbird-message-content__left .sendbird-message-content__left__avatar.use-thread-replies {
  bottom: 35px;
}

.sendbird-message-content.incoming .sendbird-message-content__middle {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container {
  max-width: 100%;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  bottom: 6px;
  display: flex;
  flex-direction: row;
  min-width: 80px;
  position: absolute;
  white-space: nowrap;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  right: -84px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  left: -84px;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at.sendbird-mouse-hover {
  display: none;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .ui_container_type__default {
  min-width: -moz-fit-content;
  min-width: fit-content;
  bottom: -16px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .ui_container_type__default {
  left: 2px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .ui_container_type__default {
  right: 2px;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__sender-name {
  margin-bottom: 4px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__sender-name {
  margin-left: 12px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__sender-name {
  margin-right: 12px;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__quote-message {
  display: inline-flex;
  position: relative;
  width: 100%;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__quote-message.outgoing {
  justify-content: flex-end;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__quote-message.incoming {
  justify-content: flex-start;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__quote-message:hover {
  cursor: pointer;
}

.sendbird-message-content.incoming .sendbird-message-content__right {
  display: inline-flex;
  padding-top: 18px;
  position: relative;
  width: 50px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__right {
  margin-left: 4px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.incoming .sendbird-message-content__right {
  margin-right: 4px;
}

.sendbird-message-content.incoming .sendbird-message-content__right.use-reactions {
  width: 70px;
}

.sendbird-message-content.incoming .sendbird-message-content__right.chain-top {
  padding-top: 2px;
}

.sendbird-message-content.incoming .sendbird-message-content__right.chain-top.use-quote {
  padding-top: 18px;
}

.sendbird-message-content.incoming .sendbird-message-content__right .sendbird-message-content-menu {
  display: none;
  flex-direction: row;
  height: 32px;
  position: relative;
}

.sendbird-message-content.incoming .sendbird-message-content__right .sendbird-message-content-menu.sendbird-mouse-hover {
  display: inline-flex;
}

.sendbird--mobile-mode .sendbird-message-content.incoming:hover .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  display: inline-flex;
}

.sendbird-message-content.incoming:hover .sendbird-message-content__right .sendbird-message-content-menu {
  display: inline-flex;
}

.sendbird-message-content.incoming:hover .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  display: none;
}

.sendbird-message-content.incoming:hover .sendbird-message-content__middle .sendbird-message-content__middle__body-container .ui_container_type__default {
  display: flex;
}

.sendbird-message-content.outgoing .sendbird-message-content__left {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-end;
  position: relative;
  width: 50px;
}

.sendbird-message-content.outgoing .sendbird-message-content__left.use-reactions {
  width: 70px;
}

.sendbird-message-content.outgoing .sendbird-message-content__left.use-quote .sendbird-message-content-menu {
  top: 18px;
}

.sendbird-message-content.outgoing .sendbird-message-content__left .sendbird-message-content-menu {
  display: none;
  flex-direction: row;
  position: absolute;
  top: 2px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.outgoing .sendbird-message-content__left .sendbird-message-content-menu {
  right: 4px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.outgoing .sendbird-message-content__left .sendbird-message-content-menu {
  left: 4px;
}

.sendbird-message-content.outgoing .sendbird-message-content__left .sendbird-message-content-menu.sendbird-mouse-hover {
  display: inline-flex;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__quote-message {
  display: inline-flex;
  position: relative;
  width: 100%;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__quote-message.outgoing {
  justify-content: flex-end;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__quote-message.incoming {
  justify-content: flex-start;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container {
  position: relative;
  width: 100%;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  bottom: 2px;
  box-sizing: content-box;
  display: flex;
  justify-content: flex-end;
  min-height: 16px;
  min-width: 80px;
  position: absolute;
  white-space: nowrap;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  left: -84px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  right: -84px;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at.sendbird-mouse-hover {
  display: none;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at .sendbird-message-content__middle__body-container__created-at__component-container {
  display: inline-flex;
  position: relative;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .ui_container_type__default {
  min-width: -moz-fit-content;
  min-width: fit-content;
  bottom: -20px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .ui_container_type__default {
  left: 2px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .ui_container_type__default {
  right: 2px;
}

.sendbird-message-content.outgoing .sendbird-message-content__right {
  display: none;
}

.sendbird--mobile-mode .sendbird-message-content.outgoing:hover .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  display: inline-flex;
}

.sendbird-message-content.outgoing:hover .sendbird-message-content__left .sendbird-message-content-menu {
  display: inline-flex;
}

.sendbird-message-content.outgoing:hover .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  display: none;
}

.sendbird-message-content__middle__body-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  align-items: flex-end;
}

.sendbird-message-content__middle__body-container .sendbird-message-content__middle__message-item-body {
  box-sizing: border-box;
}

.sendbird-message-content__middle__body-container .sendbird-message-content__middle__message-item-body.reactions {
  width: 100%;
}

.sendbird-message-content__middle__body-container:has(.sendbird-message-content__middle__message-item-body.reactions) {
  align-items: unset;
}

.sendbird-message-content-reactions {
  border-radius: 0 0 16px 16px;
  max-width: 400px;
  position: relative;
  width: 100%;
}

.sendbird-theme--light .sendbird-message-content-reactions {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-message-content-reactions {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-message-content-reactions.primary {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-message-content-reactions.primary {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-message-content-reactions.mouse-hover, .sendbird-theme--light .sendbird-message-content-reactions:hover {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-message-content-reactions.mouse-hover, .sendbird-theme--dark .sendbird-message-content-reactions:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-message-content-reactions.mouse-hover.primary, .sendbird-theme--light .sendbird-message-content-reactions:hover.primary {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-message-content-reactions.mouse-hover.primary, .sendbird-theme--dark .sendbird-message-content-reactions:hover.primary {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-message-content-reactions.image-grid {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-message-content-reactions.image-grid {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-message-content__middle__thread-replies {
  margin-top: 4px;
}

.sendbird-message-content__middle__feedback-buttons {
  margin-top: 4px;
}

.sendbird-voice-message-item-body.sendbird-message-content__middle__message-item-body {
  height: 50px;
  min-width: 136px;
}

.sendbird-message-content__middle__body-container__feedback-buttons-container {
  margin-top: 4px;
  position: relative;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}

.sendbird-message-content__sendbird-ui-container-type__default__root {
  max-width: 400px;
}

.sendbird-message-content__sendbird-ui-container-type__default__root .sendbird-message-content__sendbird-ui-container-type__default__header-container {
  display: flex;
  align-items: center;
  margin: 8px 0 6px;
}

.sendbird-message-content__sendbird-ui-container-type__default__root .sendbird-message-content__sendbird-ui-container-type__default__header-container .sendbird-message-content__sendbird-ui-container-type__default__header-container__left__profile {
  width: 28px;
  height: 28px;
  margin-inline-end: 24px;
}

.sendbird-message-content__sendbird-ui-container-type__default__root .sendbird-message-content__sendbird-ui-container-type__default__bottom {
  padding-inline-start: 52px;
}
.sendbird-message-status {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.sendbird-message-status .sendbird-message-status__icon {
  position: relative;
  display: inline-block;
  top: -2px;
}

.sendbird-message-status .sendbird-message-status__icon.hide-icon {
  display: none;
}

.sendbird-message-status .sendbird-message-status__text {
  position: relative;
  display: inline-block;
}

[dir="ltr"] .sendbird-message-status .sendbird-message-status__text {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-message-status .sendbird-message-status__text {
  margin-right: 4px;
}

.sendbird-message-status .sendbird-message-status__text .sendbird-message-status__text__try-again {
  text-decoration: underline;
}

.sendbird-message-status .sendbird-message-status__text .sendbird-message-status__text__try-again:hover {
  cursor: pointer;
}

.sendbird-message-status .sendbird-message-status__text .sendbird-message-status__text__delete {
  text-decoration: underline;
}

.sendbird-message-status .sendbird-message-status__text .sendbird-message-status__text__delete:hover {
  cursor: pointer;
}
.sendbird-text {
  color: var(--primary-1);
}
.sendbird-reaction-button {
  border-radius: 8px;
  display: inline-block;
  border: solid 1px transparent;
  box-sizing: border-box;
  cursor: pointer;
}

.sendbird-theme--light .sendbird-reaction-button:hover {
  border: solid 1px var(--sendbird-light-background-100);
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-reaction-button:hover {
  border: solid 1px var(--sendbird-dark-background-400);
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-reaction-button__inner {
  margin: 3px;
}

.sendbird-reaction-button--selected {
  cursor: pointer;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-reaction-button--selected {
  border: solid 1px var(--sendbird-light-primary-100);
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-reaction-button--selected {
  border: solid 1px var(--sendbird-dark-primary-400);
  background-color: var(--sendbird-dark-primary-400);
}

.sendbird-reaction-button--selected:hover {
  border: solid 1px var(--sendbird-selected-reaction-button-border-hover);
}

.sendbird-reaction-button--selected__inner {
  margin: 3px;
}

.sendbird-reaction-button--selected.sendbird-reactions--pressed {
  display: block !important;
}
.sendbird-emoji-reactions {
  display: inline-block;
  border-radius: 16px;
  box-sizing: border-box;
  width: 100%;
  max-width: var(--sendbird-message-balloon-width);
  min-height: 24px;
  padding: 7px 5px 3px 5px;
}

.sendbird-theme--light .sendbird-emoji-reactions {
  background-color: var(--sendbird-light-background-50);
  border: 1px solid var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-emoji-reactions {
  background-color: var(--sendbird-dark-background-600);
  border: 1px solid var(--sendbird-dark-background-500);
}

.sendbird-emoji-reactions .sendbird-emoji-reactions__reaction-badge {
  margin-bottom: 4px;
}

[dir="ltr"] .sendbird-emoji-reactions .sendbird-emoji-reactions__reaction-badge {
  margin-left: 2px;
  margin-right: 1px;
}

[dir="rtl"] .sendbird-emoji-reactions .sendbird-emoji-reactions__reaction-badge {
  margin-right: 2px;
  margin-left: 1px;
}

.sendbird-emoji-reactions:focus {
  outline: none;
}

.sendbird-emoji-reactions .sendbird-context-menu {
  height: 26px;
}

.sendbird-emoji-reactions__add-reaction-badge {
  position: relative;
  top: -4px;
  display: inline-flex;
  width: 36px;
  height: 24px;
}

[dir="ltr"] .sendbird-emoji-reactions .sendbird-context-menu {
  margin-left: 2px;
}

[dir="rtl"] .sendbird-emoji-reactions .sendbird-context-menu {
  margin-right: 2px;
}
.sendbird-reaction-badge {
  display: inline-block;
  border-radius: 15px;
  width: 50px;
  height: 24px;
}

.sendbird-theme--light .sendbird-reaction-badge {
  background-color: var(--sendbird-light-background-100);
  border: solid 1px var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-reaction-badge {
  background-color: var(--sendbird-dark-background-500);
  border: solid 1px var(--sendbird-dark-background-500);
}

.sendbird-reaction-badge:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-reaction-badge:hover {
  border: solid 1px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-reaction-badge:hover {
  border: solid 1px var(--sendbird-dark-primary-200);
}

.sendbird-reaction-badge--is-add {
  display: inline-block;
  border-radius: 15px;
  width: 36px;
  height: 24px;
}

.sendbird-theme--light .sendbird-reaction-badge--is-add {
  background-color: var(--sendbird-light-background-100);
  border: solid 1px var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-reaction-badge--is-add {
  background-color: var(--sendbird-dark-background-500);
  border: solid 1px var(--sendbird-dark-background-500);
}

.sendbird-reaction-badge--is-add:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-reaction-badge--is-add:hover {
  border: solid 1px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-reaction-badge--is-add:hover {
  border: solid 1px var(--sendbird-dark-primary-200);
}

[dir="ltr"] .sendbird-reaction-badge--is-add .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  left: 8px;
}

[dir="rtl"] .sendbird-reaction-badge--is-add .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  right: 8px;
}

.sendbird-reaction-badge--selected {
  display: inline-block;
  border-radius: 15px;
  width: 50px;
  height: 24px;
}

.sendbird-theme--light .sendbird-reaction-badge--selected {
  background-color: var(--sendbird-light-primary-100);
  border: solid 1px var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-reaction-badge--selected {
  background-color: var(--sendbird-dark-primary-400);
  border: solid 1px var(--sendbird-dark-primary-400);
}

.sendbird-reaction-badge--selected:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-reaction-badge--selected:hover {
  border: solid 1px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-reaction-badge--selected:hover {
  border: solid 1px var(--sendbird-dark-primary-200);
}

.sendbird-reaction-badge__inner {
  position: relative;
  display: flex;
  height: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

[dir="ltr"] .sendbird-reaction-badge__inner {
  padding-left: 20px;
  padding-right: 4px;
}

[dir="rtl"] .sendbird-reaction-badge__inner {
  padding-right: 20px;
  padding-left: 4px;
}

.sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  position: absolute;
  top: 2px;
}

[dir="ltr"] .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  left: 4px;
}

[dir="rtl"] .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
  right: 4px;
}

[dir="ltr"] .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__count {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__count {
  margin-right: 4px;
}
.sendbird-message__bottomsheet__reacted-members {
  position: relative;
  padding: 0px 16px;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  overflow-x: scroll;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
}

.sendbird-message__bottomsheet__reacted-members__item {
  position: relative;
  height: 100%;
  display: inline-flex;
  grid-gap: 4px;
  gap: 4px;
  flex-direction: row;
  align-items: center;
}

.sendbird-message__bottomsheet__reactor-list {
  position: relative;
  padding: 0px 16px;
  width: 100%;
  height: 216px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  overflow-y: scroll;
}

.sendbird-message__bottomsheet__reactor-list__item.sendbird-user-list-item {
  border-bottom: 0px;
  min-height: 48px;
}
.sendbird-bottomsheet {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

[dir="ltr"] .sendbird-bottomsheet {
  left: 0;
}

[dir="rtl"] .sendbird-bottomsheet {
  right: 0;
}

.sendbird-bottomsheet__content {
  position: fixed;
  z-index: 10001;
  bottom: 0;
  width: 100%;
}

[dir="ltr"] .sendbird-bottomsheet__content {
  left: 0;
}

[dir="rtl"] .sendbird-bottomsheet__content {
  right: 0;
}

.sendbird-bottomsheet__backdrop {
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  top: 0;
}

[dir="ltr"] .sendbird-bottomsheet__backdrop {
  left: 0;
}

[dir="rtl"] .sendbird-bottomsheet__backdrop {
  right: 0;
}

.sendbird-theme--light .sendbird-bottomsheet__backdrop {
  background-color: var(--sendbird-light-overlay-02);
}

.sendbird-theme--dark .sendbird-bottomsheet__backdrop {
  background-color: var(--sendbird-dark-overlay-02);
}
.sendbird-user-list-item {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 432px;
  height: 56px;
}

.sendbird--mobile-mode .sendbird-user-list-item {
  width: 100%;
}

.sendbird-theme--light .sendbird-user-list-item {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-user-list-item {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-user-list-item .sendbird-user-list-item__avatar {
  position: absolute;
  top: 8px;
  cursor: pointer;
  z-index: 0;
}

[dir="ltr"] .sendbird-user-list-item .sendbird-user-list-item__avatar {
  left: 0px;
}

[dir="rtl"] .sendbird-user-list-item .sendbird-user-list-item__avatar {
  right: 0px;
}

.sendbird-user-list-item .sendbird-muted-avatar {
  position: absolute;
  top: 8px;
  pointer-events: none;
  z-index: 1;
}

[dir="ltr"] .sendbird-user-list-item .sendbird-muted-avatar {
  left: 0px;
}

[dir="rtl"] .sendbird-user-list-item .sendbird-muted-avatar {
  right: 0px;
}

.sendbird-user-list-item .sendbird-user-list-item__title {
  position: absolute;
  top: 17px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir="ltr"] .sendbird-user-list-item .sendbird-user-list-item__title {
  left: 56px;
}

[dir="rtl"] .sendbird-user-list-item .sendbird-user-list-item__title {
  right: 56px;
}

.sendbird--mobile-mode .sendbird-user-list-item .sendbird-user-list-item__title {
  max-width: 62%;
}

.sendbird-user-list-item .sendbird-user-list-item__subtitle {
  position: absolute;
  top: 23px;
  max-width: 237px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir="ltr"] .sendbird-user-list-item .sendbird-user-list-item__subtitle {
  left: 140px;
}

[dir="rtl"] .sendbird-user-list-item .sendbird-user-list-item__subtitle {
  right: 140px;
}

.sendbird--mobile-mode .sendbird-user-list-item .sendbird-user-list-item__subtitle {
  max-width: 40%;
}

.sendbird-user-list-item .sendbird-user-list-item__checkbox {
  position: absolute;
  top: 16px;
}

[dir="ltr"] .sendbird-user-list-item .sendbird-user-list-item__checkbox {
  right: 16px;
}

[dir="rtl"] .sendbird-user-list-item .sendbird-user-list-item__checkbox {
  left: 16px;
}

.sendbird-user-list-item .sendbird-user-list-item__action {
  position: absolute;
  top: 10px;
}

[dir="ltr"] .sendbird-user-list-item .sendbird-user-list-item__action {
  right: 0;
}

[dir="rtl"] .sendbird-user-list-item .sendbird-user-list-item__action {
  left: 0;
}

.sendbird-user-list-item .sendbird-user-list-item__operator {
  position: absolute;
  top: 22px;
}

[dir="ltr"] .sendbird-user-list-item .sendbird-user-list-item__operator {
  right: 40px;
}

[dir="rtl"] .sendbird-user-list-item .sendbird-user-list-item__operator {
  left: 40px;
}

[dir="ltr"] .sendbird-user-list-item .sendbird-user-list-item__operator.checkbox {
  right: 66px;
}

[dir="rtl"] .sendbird-user-list-item .sendbird-user-list-item__operator.checkbox {
  left: 66px;
}

.sendbird-user-list-item--small {
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 40px;
}

[dir="ltr"] .sendbird-user-list-item--small {
  padding-left: 12px;
}

[dir="rtl"] .sendbird-user-list-item--small {
  padding-right: 12px;
}

.sendbird-user-list-item--small .sendbird-user-list-item--small__avatar {
  cursor: pointer;
  position: absolute;
  top: 10px;
}

.sendbird-user-list-item--small .sendbird-muted-avatar {
  position: absolute;
  top: 10px;
  pointer-events: none;
}

[dir="ltr"] .sendbird-user-list-item--small .sendbird-muted-avatar {
  left: 12px;
}

[dir="rtl"] .sendbird-user-list-item--small .sendbird-muted-avatar {
  right: 12px;
}

.sendbird-user-list-item--small .sendbird-user-list-item--small__title {
  position: absolute;
  top: 10px;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir="ltr"] .sendbird-user-list-item--small .sendbird-user-list-item--small__title {
  left: 54px;
}

[dir="rtl"] .sendbird-user-list-item--small .sendbird-user-list-item--small__title {
  right: 54px;
}

.sendbird-user-list-item--small .sendbird-user-list-item--small__subtitle {
  position: absolute;
  top: 14px;
  max-width: 237px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir="ltr"] .sendbird-user-list-item--small .sendbird-user-list-item--small__subtitle {
  left: 140px;
}

[dir="rtl"] .sendbird-user-list-item--small .sendbird-user-list-item--small__subtitle {
  right: 140px;
}

.sendbird--mobile-mode .sendbird-user-list-item--small .sendbird-user-list-item--small__subtitle {
  max-width: 40%;
}

.sendbird-user-list-item--small .sendbird-user-list-item--small__checkbox {
  position: absolute;
  top: 16px;
}

[dir="ltr"] .sendbird-user-list-item--small .sendbird-user-list-item--small__checkbox {
  right: 16px;
}

[dir="rtl"] .sendbird-user-list-item--small .sendbird-user-list-item--small__checkbox {
  left: 16px;
}

.sendbird-user-list-item--small .sendbird-user-list-item--small__action {
  display: none;
  position: absolute;
  top: 4px;
}

[dir="ltr"] .sendbird-user-list-item--small .sendbird-user-list-item--small__action {
  right: 10px;
}

[dir="rtl"] .sendbird-user-list-item--small .sendbird-user-list-item--small__action {
  left: 10px;
}

.sendbird-user-list-item--small .sendbird-user-list-item--small__operator {
  position: absolute;
  top: 10px;
}

[dir="ltr"] .sendbird-user-list-item--small .sendbird-user-list-item--small__operator {
  right: 24px;
}

[dir="rtl"] .sendbird-user-list-item--small .sendbird-user-list-item--small__operator {
  left: 24px;
}

.sendbird-theme--light .sendbird-user-list-item--small:hover, .sendbird-theme--light .sendbird-user-list-item--small.sendbird-icon--pressed {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-user-list-item--small:hover, .sendbird-theme--dark .sendbird-user-list-item--small.sendbird-icon--pressed {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-user-list-item--small:hover .sendbird-user-list-item--small__action, .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-user-list-item--small__action {
  display: block;
}

.sendbird-user-list-item--small:hover .sendbird-user-list-item--small__operator, .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-user-list-item--small__operator {
  display: none;
}

.sendbird-theme--light .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-iconbutton {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-iconbutton {
  background-color: var(--sendbird-dark-background-700);
}
.sendbird-muted-avatar {
  border-radius: 50%;
  display: inline-block;
}

.sendbird-muted-avatar .sendbird-muted-avatar__icon {
  position: relative;
}

.sendbird-muted-avatar .sendbird-muted-avatar__icon .sendbird-icon {
  position: absolute;
  top: 4px;
}

[dir="ltr"] .sendbird-muted-avatar .sendbird-muted-avatar__icon .sendbird-icon {
  left: 4px;
}

[dir="rtl"] .sendbird-muted-avatar .sendbird-muted-avatar__icon .sendbird-icon {
  right: 4px;
}

.sendbird-muted-avatar .sendbird-muted-avatar__bg {
  opacity: 0.5;
  position: absolute;
  border-radius: 50%;
  top: 0;
}

[dir="ltr"] .sendbird-muted-avatar .sendbird-muted-avatar__bg {
  left: 0;
}

[dir="rtl"] .sendbird-muted-avatar .sendbird-muted-avatar__bg {
  right: 0;
}

.sendbird-theme--light .sendbird-muted-avatar .sendbird-muted-avatar__bg {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-muted-avatar .sendbird-muted-avatar__bg {
  background-color: var(--sendbird-dark-primary-300);
}
.sendbird-checkbox {
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

[dir="ltr"] .sendbird-checkbox {
  padding-left: 35px;
}

[dir="rtl"] .sendbird-checkbox {
  padding-right: 35px;
}

.sendbird-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.sendbird-checkbox--checkmark {
  position: absolute;
  top: 0;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  background-color: inherit;
}

[dir="ltr"] .sendbird-checkbox--checkmark {
  left: 0;
}

[dir="rtl"] .sendbird-checkbox--checkmark {
  right: 0;
}

.sendbird-theme--light .sendbird-checkbox--checkmark {
  border: solid 2px var(--sendbird-light-background-400);
}

.sendbird-theme--dark .sendbird-checkbox--checkmark {
  border: solid 2px var(--sendbird-dark-background-300);
}

.sendbird-theme--light .sendbird-checkbox--checkmark.disabled {
  border: solid 2px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-checkbox--checkmark.disabled {
  border: solid 2px var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-checkbox input:focus ~ .sendbird-checkbox--checkmark {
  box-shadow: 0px 0px 0px 2px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-checkbox input:focus ~ .sendbird-checkbox--checkmark {
  box-shadow: 0px 0px 0px 2px var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark {
  background-color: var(--sendbird-light-primary-300);
  border: solid 2px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark {
  background-color: var(--sendbird-dark-primary-200);
  border: solid 2px var(--sendbird-dark-primary-200);
}

.sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark.disabled {
  cursor: default;
}

.sendbird-theme--light .sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark.disabled {
  background-color: var(--sendbird-light-onlight-04);
  border: solid 2px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark.disabled {
  background-color: var(--sendbird-dark-ondark-04);
  border: solid 2px var(--sendbird-dark-ondark-04);
}

.sendbird-checkbox--checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark:after {
  display: block;
}

.sendbird-theme--light .sendbird-checkbox .sendbird-checkbox--checkmark:after {
  left: 5px;
  top: -0.5px;
  width: 7px;
  height: 12px;
  border: solid var(--sendbird-light-background-50);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.sendbird-theme--dark .sendbird-checkbox .sendbird-checkbox--checkmark:after {
  left: 5px;
  top: -0.5px;
  width: 7px;
  height: 12px;
  border: solid var(--sendbird-dark-background-600);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/**rtl:end:ignore**/
.sendbird__user-profile {
  width: 320px;
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  text-align: center;
}

.sendbird-theme--light .sendbird__user-profile {
  box-shadow: var(--sendbird-light-shadow-02);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird__user-profile {
  box-shadow: var(--sendbird-dark-shadow-02);
  background-color: var(--sendbird-dark-background-500);
}

.sendbird__user-profile .sendbird__user-profile-avatar {
  margin: 8px 0px;
}

.sendbird__user-profile .sendbird__user-profile-name {
  max-width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.sendbird-theme--light .sendbird__user-profile .sendbird__user-profile-name {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird__user-profile .sendbird__user-profile-name {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird__user-profile .sendbird__user-profile-message {
  margin-top: 20px;
}

.sendbird__user-profile .sendbird__user-profile-message button {
  width: 272px;
}

.sendbird__user-profile .sendbird__user-profile-separator {
  margin: 24px 0px;
  height: 1px;
}

.sendbird-theme--light .sendbird__user-profile .sendbird__user-profile-separator {
  background-color: var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird__user-profile .sendbird__user-profile-separator {
  background-color: var(--sendbird-dark-ondark-04);
}

.sendbird__user-profile .sendbird__user-profile-userId--label,
.sendbird__user-profile .sendbird__user-profile-userId--value {
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
}

[dir="ltr"] .sendbird__user-profile .sendbird__user-profile-userId--label,
[dir="ltr"] .sendbird__user-profile .sendbird__user-profile-userId--value {
  text-align: left;
}

[dir="rtl"] .sendbird__user-profile .sendbird__user-profile-userId--label,
[dir="rtl"] .sendbird__user-profile .sendbird__user-profile-userId--value {
  text-align: right;
}

.sendbird__user-profile .sendbird__user-profile-userId--label {
  margin-bottom: 8px;
}
.sendbird-tooltip {
  position: relative;
  display: inline-flex;
  justify-content: center;
  max-width: 200px;
  border-radius: 8px;
  padding: 8px 16px;
}

.sendbird-theme--light .sendbird-tooltip {
  background-color: var(--sendbird-light-background-700);
}

.sendbird-theme--dark .sendbird-tooltip {
  background-color: var(--sendbird-dark-background-300);
}

.sendbird-tooltip::after {
  content: "";
  position: absolute;
  bottom: -4px;
  width: 8px;
  height: 8px;
}

[dir="ltr"] .sendbird-tooltip::after {
  transform: rotate(45deg);
}

[dir="rtl"] .sendbird-tooltip::after {
  transform: rotate(-45deg);
}

.sendbird-theme--light .sendbird-tooltip::after {
  background-color: var(--sendbird-light-background-700);
}

.sendbird-theme--dark .sendbird-tooltip::after {
  background-color: var(--sendbird-dark-background-300);
}

.sendbird-tooltip__text {
  font-family: var(--sendbird-font-family-default);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  word-break: keep-all;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sendbird-tooltip-wrapper {
  position: relative;
  display: inline-flex;
}

.sendbird-tooltip-wrapper__hover-tooltip {
  position: absolute;
  display: inline-flex;
  width: 200px;
}

.sendbird-tooltip-wrapper__hover-tooltip__inner__tooltip-container {
  position: relative;
  display: inline-flex;
}
.sendbird-admin-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendbird-admin-message .sendbird-admin-message__text {
  display: flex;
  text-align: center;
}
.sendbird-quote-message {
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.sendbird-quote-message.incoming {
  align-items: flex-start;
}

.sendbird-quote-message.outgoing {
  align-items: flex-end;
}

.sendbird-quote-message .sendbird-quote-message__replied-to {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 16px;
  padding: 0px 12px;
  width: 100%;
}

.sendbird-quote-message .sendbird-quote-message__replied-to .sendbird-quote-message__replied-to__icon {
  position: relative;
  margin-bottom: 4px;
}

[dir="ltr"] .sendbird-quote-message .sendbird-quote-message__replied-to .sendbird-quote-message__replied-to__icon {
  margin-right: 4px;
}

[dir="rtl"] .sendbird-quote-message .sendbird-quote-message__replied-to .sendbird-quote-message__replied-to__icon {
  margin-left: 4px;
}

.sendbird-quote-message .sendbird-quote-message__replied-to .sendbird-quote-message__replied-to__text {
  position: relative;
  max-width: 360px;
  overflow: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  font-size: 12px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.sendbird-quote-message .sendbird-quote-message__replied-to .sendbird-quote-message__replied-to__text .sendbird-quote-message__replied-to__text__nickname {
  display: inline-block;
  max-width: 148px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-quote-message .sendbird-quote-message__replied-to .sendbird-quote-message__replied-to__text .sendbird-quote-message__replied-to__text__text {
  margin: 0px 4px;
}

.sendbird-quote-message .sendbird-quote-message__replied-message {
  position: relative;
  display: inline-flex;
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__text-message {
  position: relative;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 12px;
  padding: 8px 12px 16px 12px;
  border-radius: 16px;
  opacity: 0.5;
}

.sendbird-theme--light .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__text-message {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__text-message {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__text-message .sendbird-quote-message__replied-message__text-message__word {
  position: relative;
  display: inline-flex;
  max-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message {
  position: relative;
  width: 144px;
  height: 108px;
  border-radius: 6.4px;
  overflow: hidden;
}

.sendbird-theme--light .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__video,
.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__image {
  position: absolute;
  top: 0px;
  display: inline-flex;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__placeholder {
  position: absolute;
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__placeholder .sendbird-quote-message__replied-message__thumbnail-message__placeholder__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__cover {
  position: absolute;
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0px;
}

.sendbird-theme--light .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__cover {
  background-color: rgba(255, 255, 255, 0.4);
}

.sendbird-theme--dark .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__cover {
  background-color: rgba(255, 255, 255, 0.4);
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__cover .sendbird-quote-message__replied-message__thumbnail-message__cover__icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__cover .sendbird-quote-message__replied-message__thumbnail-message__cover__icon {
  background-color: rgb(255, 255, 255);
}

.sendbird-theme--dark .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__thumbnail-message .sendbird-quote-message__replied-message__thumbnail-message__cover .sendbird-quote-message__replied-message__thumbnail-message__cover__icon {
  background-color: rgb(255, 255, 255);
}

.sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__file-message {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  font-size: 12px;
  padding: 8px 12px 16px 12px;
  border-radius: 16px;
  opacity: 0.5;
}

.sendbird-theme--light .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__file-message {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__file-message {
  background-color: var(--sendbird-dark-background-500);
}

[dir="ltr"] .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__file-message .sendbird-quote-message__replied-message__file-message__type-icon {
  margin-right: 2px;
}

[dir="rtl"] .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__file-message .sendbird-quote-message__replied-message__file-message__type-icon {
  margin-left: 2px;
}

.sendbird-quote-message:hover {
  cursor: pointer;
}

.sendbird-quote-message.unavailable:hover {
  cursor: default;
}

.sendbird-message-content.outgoing .sendbird-quote-message__replied-to {
  flex-direction: row-reverse;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.outgoing .sendbird-quote-message__replied-to {
  padding-right: 0px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.outgoing .sendbird-quote-message__replied-to {
  padding-left: 0px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-quote-message__replied-to {
  padding-left: 0px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-message-content.incoming .sendbird-quote-message__replied-to {
  padding-right: 0px;
}
.sendbird-message__mobile-context-menu {
  border-radius: 8px;
  width: 180px;
}

.sendbird-message__contextmenu--hyperlink {
  display: flex;
  justify-content: space-between;
}

.sendbird-message__bottomsheet--hyperlink {
  display: block;
}

.sendbird-message__bottomsheet--hyperlink,
.sendbird-message__contextmenu--hyperlink {
  width: 100%;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-stretch: normal;
  font-style: normal;
  line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;
}

.sendbird-theme--light .sendbird-message__mobile-context-menu-item:not(:last-child) {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-message__mobile-context-menu-item:not(:last-child) {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-message__bottomsheet {
  width: 100vw;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 16px;
}

.sendbird-theme--light .sendbird-message__bottomsheet {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message__bottomsheet {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-message__bottomsheet-reaction-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0;
}

[dir="ltr"] .sendbird-message__bottomsheet-reaction-bar {
  padding-left: 0;
}

[dir="rtl"] .sendbird-message__bottomsheet-reaction-bar {
  padding-right: 0;
}

.sendbird-message__bottomsheet-reaction-bar__row {
  display: inline-block;
}

.sendbird-message__bottomsheet-reaction-bar__row.sendbird-message__bottomsheet-reaction-bar__all {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sendbird-message__bottomsheet--action {
  cursor: pointer;
  height: 48px;
  display: flex;
  align-items: center;
}

[dir="ltr"] .sendbird-message__bottomsheet--action {
  padding-left: 16px;
}

[dir="rtl"] .sendbird-message__bottomsheet--action {
  padding-right: 16px;
}

[dir="ltr"] .sendbird-message__bottomsheet--action .sendbird-icon {
  margin-right: 24px;
}

[dir="rtl"] .sendbird-message__bottomsheet--action .sendbird-icon {
  margin-left: 24px;
}

.sendbird-message__bottomsheet--action-disabled {
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-message__bottomsheet--action-disabled {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message__bottomsheet--action-disabled {
  background-color: var(--sendbird-dark-background-500);
}
.sendbird-ui-thread-replies {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  min-height: 20px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 10px;
  grid-gap: 4px;
  gap: 4px;
  border-radius: 16px;
}

.sendbird-theme--light .sendbird-ui-thread-replies {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-ui-thread-replies {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-ui-thread-replies:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-ui-thread-replies:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-ui-thread-replies:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-ui-thread-replies__user-profiles {
  position: relative;
  width: 100%;
  height: 100%;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
  flex-direction: row;
}

.sendbird-ui-thread-replies__user-profiles__avatar {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.sendbird-ui-thread-replies__user-profiles__avatar__image {
  position: absolute;
  top: 0px;
  width: 20px;
  height: 20px;
}

[dir="ltr"] .sendbird-ui-thread-replies__user-profiles__avatar__image {
  left: 0px;
}

[dir="rtl"] .sendbird-ui-thread-replies__user-profiles__avatar__image {
  right: 0px;
}

.sendbird-ui-thread-replies__user-profiles__avatar__cover {
  position: absolute;
  top: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--sendbird-light-overlay-01);
}

[dir="ltr"] .sendbird-ui-thread-replies__user-profiles__avatar__cover {
  left: 0px;
}

[dir="rtl"] .sendbird-ui-thread-replies__user-profiles__avatar__cover {
  right: 0px;
}

.sendbird-ui-thread-replies__user-profiles__avatar__plus {
  position: absolute;
  top: 0px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

[dir="ltr"] .sendbird-ui-thread-replies__user-profiles__avatar__plus {
  left: 0px;
}

[dir="rtl"] .sendbird-ui-thread-replies__user-profiles__avatar__plus {
  right: 0px;
}

.sendbird-ui-thread-replies__reply-counts {
  position: relative;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: 12px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}

.sendbird-ui-thread-replies__icon {
  position: relative;
  display: inline-flex;
}

[dir="rtl"] .sendbird-ui-thread-replies__icon {
  transform: rotate(180deg);
}
.sendbird-og-message-item-body {
  position: relative;
  display: inline-block;
  width: 320px;
  min-width: 320px;
}

.sendbird--mobile-mode .sendbird-og-message-item-body {
  min-width: 160px;
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__text-bubble {
  position: relative;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 16px 16px 0px 0px;
  word-break: break-word;
  white-space: pre-line;
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__text-bubble .sendbird-og-message-item-body__text-bubble__message {
  display: inline;
  margin: 0px 2px;
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__og-thumbnail {
  position: relative;
  overflow: hidden;
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__og-thumbnail .sendbird-og-message-item-body__og-thumbnail__image {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendbird-og-message-item-body.reactions .sendbird-og-message-item-body__description {
  border-radius: 0px;
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 16px 16px;
}

.sendbird-theme--light .sendbird-og-message-item-body .sendbird-og-message-item-body__description {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-og-message-item-body .sendbird-og-message-item-body__description {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__description .sendbird-og-message-item-body__description__title,
.sendbird-og-message-item-body .sendbird-og-message-item-body__description .sendbird-og-message-item-body__description__description {
  margin-bottom: 4px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__description .sendbird-og-message-item-body__description__url {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  margin: 4px 0px;
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__og-thumbnail:hover,
.sendbird-og-message-item-body .sendbird-og-message-item-body__description:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-og-message-item-body.outgoing .sendbird-og-message-item-body__text-bubble {
  background-color: var(--sendbird-light-primary-300);
  color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-og-message-item-body.outgoing .sendbird-og-message-item-body__text-bubble {
  background-color: var(--sendbird-dark-primary-200);
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-og-message-item-body.incoming .sendbird-og-message-item-body__text-bubble {
  background-color: var(--sendbird-light-background-100);
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-og-message-item-body.incoming .sendbird-og-message-item-body__text-bubble {
  background-color: var(--sendbird-dark-background-500);
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-og-message-item-body.mouse-hover .sendbird-og-message-item-body__description, .sendbird-theme--light .sendbird-og-message-item-body:hover .sendbird-og-message-item-body__description {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-og-message-item-body.mouse-hover .sendbird-og-message-item-body__description, .sendbird-theme--dark .sendbird-og-message-item-body:hover .sendbird-og-message-item-body__description {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-og-message-item-body.mouse-hover.outgoing .sendbird-og-message-item-body__text-bubble, .sendbird-theme--light .sendbird-og-message-item-body:hover.outgoing .sendbird-og-message-item-body__text-bubble {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-og-message-item-body.mouse-hover.outgoing .sendbird-og-message-item-body__text-bubble, .sendbird-theme--dark .sendbird-og-message-item-body:hover.outgoing .sendbird-og-message-item-body__text-bubble {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-og-message-item-body.mouse-hover.incoming .sendbird-og-message-item-body__text-bubble, .sendbird-theme--light .sendbird-og-message-item-body:hover.incoming .sendbird-og-message-item-body__text-bubble {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-og-message-item-body.mouse-hover.incoming .sendbird-og-message-item-body__text-bubble, .sendbird-theme--dark .sendbird-og-message-item-body:hover.incoming .sendbird-og-message-item-body__text-bubble {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-og-message-item-body .sendbird-og-message-item-body__cover {
  display: none;
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0px 0px;
}

[dir="ltr"] .sendbird-og-message-item-body .sendbird-og-message-item-body__cover {
  left: 0;
}

[dir="rtl"] .sendbird-og-message-item-body .sendbird-og-message-item-body__cover {
  right: 0;
}

div.sendbird-og-message-item-body__text-bubble {
  margin: 0px;
}
.sendbird-word {
  white-space: break-spaces;
}

.sendbird-word .sendbird-word__mention {
  display: inline-block;
}

.sendbird-word .sendbird-word__mention:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-word .sendbird-word__mention.sendbird-word__mention--me {
  background-color: var(--sendbird-highlight-100);
}

.sendbird-theme--dark .sendbird-word .sendbird-word__mention.sendbird-word__mention--me {
  background-color: var(--sendbird-highlight-100);
}

.sendbird-theme--light .sendbird-word .sendbird-word__mention.sendbird-word__mention--me .sendbird-label {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-word .sendbird-word__mention.sendbird-word__mention--me .sendbird-label {
  color: var(--sendbird-dark-onlight-01);
}
.sendbird-link-label {
  text-decoration: unset;
}

.sendbird-link-label:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sendbird-link-label:focus {
  outline: none;
}

.sendbird-link-label__label {
  display: inline;
}

.sendbird-theme--light .sendbird-link-label .sendbird-label--color-onbackground-1 {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-link-label .sendbird-label--color-onbackground-1 {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-link-label .sendbird-label--color-onbackground-2 {
  color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-link-label .sendbird-label--color-onbackground-2 {
  color: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-link-label .sendbird-label--color-onbackground-3 {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-link-label .sendbird-label--color-onbackground-3 {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-link-label .sendbird-label--color-oncontent-1 {
  color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-link-label .sendbird-label--color-oncontent-1 {
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-link-label .sendbird-label--color-oncontent-2 {
  color: var(--sendbird-light-ondark-02);
}

.sendbird-theme--dark .sendbird-link-label .sendbird-label--color-oncontent-2 {
  color: var(--sendbird-dark-onlight-02);
}

.sendbird-theme--light .sendbird-link-label .sendbird-label--color-primary {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-link-label .sendbird-label--color-primary {
  color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-link-label .sendbird-label--color-error {
  color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-link-label .sendbird-label--color-error {
  color: var(--sendbird-dark-error-200);
}

.sendbird-theme--light .sendbird-link-label .sendbird-label--color-secondary-3 {
  color: var(--sendbird-light-secondary-300);
}

.sendbird-theme--dark .sendbird-link-label .sendbird-label--color-secondary-3 {
  color: var(--sendbird-dark-secondary-200);
}
.sendbird-text-message-item-body {
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  padding: 8px 12px;
  border-radius: 16px;
  white-space: pre-line;
  word-break: break-word;
}

.sendbird-text-message-item-body.reactions {
  border-radius: 16px 16px 0px 0px;
}

.sendbird-theme--light .sendbird-text-message-item-body.incoming {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-text-message-item-body.incoming {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-text-message-item-body.outgoing {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-text-message-item-body.outgoing {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-text-message-item-body.mouse-hover.incoming, .sendbird-theme--light .sendbird-text-message-item-body:hover.incoming {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-text-message-item-body.mouse-hover.incoming, .sendbird-theme--dark .sendbird-text-message-item-body:hover.incoming {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-text-message-item-body.mouse-hover.outgoing, .sendbird-theme--light .sendbird-text-message-item-body:hover.outgoing {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-text-message-item-body.mouse-hover.outgoing, .sendbird-theme--dark .sendbird-text-message-item-body:hover.outgoing {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-text-message-item-body.disable-hover.mouse-hover.incoming, .sendbird-theme--light .sendbird-text-message-item-body.disable-hover:hover.incoming {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-text-message-item-body.disable-hover.mouse-hover.incoming, .sendbird-theme--dark .sendbird-text-message-item-body.disable-hover:hover.incoming {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-text-message-item-body.disable-hover.mouse-hover.outgoing, .sendbird-theme--light .sendbird-text-message-item-body.disable-hover:hover.outgoing {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-text-message-item-body.disable-hover.mouse-hover.outgoing, .sendbird-theme--dark .sendbird-text-message-item-body.disable-hover:hover.outgoing {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-text-message-item-body .sendbird-text-message-item-body__text-bubble__message {
  display: inline;
  margin: 0px 2px;
}

div.sendbird-text-message-item-body {
  margin: 0px;
}
.sendbird-file-message-item-body {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 8px 12px;
  align-items: center;
  border-radius: 16px;
}

.sendbird-file-message-item-body.reactions {
  border-radius: 16px 16px 0px 0px;
}

.sendbird-theme--light .sendbird-file-message-item-body.outgoing {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-file-message-item-body.outgoing {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-file-message-item-body.incoming {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-file-message-item-body.incoming {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-file-message-item-body.mouse-hover.outgoing, .sendbird-theme--light .sendbird-file-message-item-body:hover.outgoing {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-file-message-item-body.mouse-hover.outgoing, .sendbird-theme--dark .sendbird-file-message-item-body:hover.outgoing {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-file-message-item-body.mouse-hover.incoming, .sendbird-theme--light .sendbird-file-message-item-body:hover.incoming {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-file-message-item-body.mouse-hover.incoming, .sendbird-theme--dark .sendbird-file-message-item-body:hover.incoming {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-file-message-item-body .sendbird-file-message-item-body__file-icon {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

[dir="ltr"] .sendbird-file-message-item-body .sendbird-file-message-item-body__file-icon {
  margin-right: 8px;
}

[dir="rtl"] .sendbird-file-message-item-body .sendbird-file-message-item-body__file-icon {
  margin-left: 8px;
}

.sendbird-theme--light .sendbird-file-message-item-body .sendbird-file-message-item-body__file-icon {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-file-message-item-body .sendbird-file-message-item-body__file-icon {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-file-message-item-body .sendbird-file-message-item-body__file-name {
  position: relative;
  top: -6px;
}
.sendbird-image-grid-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.sendbird-image-grid {
  border-radius: 12px;
  display: grid;
  grid-gap: 4px;
  gap: 4px;
  grid-template-columns: repeat(2, 1fr);
  padding: 4px;
  width: -moz-fit-content;
  width: fit-content;
}

.sendbird-theme--light .sendbird-image-grid {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-image-grid {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-image-grid.reactions {
  border-radius: 12px 12px 0 0;
}
.sendbird-fileviewer__header__right__actions__download,
.sendbird-fileviewer__header__right__actions__delete,
.sendbird-fileviewer__header__right__actions__close {
  width: 64px;
  padding: 21px;
  box-sizing: border-box;
  cursor: pointer;
}

.sendbird-fileviewer__header__right__actions__download .disabled,
.sendbird-fileviewer__header__right__actions__delete .disabled,
.sendbird-fileviewer__header__right__actions__close .disabled {
  cursor: not-allowed;
}

.sendbird-fileviewer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 100000;
}

[dir="ltr"] .sendbird-fileviewer {
  left: 0;
}

[dir="rtl"] .sendbird-fileviewer {
  right: 0;
}

.sendbird-theme--light .sendbird-fileviewer {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-fileviewer {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-fileviewer .sendbird-fileviewer__header {
  height: 64px;
  min-height: 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.sendbird-theme--light .sendbird-fileviewer .sendbird-fileviewer__header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__avatar {
  display: flex;
  margin-inline-end: 8px;
}

.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__avatar {
  margin-inline-end: 12px;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container {
  flex-direction: column;
  align-items: unset;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container .sendbird-fileviewer__header__left__filename {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-inline-end: 8px;
  padding-top: 3px;
}

.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container .sendbird-fileviewer__header__left__filename {
  display: block;
  max-width: calc(100vw - 240px);
  padding-top: 0;
  margin-inline-end: 0;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container .sendbird-fileviewer__header__left__sender-name {
  line-height: 1.1;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right {
  display: flex;
  width: 160px;
  justify-content: flex-end;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions {
  display: flex;
  flex-direction: row;
}

[dir="ltr"] .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions {
  right: 0;
}

[dir="rtl"] .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions {
  left: 0;
}

[dir="ltr"] .sendbird-theme--light .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-right: 1px solid var(--sendbird-light-onlight-04);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-left: 1px solid var(--sendbird-dark-ondark-04);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-right: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-fileviewer .sendbird-fileviewer__content {
  position: relative;
  height: calc(100% - 72px);
  margin-top: 4px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__video {
  max-width: 100%;
  max-height: 100%;
}

.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__img {
  max-width: 90%;
  max-height: 90%;
}

.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__img__multi {
  max-width: calc(100% - 64px - 56px);
  max-height: calc(100% - 64px);
}

.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__unsupported {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendbird-file-viewer-arrow--left,
.sendbird-file-viewer-arrow--right {
  cursor: pointer;
  position: absolute;
  height: 32px;
  width: 32px;
  top: calc(50% - 16px);
}

.sendbird-file-viewer-arrow--left {
  left: 14px;
}

.sendbird-file-viewer-arrow--right {
  right: 14px;
  transform: rotate(180deg);
}
.sendbird-multiple-files-image-renderer-wrapper {
  cursor: pointer;
  width: 100%;
}

.sendbird-multiple-files-image-renderer__thumbnail__placeholder {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 16px;
  align-items: center;
}

.sendbird-theme--light .sendbird-multiple-files-image-renderer__thumbnail__placeholder {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-multiple-files-image-renderer__thumbnail__placeholder {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-multiple-files-image-renderer__thumbnail__placeholder .sendbird-multiple-files-image-renderer__thumbnail__placeholder__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--sendbird-light-ondark-01);
}
.sendbird-voice-message-item-body {
  position: relative;
  display: inline-flex;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.sendbird-voice-message-item-body.is-reactions-contained {
  border-radius: 16px 16px 0px 0px;
}

.sendbird-voice-message-item-body__progress-bar {
  position: absolute;
  width: 100%;
  height: 50px;
}

.sendbird-voice-message-item-body__status-button {
  position: relative;
  top: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
}

[dir="ltr"] .sendbird-voice-message-item-body__status-button {
  left: 12px;
}

[dir="rtl"] .sendbird-voice-message-item-body__status-button {
  right: 12px;
}

.sendbird-voice-message-item-body__status-button__button {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-voice-message-item-body__status-button__button {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-voice-message-item-body__status-button__button {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-voice-message-item-body__playback-time {
  position: absolute;
  top: 15px;
}

[dir="ltr"] .sendbird-voice-message-item-body__playback-time {
  right: 12px;
}

[dir="rtl"] .sendbird-voice-message-item-body__playback-time {
  left: 12px;
}

.sendbird-voice-message-item-body__status-button__button__pause {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 13.75px;
  height: 13.75px;
}

.sendbird-voice-message-item-body__status-button__button__pause__inner {
  position: relative;
  display: inline-flex;
  width: 4.38px;
  height: 13.75px;
  border-radius: 2px;
}

.sendbird-theme--light .sendbird-voice-message-item-body__status-button__button__pause__inner {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-voice-message-item-body__status-button__button__pause__inner {
  background-color: var(--sendbird-dark-primary-200);
}
.sendbird-progress-bar, .sendbird-progress-bar__fill {
  display: inline-flex;
  width: 100%;
  height: 100%;
}

.sendbird-progress-bar {
  justify-content: flex-start;
}

.sendbird-theme--light .sendbird-progress-bar__fill {
  background-color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-progress-bar__fill {
  background-color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .progress-bar-color--primary {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .progress-bar-color--primary {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-progress-bar.progress-bar--disabled, .sendbird-theme--light .progress-bar-color--gray {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-progress-bar.progress-bar--disabled, .sendbird-theme--dark .progress-bar-color--gray {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-progress-bar.progress-bar--disabled .sendbird-progress-bar__fill {
  background-color: transparent;
}
.sendbird-thumbnail-message-item-body {
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-width: 360px;
  max-width: 400px;
  height: 270px;
  border-radius: 16px;
}

.sendbird--mobile-mode .sendbird-thumbnail-message-item-body {
  min-width: 160px;
}

.sendbird-thumbnail-message-item-body.reactions {
  border-radius: 16px 16px 0px 0px;
}

.sendbird-theme--light .sendbird-thumbnail-message-item-body {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-thumbnail-message-item-body {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-thumbnail-message-item-body.mouse-hover, .sendbird-theme--light .sendbird-thumbnail-message-item-body:hover {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-thumbnail-message-item-body.mouse-hover, .sendbird-theme--dark .sendbird-thumbnail-message-item-body:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__thumbnail {
  position: absolute;
  border-radius: 16px;
}

.sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__placeholder {
  position: absolute;
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__placeholder .sendbird-thumbnail-message-item-body__placeholder__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--sendbird-light-background-50);
}

.sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__image-cover {
  position: absolute;
  top: 0px;
  display: none;
  width: 100%;
  height: 270px;
  border-radius: 16px;
  background-color: var(--sendbird-light-overlay-01);
}

.sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__video {
  position: absolute;
  width: 100%;
  height: 270px;
  border-radius: 16px;
}

.sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__icon-wrapper {
  position: absolute;
  width: 100%;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__icon-wrapper .sendbird-thumbnail-message-item-body__icon-wrapper__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--sendbird-light-ondark-01);
}

.sendbird-thumbnail-message-item-body:hover {
  cursor: pointer;
}

.sendbird-thumbnail-message-item-body:hover .sendbird-thumbnail-message-item-body__image-cover {
  display: inline-flex;
}
.sendbird-unknown-message-item-body {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 16px;
}

.sendbird-unknown-message-item-body.reactions {
  border-radius: 16px 16px 0px 0px;
}

.sendbird-theme--light .sendbird-unknown-message-item-body.outgoing {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-unknown-message-item-body.outgoing {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-unknown-message-item-body.incoming {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-unknown-message-item-body.incoming {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-unknown-message-item-body.mouse-hover.outgoing, .sendbird-theme--light .sendbird-unknown-message-item-body:hover.outgoing {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-unknown-message-item-body.mouse-hover.outgoing, .sendbird-theme--dark .sendbird-unknown-message-item-body:hover.outgoing {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-unknown-message-item-body.mouse-hover.incoming, .sendbird-theme--light .sendbird-unknown-message-item-body:hover.incoming {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-unknown-message-item-body.mouse-hover.incoming, .sendbird-theme--dark .sendbird-unknown-message-item-body:hover.incoming {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-unknown-message-item-body .sendbird-unknown-message-item-body__header {
  display: block;
}

.sendbird-unknown-message-item-body .sendbird-unknown-message-item-body__description {
  display: block;
}
.sendbird-template-message-item-body {
  max-width: 100%;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  background-color: transparent;
  white-space: pre-line;
  word-break: break-word;
}

.sendbird-template-message-item-body__fallback_message {
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  padding: 8px 12px;
  border-radius: 16px;
  white-space: pre-line;
  word-break: break-word;
}

.sendbird-theme--light .sendbird-template-message-item-body__fallback_message.incoming {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-template-message-item-body__fallback_message.incoming {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-template-message-item-body__fallback_message.outgoing {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-template-message-item-body__fallback_message.outgoing {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-template-message-item-body__fallback_message.mouse-hover.incoming, .sendbird-theme--light .sendbird-template-message-item-body__fallback_message:hover.incoming {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-template-message-item-body__fallback_message.mouse-hover.incoming, .sendbird-theme--dark .sendbird-template-message-item-body__fallback_message:hover.incoming {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-template-message-item-body__fallback_message.mouse-hover.outgoing, .sendbird-theme--light .sendbird-template-message-item-body__fallback_message:hover.outgoing {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-template-message-item-body__fallback_message.mouse-hover.outgoing, .sendbird-theme--dark .sendbird-template-message-item-body__fallback_message:hover.outgoing {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-template-message-item-body__fallback_message .sendbird-template-message-item-body__fallback_message__header {
  display: block;
}

.sendbird-template-message-item-body__fallback_message .sendbird-template-message-item-body__fallback_message__description {
  display: block;
}

.sendbird-template-loading-message-item-body {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  border-radius: 16px;
  height: 274px;
  width: 100%;
}

.sendbird-theme--light .sendbird-template-loading-message-item-body.incoming {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-template-loading-message-item-body.incoming {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-template-loading-message-item-body.outgoing {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-template-loading-message-item-body.outgoing {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-template-loading-message-item-body.mouse-hover.incoming, .sendbird-theme--light .sendbird-template-loading-message-item-body:hover.incoming {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-template-loading-message-item-body.mouse-hover.incoming, .sendbird-theme--dark .sendbird-template-loading-message-item-body:hover.incoming {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-template-loading-message-item-body.mouse-hover.outgoing, .sendbird-theme--light .sendbird-template-loading-message-item-body:hover.outgoing {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-template-loading-message-item-body.mouse-hover.outgoing, .sendbird-theme--dark .sendbird-template-loading-message-item-body:hover.outgoing {
  background-color: var(--sendbird-dark-primary-300);
}

.sb-message-template__border {
  position: relative;
}

.sb-message-template__border::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: inset 0 0 0 var(--border-width) var(--border-color);
  border-radius: var(--border-radius);
}

.sb-message-template__action {
  cursor: pointer;
}

.sb-message-template__parent {
  font-family: 'Roboto', serif;
}

.sendbird-theme--light .sb-message-template__box {
}

.sendbird-theme--dark .sb-message-template__box {
}

.sendbird-theme--light .sb-message-template__text {
}

.sendbird-theme--dark .sb-message-template__text {
}

.sb-message-template__text {
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

[dir="ltr"] .sb-message-template__text {
  text-align: left;
}

[dir="rtl"] .sb-message-template__text {
  text-align: right;
}

.sb-message-template__text-button {
  font-family: inherit;
  background-color: #e0e0e0;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  /*line-height: 1.43;*/
  /* opacity: 0.8; */
}

.sendbird-theme--light .sb-message-template__text-button {
  color: #742ddd;
}

.sendbird-theme--dark .sb-message-template__text-button {
  color: #c2a9fa;
}

.sb-message-template__text-button:hover {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.sb-message-template__image-button {
}

.sb-message-template__text-button,
.sb-message-template__image-button {
  cursor: pointer;
  border-style: none;
}

.sb-message-template__parent {
  border-radius: unset;
  background-color: transparent;
  overflow: hidden;
}

.sb-message-template__parent * {
  box-sizing: border-box;
}

.sb-message-template__carousel {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  touch-action: pan-y;
}

.sendbird-message-template__root * {
  box-sizing: border-box;
}

.sendbird-message-template__root {
  border-radius: 0;
  font-family: var(--sendbird-font-family-default);
}
.sendbird-theme--light {
  --form__chip__color__selected-focus: var(--sendbird-light-primary-300);
  --form__chip__color__submitted-default: var(--sendbird-light-onlight-02);
  --form__chip__bg-color__default: var(--sendbird-light-background-50);
  --form__chip__bg-color__selected: var(--sendbird-light-primary-100);
  --form__chip__bg-color__selected-hover: var(--sendbird-light-background-50);
  --form__chip__bg-color__selected-focus: var(--sendbird-light-background-50);
  --form__chip__bg-color__submitted-default: var(--sendbird-dark-ondark-02);
  --form__chip__bg-color__submitted-selected: var(--sendbird-dark-ondark-02);
  --form__chip__bd-color__default-focus: var(--sendbird-light-primary-300);
  --form__chip__bd-color__selected-focus: var(--sendbird-light-primary-300);
  --form__submitted-input-box__color: var(--sendbird-dark-onlight-01);
  --form__submitted-input-box__bg-color: var(--sendbird-dark-ondark-02);
  --form__submit-button__color__disabled: var(--sendbird-light-background-50);
  --form__submit-button__bg-color__disabled: var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark {
  --form__chip__color__selected-focus: var(--sendbird-dark-ondark-02);
  --form__chip__color__submitted-default: var(--sendbird-dark-ondark-04);
  --form__chip__bg-color__default: var(--sendbird-light-onlight-03);
  --form__chip__bg-color__selected: var(--sendbird-dark-background-600);
  --form__chip__bg-color__selected-hover: var(--sendbird-light-onlight-03);
  --form__chip__bg-color__selected-focus: var(--sendbird-light-onlight-03);
  --form__chip__bg-color__submitted-default: var(--sendbird-light-onlight-04);
  --form__chip__bg-color__submitted-selected: var(--sendbird-light-onlight-04);
  --form__chip__bd-color__default-focus: var(--sendbird-dark-ondark-02);
  --form__chip__bd-color__selected-focus: var(--sendbird-dark-ondark-02);
  --form__submitted-input-box__color: var(--sendbird-dark-ondark-01);
  --form__submitted-input-box__bg-color: var(--sendbird-light-onlight-04);
  --form__submit-button__color__disabled: var(--sendbird-light-onlight-01);
  --form__submit-button__bg-color__disabled: var(--sendbird-dark-ondark-04);
}

.sendbird-form-message__submit-button {
  width: 100%;
}

.sendbird-form-message__submit-button.sendbird-button__disabled {
  cursor: not-allowed;
}

.sendbird-form-message__submit-button.sendbird-button__disabled.sendbird-button--primary .sendbird-button__text {
  color: var(--form__submit-button__color__disabled);
}

.sendbird-theme--light .sendbird-form-message__submit-button.sendbird-button__disabled {
  background-color: var(--form__submit-button__bg-color__disabled);
  border: none;
}

.sendbird-theme--light .sendbird-form-message__submit-button.sendbird-button__disabled:hover {
  background-color: var(--form__submit-button__bg-color__disabled);
}

.sendbird-theme--dark .sendbird-form-message__submit-button.sendbird-button__disabled {
  background-color: var(--form__submit-button__bg-color__disabled);
  border: none;
}

.sendbird-theme--dark .sendbird-form-message__submit-button.sendbird-button__disabled:hover {
  background-color: var(--form__submit-button__bg-color__disabled);
}

.sendbird-form-message__input__label {
  line-height: 14px;
  display: block;
  font-weight: 500;
  margin-bottom: 6px;
}

.sendbird-form-message__input__title-container {
  display: inline-block;
}

.sendbird-form-message__input__container {
  width: 100%;
  position: relative;
}

.sendbird-theme--light .sendbird-form-message__input__title-optional {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-form-message__input__title-optional {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-form-message__input__root {
  padding-bottom: 12px;
  width: 100%;
  font-family: var(--sendbird-font-family-default);
}

.sendbird-form-message__input__textarea {
  display: block;
  resize: none;
  height: 96px !important;
  padding: 7px 12px !important;
}

.sendbird-theme--light .sendbird-form-message__input__textarea ::-moz-placeholder {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--light .sendbird-form-message__input__textarea ::placeholder {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-form-message__input__textarea ::-moz-placeholder {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--dark .sendbird-form-message__input__textarea ::placeholder {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-form-message__error-label {
  margin-top: 4px;
  color: var(--sendbird-light-error-300);
}

.sendbird-form-message__submitted-input-box-text {
  width: calc(100% - 24px);
  line-height: 20px;
}

.sendbird-form-message__root.sendbird-form-message__root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 12px;
  max-width: 100%;
  box-sizing: border-box;
  min-width: 220px;
  border-radius: 16px;
  overflow: hidden;
}

.sendbird--mobile-mode .sendbird-form-message__root.sendbird-form-message__root {
  max-width: calc(100vw - 164px);
}

.sendbird-form-message__input__chip-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  grid-gap: 4px;
  gap: 4px;
  flex-wrap: wrap;
}

[dir="ltr"] .sendbird-form-message__submitted-check-icon-chip {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-form-message__submitted-check-icon-chip {
  margin-right: 4px;
}

.sendbird-form-message__submitted-check-icon-container {
  display: flex;
  height: auto;
  align-items: flex-end;
}

.sendbird-form-message__submitted-input-box {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  display: flex;
  word-wrap: break-word;
  width: calc(100% - 24px);
  color: var(--form__submitted-input-box__color);
  background-color: var(--form__submitted-input-box__bg-color);
  border: none;
  pointer-events: none;
  border-radius: 4px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 8px 12px;
  min-height: 20px;
}

.sendbird-form-message__submitted-input-box.textarea {
  padding: 9px 12px;
  min-height: 78px;
}

.sendbird-form-message__input__chip-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
}

.sendbird-form-message__input__chip {
  border-radius: 100px;
  padding: 5px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: calc(100% - 32px);
}

.sendbird-theme--light .sendbird-form-message__input__chip.default {
  color: var(--sendbird-light-onlight-02);
  border: 1px solid var(--sendbird-light-onlight-04);
  cursor: pointer;
  background-color: var(--form__chip__bg-color__default);
}

.sendbird-theme--light .sendbird-form-message__input__chip.default:hover {
  color: var(--sendbird-light-primary-300);
  border: 1px solid var(--sendbird-light-primary-300);
}

.sendbird-theme--light .sendbird-form-message__input__chip.default:focus {
  color: var(--sendbird-light-onlight-02);
  border: 1px solid var(--form__chip__bd-color__default-focus);
  outline: none;
  box-shadow: 0 0 0 1px var(--form__chip__bd-color__default-focus);
}

.sendbird-theme--dark .sendbird-form-message__input__chip.default {
  color: var(--sendbird-dark-ondark-02);
  border: 1px solid var(--sendbird-dark-ondark-04);
  cursor: pointer;
  background-color: var(--form__chip__bg-color__default);
}

.sendbird-theme--dark .sendbird-form-message__input__chip.default:hover {
  color: var(--sendbird-dark-primary-200);
  border: 1px solid var(--sendbird-dark-primary-200);
}

.sendbird-theme--dark .sendbird-form-message__input__chip.default:focus {
  color: var(--sendbird-dark-ondark-02);
  border: 1px solid var(--form__chip__bd-color__default-focus);
  outline: none;
  box-shadow: 0 0 0 1px var(--form__chip__bd-color__default-focus);
}

.sendbird-theme--light .sendbird-form-message__input__chip.selected {
  color: var(--sendbird-light-primary-300);
  background-color: var(--form__chip__bg-color__selected);
  border: 1px solid var(--sendbird-light-primary-300);
  cursor: pointer;
}

.sendbird-theme--light .sendbird-form-message__input__chip.selected:hover {
  color: var(--sendbird-light-primary-300);
  background-color: var(--form__chip__bg-color__selected-hover);
  border: 1px solid var(--sendbird-light-primary-300);
}

.sendbird-theme--light .sendbird-form-message__input__chip.selected:focus {
  color: var(--form__chip__color__selected-focus);
  background-color: var(--form__chip__bg-color__selected-focus);
  border: 1px solid var(--form__chip__bd-color__selected-focus);
  outline: none;
  box-shadow: 0 0 0 1px var(--form__chip__bd-color__selected-focus);
}

.sendbird-theme--dark .sendbird-form-message__input__chip.selected {
  color: var(--sendbird-dark-primary-200);
  background-color: var(--form__chip__bg-color__selected);
  border: 1px solid var(--sendbird-dark-primary-200);
  cursor: pointer;
}

.sendbird-theme--dark .sendbird-form-message__input__chip.selected:hover {
  color: var(--sendbird-dark-primary-200);
  background-color: var(--form__chip__bg-color__selected-hover);
  border: 1px solid var(--sendbird-dark-primary-200);
}

.sendbird-theme--dark .sendbird-form-message__input__chip.selected:focus {
  color: var(--form__chip__color__selected-focus);
  background-color: var(--form__chip__bg-color__selected-focus);
  border: 1px solid var(--form__chip__bd-color__selected-focus);
  outline: none;
  box-shadow: 0 0 0 1px var(--form__chip__bd-color__selected-focus);
}

.sendbird-form-message__input__chip.submittedDefault {
  border: none;
  cursor: default;
  padding: 6px 12px;
}

.sendbird-theme--light .sendbird-form-message__input__chip.submittedDefault {
  color: var(--form__chip__color__submitted-default);
  background-color: var(--form__chip__bg-color__submitted-default);
}

.sendbird-theme--dark .sendbird-form-message__input__chip.submittedDefault {
  color: var(--form__chip__color__submitted-default);
  background-color: var(--form__chip__bg-color__submitted-default);
}

.sendbird-form-message__input__chip.submittedSelected {
  border: none;
  cursor: default;
  padding: 6px 12px;
}

.sendbird-theme--light .sendbird-form-message__input__chip.submittedSelected {
  color: var(--sendbird-light-onlight-01);
  background-color: var(--form__chip__bg-color__submitted-selected);
}

.sendbird-theme--dark .sendbird-form-message__input__chip.submittedSelected {
  color: var(--sendbird-dark-ondark-01);
  background-color: var(--form__chip__bg-color__submitted-selected);
}
.sendbird--mobile-mode .sendbird-message-feedback-modal-content__mobile {
  max-width: 280px;
  padding: 16px;
}

.sendbird-message-feedback-modal-footer__root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sendbird-message-feedback-modal-footer__root_failed {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 48px;
}

.sendbird-message-feedback-modal-footer__right-content {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.sendbird-message-feedback-modal-body__root {
  margin: 21px 0;
}

.sendbird--mobile-mode .sendbird-message-feedback-modal-header {
  font-size: 18px;
  line-height: 1.33;
}
.sendbird-iconbutton__feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  border-radius: 18px;
  border: 1px solid;
  padding: 5px 8px;
}

.sendbird-theme--light .sendbird-iconbutton__feedback {
  border-color: var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-iconbutton__feedback {
  border-color: var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-iconbutton__feedback:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-iconbutton__feedback:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-iconbutton__feedback.sendbird-iconbutton__feedback__disabled {
  cursor: not-allowed;
}

.sendbird-iconbutton__feedback.sendbird-iconbutton__feedback__disabled:hover {
  background-color: transparent;
}

.sendbird-iconbutton__feedback.sendbird-iconbutton__feedback__pressed:hover {
  background-color: transparent;
}

.sendbird-theme--light .sendbird-iconbutton__feedback.sendbird-iconbutton__feedback__pressed {
  border-color: var(--sendbird-light-primary-300);
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-iconbutton__feedback.sendbird-iconbutton__feedback__pressed {
  border-color: var(--sendbird-dark-primary-200);
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-iconbutton__feedback__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendbird-theme--light .sendbird-iconbutton__feedback__inner svg path {
  fill: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-iconbutton__feedback__inner svg path {
  fill: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-iconbutton__feedback__inner.sendbird-iconbutton__feedback__inner__disabled svg path {
  fill: var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-iconbutton__feedback__inner.sendbird-iconbutton__feedback__inner__disabled svg path {
  fill: var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-iconbutton__feedback__inner__pressed svg path {
  fill: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-iconbutton__feedback__inner__pressed svg path {
  fill: var(--sendbird-dark-onlight-01);
}
.sendbird-message__bottomsheet--feedback-options-menu {
  width: 100vw;
  border-radius: 8px 8px 0 0;
}

.sendbird-theme--light .sendbird-message__bottomsheet--feedback-options-menu {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message__bottomsheet--feedback-options-menu {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-message__bottomsheet--feedback-options-menu :first-child {
  border-radius: 8px 8px 0 0;
}

.sendbird-message__bottomsheet--feedback-option {
  cursor: pointer;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.sendbird-input {
  display: inline-block;
  width: 100%;
  height: 40px;
  position: relative;
}

.sendbird-input .sendbird-input__input {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  font-family: var(--sendbird-font-family-default);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  padding: 10px 16px;
  box-sizing: border-box;
  background-color: inherit;
}

.sendbird-theme--light .sendbird-input .sendbird-input__input {
  color: var(--sendbird-light-onlight-01);
  caret-color: var(--sendbird-light-primary-300);
  border: solid 1px var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-input .sendbird-input__input {
  color: var(--sendbird-dark-ondark-01);
  caret-color: var(--sendbird-dark-primary-200);
  border: solid 1px var(--sendbird-dark-ondark-03);
}

.sendbird-input .sendbird-input__input:focus {
  outline: none;
}

.sendbird-theme--light .sendbird-input .sendbird-input__input:focus {
  box-shadow: 0 1px 5px 0 var(--sendbird-light-onlight-04), 0 0 1px 0 rgba(0, 0, 0, 0.16), 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  color: var(--sendbird-light-onlight-01);
  border: solid 2px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-input .sendbird-input__input:focus {
  box-shadow: 0 1px 5px 0 var(--sendbird-dark-ondark-04), 0 0 1px 0 rgba(0, 0, 0, 0.16), 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  color: var(--sendbird-dark-ondark-01);
  border: solid 2px var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-input .sendbird-input__input:active {
  box-shadow: 0 1px 5px 0 var(--sendbird-light-onlight-04), 0 0 1px 0 rgba(0, 0, 0, 0.16), 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  color: var(--sendbird-light-onlight-01);
  border: solid 1px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-input .sendbird-input__input:active {
  box-shadow: 0 1px 5px 0 var(--sendbird-dark-ondark-04), 0 0 1px 0 rgba(0, 0, 0, 0.16), 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  color: var(--sendbird-dark-ondark-01);
  border: solid 1px var(--sendbird-dark-primary-200);
}

.sendbird-input .sendbird-input__input:disabled {
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-input .sendbird-input__input:disabled {
  background-color: var(--sendbird-light-background-100);
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-input .sendbird-input__input:disabled {
  background-color: var(--sendbird-dark-background-500);
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-input .sendbird-input__placeholder {
  position: absolute;
  pointer-events: none;
  top: 10px;
  font-family: var(--sendbird-font-family-default);
  font-size: 14px;
  line-height: 1.43;
}

[dir="ltr"] .sendbird-input .sendbird-input__placeholder {
  left: 16px;
}

[dir="rtl"] .sendbird-input .sendbird-input__placeholder {
  right: 16px;
}

.sendbird-theme--light .sendbird-input .sendbird-input__placeholder {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-input .sendbird-input__placeholder {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light {
  --form__input__bg-color_default: var(--sendbird-light-background-50);
  --form__input__bg-color_disabled: var(--sendbird-dark-ondark-02);
  --form__input__bd-color_error: var(--sendbird-light-error-300);
}

.sendbird-theme--dark {
  --form__input__bg-color_default: var(--sendbird-light-onlight-03);
  --form__input__bg-color_disabled: var(--sendbird-light-onlight-04);
  --form__input__bd-color_error: var(--sendbird-dark-error-200);
}

.sendbird-input_for_form {
  display: inline-block;
  width: 100%;
  height: unset;
  position: relative;
}

.sendbird-input_for_form .sendbird-input__input {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px;
  font-family: var(--sendbird-font-family-default);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  padding: 7px 12px;
  box-sizing: border-box;
  background-color: inherit;
}

.sendbird-theme--light .sendbird-input_for_form .sendbird-input__input {
  caret-color: var(--sendbird-light-primary-300);
  color: var(--sendbird-light-onlight-01);
  background-color: var(--form__input__bg-color_default);
  border: solid 1px var(--sendbird-light-onlight-04);
}

.sendbird-theme--light .sendbird-input_for_form .sendbird-input__input:disabled {
  pointer-events: none;
  border: none;
  cursor: not-allowed;
  background-color: var(--form__input__bg-color_disabled);
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--light .sendbird-input_for_form .sendbird-input__input:focus {
  border: solid 1px var(--sendbird-light-primary-300) !important;
  outline: none;
  box-shadow: 0 0 0 1px var(--sendbird-light-primary-300);
}

.sendbird-theme--light .sendbird-input_for_form .sendbird-input__input:active {
  box-shadow: none;
  border: solid 1px var(--sendbird-light-primary-300);
}

.sendbird-theme--light .sendbird-input_for_form .sendbird-input__input.error {
  border: solid 1px var(--form__input__bd-color_error);
}

.sendbird-theme--dark .sendbird-input_for_form .sendbird-input__input {
  caret-color: var(--sendbird-dark-primary-200);
  color: var(--sendbird-dark-ondark-01);
  background-color: var(--form__input__bg-color_default);
  border: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird-theme--dark .sendbird-input_for_form .sendbird-input__input:disabled {
  pointer-events: none;
  border: none;
  cursor: not-allowed;
  background-color: var(--form__input__bg-color_disabled);
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--dark .sendbird-input_for_form .sendbird-input__input:focus {
  border: solid 1px var(--sendbird-dark-primary-200) !important;
  outline: none;
  box-shadow: 0 0 0 1px var(--sendbird-dark-primary-200);
}

.sendbird-theme--dark .sendbird-input_for_form .sendbird-input__input:active {
  box-shadow: none;
  border: solid 1px var(--sendbird-dark-primary-200);
}

.sendbird-theme--dark .sendbird-input_for_form .sendbird-input__input.error {
  border: solid 1px var(--form__input__bd-color_error);
}

.sendbird-input_for_form .sendbird-input__placeholder {
  width: calc(100% - 26px);
  height: calc(100% - 16px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: absolute;
  pointer-events: none;
  top: 8px;
  font-family: var(--sendbird-font-family-default);
  font-size: 14px;
  line-height: 1.43;
}

[dir="ltr"] .sendbird-input_for_form .sendbird-input__placeholder {
  left: 13px;
}

[dir="rtl"] .sendbird-input_for_form .sendbird-input__placeholder {
  right: 13px;
}

.sendbird-theme--light .sendbird-input_for_form .sendbird-input__placeholder {
  color: var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-input_for_form .sendbird-input__placeholder {
  color: var(--sendbird-dark-ondark-03);
}

.sendbird-input_for_form .sendbird-input__placeholder.textarea {
  -webkit-line-clamp: 4;
}

.sendbird-input-label {
  margin: 4px 0;
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-suggested-replies {
  position: relative;
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
}

.sendbird-suggested-replies.vertical {
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
}

.sendbird-suggested-replies.vertical .sendbird-suggested-replies__option:not(:first-child) {
  margin-top: 8px;
}

.sendbird-suggested-replies.horizontal {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
}

.sendbird--mobile-mode .sendbird-suggested-replies.horizontal {
  margin-left: -12px;
  margin-right: -12px;
}

[dir="ltr"] .sendbird--mobile-mode .sendbird-suggested-replies.horizontal {
  padding-left: 52px;
  padding-right: 12px;
}

[dir="rtl"] .sendbird--mobile-mode .sendbird-suggested-replies.horizontal {
  padding-right: 52px;
  padding-left: 12px;
}

[dir="ltr"] .sendbird-suggested-replies.horizontal .sendbird-suggested-replies__option:not(:last-child) {
  margin-right: 8px;
}

[dir="rtl"] .sendbird-suggested-replies.horizontal .sendbird-suggested-replies__option:not(:last-child) {
  margin-left: 8px;
}

.sendbird-suggested-replies::-webkit-scrollbar {
  display: none;
}

.sendbird-theme--light .sendbird-suggested-replies {
  font-family: var(--sendbird-font-family-default);
}

.sendbird-theme--dark .sendbird-suggested-replies {
  font-family: var(--sendbird-font-family-default);
}

.sendbird-suggested-replies__option {
  white-space: pre-wrap;
  font-size: 12px;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  cursor: pointer;
  word-break: break-word;
}

.sendbird-theme--light .sendbird-suggested-replies__option {
  color: var(--sendbird-light-primary-300);
  border: 1px solid var(--sendbird-light-primary-300);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-suggested-replies__option {
  color: var(--sendbird-dark-primary-200);
  border: 1px solid var(--sendbird-dark-primary-200);
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-theme--light .sendbird-suggested-replies__option:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-suggested-replies__option:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-suggested-replies__option:active {
  background-color: var(--sendbird-light-primary-300);
  color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-suggested-replies__option:active {
  background-color: var(--sendbird-dark-primary-200);
  color: var(--sendbird-dark-background-600);
}

.sendbird-suggested-replies__option.horizontal {
  white-space: nowrap;
}

.sendbird-suggested-replies__option.vertical {
  white-space: pre-wrap;
  max-width: calc(100% - 70px);
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-mention-suggest-list {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 56px);
  max-height: 260px;
  overflow: scroll;
  margin-left: 28px;
  margin-right: 28px;
}

.sendbird-theme--light .sendbird-mention-suggest-list {
  border: 1px solid var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-mention-suggest-list {
  border: 1px solid var(--sendbird-dark-background-500);
}

.sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 8px;
}

.sendbird-theme--light .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-theme--light .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item.focused {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item.focused {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__avatar {
  position: relative;
  display: inline-block;
}

.sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__nickname {
  position: relative;
  display: inline-block;
  max-width: calc(100% - 250px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir="ltr"] .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__nickname {
  margin-left: 16px;
}

[dir="rtl"] .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__nickname {
  margin-right: 16px;
}

.sendbird--mobile-mode .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__nickname {
  max-width: calc(100% - 150px);
}

.sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__user-id {
  position: absolute;
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir="ltr"] .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__user-id {
  right: 16px;
}

[dir="rtl"] .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__user-id {
  left: 16px;
}

.sendbird--mobile-mode .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__user-id {
  max-width: 100px;
}

.sendbird-mention-suggest-list .sendbird-mention-suggest-list__notice-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 8px;
}

.sendbird-theme--light .sendbird-mention-suggest-list .sendbird-mention-suggest-list__notice-item {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-mention-suggest-list .sendbird-mention-suggest-list__notice-item {
  background-color: var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-mention-suggest-list .sendbird-mention-suggest-list__notice-item .sendbird-mention-suggest-list__notice-item__icon {
  margin-right: 4px;
}

[dir="rtl"] .sendbird-mention-suggest-list .sendbird-mention-suggest-list__notice-item .sendbird-mention-suggest-list__notice-item__icon {
  margin-left: 4px;
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-fileviewer__header__right__actions__download,
.sendbird-fileviewer__header__right__actions__delete,
.sendbird-fileviewer__header__right__actions__close {
  width: 64px;
  padding: 21px;
  box-sizing: border-box;
  cursor: pointer;
}

.sendbird-fileviewer__header__right__actions__download .disabled,
.sendbird-fileviewer__header__right__actions__delete .disabled,
.sendbird-fileviewer__header__right__actions__close .disabled {
  cursor: not-allowed;
}

.sendbird-fileviewer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 100000;
}

[dir="ltr"] .sendbird-fileviewer {
  left: 0;
}

[dir="rtl"] .sendbird-fileviewer {
  right: 0;
}

.sendbird-theme--light .sendbird-fileviewer {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-fileviewer {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-fileviewer .sendbird-fileviewer__header {
  height: 64px;
  min-height: 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header {
  position: relative;
}

.sendbird-theme--light .sendbird-fileviewer .sendbird-fileviewer__header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__avatar {
  display: flex;
  margin-inline-end: 8px;
}

.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__avatar {
  margin-inline-end: 12px;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container {
  flex-direction: column;
  align-items: unset;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container .sendbird-fileviewer__header__left__filename {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-inline-end: 8px;
  padding-top: 3px;
}

.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container .sendbird-fileviewer__header__left__filename {
  display: block;
  max-width: calc(100vw - 240px);
  padding-top: 0;
  margin-inline-end: 0;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__text-container .sendbird-fileviewer__header__left__sender-name {
  line-height: 1.1;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right {
  display: flex;
  width: 160px;
  justify-content: flex-end;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions {
  display: flex;
  flex-direction: row;
}

[dir="ltr"] .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions {
  right: 0;
}

[dir="rtl"] .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions {
  left: 0;
}

[dir="ltr"] .sendbird-theme--light .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-right: 1px solid var(--sendbird-light-onlight-04);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-left: 1px solid var(--sendbird-dark-ondark-04);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-right: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-fileviewer .sendbird-fileviewer__content {
  height: calc(100% - 72px);
  margin-top: 4px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__video {
  max-width: 100%;
  max-height: 100%;
}

.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__img {
  max-width: 90%;
  max-height: 90%;
}

.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__unsupported {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-notification--hide,
.sendbird-notification {
  position: absolute;
}

.sendbird-notification {
  margin-top: 8px;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 4px;
  padding: 0px 2px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendbird-theme--light .sendbird-notification {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-notification {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-notification:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-notification:hover {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-notification:hover {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-notification.sendbird-notification--frozen {
  background-color: var(--sendbird-light-information-100);
}

.sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen {
  background-color: var(--sendbird-dark-information-100);
}

.sendbird-theme--light .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-notification .sendbird-notification__text {
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir="ltr"] .sendbird-notification .sendbird-notification__text {
  margin-right: 8px;
}

[dir="rtl"] .sendbird-notification .sendbird-notification__text {
  margin-left: 8px;
}

.sendbird-notification--hide {
  display: none;
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-notification {
  margin-top: 8px;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 4px;
  padding: 0px 2px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendbird-theme--light .sendbird-notification {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-notification {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-notification:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-notification:hover {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-notification:hover {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-notification.sendbird-notification--frozen {
  background-color: var(--sendbird-light-information-100);
}

.sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen {
  background-color: var(--sendbird-dark-information-100);
}

.sendbird-theme--light .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-notification .sendbird-notification__text {
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir="ltr"] .sendbird-notification .sendbird-notification__text {
  margin-right: 8px;
}

[dir="rtl"] .sendbird-notification .sendbird-notification__text {
  margin-left: 8px;
}
.typing-dots-container {
  align-items: center;
  border-radius: 16px;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  justify-content: center;
  padding: 16px 12px;
}

.sendbird-theme--light .typing-dots-container {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .typing-dots-container {
  background-color: var(--sendbird-dark-background-400);
}

.typing-dots-container span {
  animation: blink 1.4s infinite;
  animation-fill-mode: both;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.sendbird-theme--light .typing-dots-container span {
  background-color: var(--sendbird-light-background-700);
}

.sendbird-theme--dark .typing-dots-container span {
  background-color: var(--sendbird-dark-background-50);
}

.typing-dots-container span:nth-child(1) {
  animation-delay: 0.4s;
}

.typing-dots-container span:nth-child(2) {
  animation-delay: 0.6s;
}

.typing-dots-container span:nth-child(3) {
  animation-delay: 0.8s;
}

@keyframes blink {
  0% {
    opacity: 0.12;
    transform: scale(1);
  }

  21.43% {
    opacity: 0.38;
    transform: scale(1.2);
  }

  42.86% {
    opacity: 0.12;
    transform: scale(1);
  }

  100% {
    opacity: 0.12;
    transform: scale(1);
  }
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-message-input-wrapper--voice-message,
.sendbird-message-input-wrapper {
  position: relative;
  width: 100%;
}

.sendbird-message-input-wrapper--voice-message .sendbird-message-input-wrapper__quote-message-input,
.sendbird-message-input-wrapper .sendbird-message-input-wrapper__quote-message-input {
  position: relative;
  width: 100%;
}

.sendbird-theme--light .sendbird-message-input-wrapper--voice-message .sendbird-message-input-wrapper__quote-message-input,
.sendbird-theme--light .sendbird-message-input-wrapper .sendbird-message-input-wrapper__quote-message-input {
  border-top: solid 1px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-message-input-wrapper--voice-message .sendbird-message-input-wrapper__quote-message-input,
.sendbird-theme--dark .sendbird-message-input-wrapper .sendbird-message-input-wrapper__quote-message-input {
  border-top: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird-message-input-wrapper--voice-message .sendbird-message-input-wrapper__message-input,
.sendbird-message-input-wrapper .sendbird-message-input-wrapper__message-input {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 24px;
}

.sendbird--mobile-mode .sendbird-message-input-wrapper--voice-message .sendbird-message-input-wrapper__message-input,
.sendbird--mobile-mode .sendbird-message-input-wrapper .sendbird-message-input-wrapper__message-input {
  padding: 0px 16px;
}

.sendbird-theme--light .sendbird-message-input-wrapper--voice-message {
  border-top: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-message-input-wrapper--voice-message {
  border-top: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-quote_message_input {
  position: relative;
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-quote_message_input {
  padding: 16px 80px 16px 40px;
}

[dir="rtl"] .sendbird-quote_message_input {
  padding: 16px 40px 16px 80px;
}

.sendbird-quote_message_input .sendbird-quote_message_input__avatar {
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendbird-theme--light .sendbird-quote_message_input .sendbird-quote_message_input__avatar {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-quote_message_input .sendbird-quote_message_input__avatar {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-quote_message_input .sendbird-quote_message_input__body {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.sendbird-quote_message_input .sendbird-quote_message_input__body .sendbird-quote_message_input__body__sender-name {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.sendbird-quote_message_input .sendbird-quote_message_input__body .sendbird-quote_message_input__body__message-content {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.sendbird-quote_message_input .sendbird-quote_message_input__close-button {
  position: absolute;
  top: 26px;
}

[dir="ltr"] .sendbird-quote_message_input .sendbird-quote_message_input__close-button {
  right: 40px;
}

[dir="rtl"] .sendbird-quote_message_input .sendbird-quote_message_input__close-button {
  left: 40px;
}

.sendbird-quote_message_input .sendbird-quote_message_input__close-button:hover {
  cursor: pointer;
}
/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-voice-message-input-wrapper .sendbird-voice-message-input__indicator {
  height: 40px;
}

.sendbird-voice-message-input-wrapper-alert__body {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 74px;
}

.sendbird-voice-message-input-wrapper-alert__body__ok-button {
  width: 74px;
}
.sendbird-voice-message-input {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 110px;
  padding-top: 22px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
}

.sendbird-voice-message-input__indicator {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.sendbird-voice-message-input__indicator__progress-bar {
  position: relative;
  display: inline-flex;
  width: 100%;
  border-radius: 28px;
  overflow: hidden;
}

.sendbird-voice-message-input__indicator__on-rec {
  position: absolute;
  top: 14px;
}

[dir="ltr"] .sendbird-voice-message-input__indicator__on-rec {
  right: 53px;
}

[dir="rtl"] .sendbird-voice-message-input__indicator__on-rec {
  left: 53px;
}

.sendbird-voice-message-input__indicator__playback-time {
  position: absolute;
  top: 10px;
}

[dir="ltr"] .sendbird-voice-message-input__indicator__playback-time {
  right: 16px;
}

[dir="rtl"] .sendbird-voice-message-input__indicator__playback-time {
  left: 16px;
}

.sendbird-voice-message-input__controler {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  height: 34px;
  min-height: 34px;
}

.sendbird-voice-message-input__controler__main {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-voice-message-input__controler__main {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-voice-message-input__controler__main {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-voice-message-input__controler__main:hover {
  cursor: pointer;
}

.sendbird-voice-message-input__controler__submit {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-voice-message-input__controler__submit {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-voice-message-input__controler__submit {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-voice-message-input__controler__submit:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-voice-message-input__controler__submit.voice-message--disabled {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-voice-message-input__controler__submit.voice-message--disabled {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-voice-message-input__controler__submit.voice-message--disabled:hover {
  cursor: default;
}

@keyframes flickerAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.sendbird-voice-message-input__indicator__on-rec {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  animation: flickerAnimation 1s infinite;
}

.sendbird-theme--light .sendbird-voice-message-input__indicator__on-rec {
  background-color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-voice-message-input__indicator__on-rec {
  background-color: var(--sendbird-dark-error-300);
}

.sendbird-voice-message-input__indicator__progress-bar__bar {
  height: 40px;
}

.sendbird-controler-icon {
  position: relative;
  display: inline-block;
}

.sendbird-controler-icon.record-icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-controler-icon.record-icon {
  background-color: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-controler-icon.record-icon {
  background-color: var(--sendbird-dark-error-300);
}

.sendbird-controler-icon.stop-icon {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  border-radius: 2px;
}

.sendbird-theme--light .sendbird-controler-icon.stop-icon {
  background-color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-controler-icon.stop-icon {
  background-color: var(--sendbird-dark-ondark-01);
}

.sendbird-controler-icon.pause-icon {
  display: inline-flex;
  width: 100%;
  grid-gap: 3.75px;
  gap: 3.75px;
  width: 13.75px;
  height: 13.75px;
  min-width: 13.75px;
  min-height: 13.75px;
}

.sendbird-controler-icon.pause-icon-inner {
  display: inline-flex;
  min-width: 4.38px;
  border-radius: 2px;
}

.sendbird-theme--light .sendbird-controler-icon.pause-icon-inner {
  background-color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-controler-icon.pause-icon-inner {
  background-color: var(--sendbird-dark-ondark-01);
}
/*
  We are sharing the same CSS file
  * the same components
    1. `~/modules/GroupChannelList/components/GroupChannelListUI`
    2. `~/modules/ChannelList/components/ChannelListUI`
  So must to keep the same structure with the component when you make a change on the one component of them
*/
.sendbird-channel-list {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-channel-list {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-channel-list {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird--mobile-mode .sendbird-channel-list {
  width: 100%;
}

.sendbird-channel-list__header {
  height: 64px;
  min-height: 64px;
}

.sendbird-channel-list__body {
  flex: 1 1;
  -ms-flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
/*
  We are sharing the same CSS file
  * the same components
    1. `~/modules/GroupChannelList/components/GroupChannelListHeader`
    2. `~/modules/ChannelList/components/ChannelListHeader`
  So must to keep the same structure with the component when you make a change on the one component of them
*/
.sendbird-channel-header {
  position: relative;
  height: 64px;
  min-height: 64px;
  width: 320px;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-channel-header {
  padding-left: 8px;
  padding-right: 16px;
}

[dir="rtl"] .sendbird-channel-header {
  padding-right: 8px;
  padding-left: 16px;
}

.sendbird-theme--light .sendbird-channel-header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-channel-header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}

.sendbird--mobile-mode .sendbird-channel-header {
  width: 100%;
}

.sendbird-channel-header.sendbird-channel-header--allow-edit .sendbird-channel-header__title {
  cursor: pointer;
}

.sendbird-channel-header .sendbird-channel-header__title {
  display: flex;
  flex-direction: row;
  height: 48px;
  border-radius: 4px;
}

.sendbird-theme--light .sendbird-channel-header .sendbird-channel-header__title:hover {
  background: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-channel-header .sendbird-channel-header__title:hover {
  background: var(--sendbird-dark-background-500);
}

.sendbird-channel-header .sendbird-channel-header__title:focus {
  outline: none;
}

.sendbird-theme--light .sendbird-channel-header .sendbird-channel-header__title:focus {
  background: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-channel-header .sendbird-channel-header__title:focus {
  background: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-channel-header .sendbird-channel-header__title:active {
  border: solid 2px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-channel-header .sendbird-channel-header__title:active {
  border: solid 2px var(--sendbird-dark-primary-200);
}

.sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title__left {
  width: 48px;
  padding: 8px;
  box-sizing: border-box;
}

.sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title__right {
  padding-top: 8px;
}

[dir="ltr"] .sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title__right {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title__right {
  margin-right: 4px;
}

.sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title__right__name,
.sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title__right__user-id {
  display: block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-channel-header .sendbird-channel-header__right-icon {
  position: absolute;
  top: 16px;
}

[dir="ltr"] .sendbird-channel-header .sendbird-channel-header__right-icon {
  right: 16px;
}

[dir="rtl"] .sendbird-channel-header .sendbird-channel-header__right-icon {
  left: 16px;
}
.sendbird-edit-user-profile section {
  margin-top: 16px;
}

.sendbird-edit-user-profile section .sendbird-input-label {
  display: block;
  margin-bottom: 12px;
}

.sendbird-edit-user-profile .sendbird-edit-user-profile__img .sendbird-input-label {
  margin-bottom: 12px;
}

.sendbird-edit-user-profile__img {
  position: relative;
}

.sendbird-edit-user-profile__img__avatar-button {
  position: absolute;
  top: 50px;
}

[dir="ltr"] .sendbird-edit-user-profile__img__avatar-button {
  left: 96px;
}

[dir="rtl"] .sendbird-edit-user-profile__img__avatar-button {
  right: 96px;
}

.sendbird-edit-user-profile__theme__theme-icon .sendbird-icon {
  cursor: pointer;
}
/*
  We are sharing the same CSS file
  * the same components
    1. `~/modules/GroupChannelList/components/GroupChannelListItem`
    2. `~/modules/ChannelList/components/ChannelPreview`
  So must to keep the same structure with the component when you make a change on the one component of them
*/
.sendbird-channel-preview__leave--mobile .sendbird-modal__content {
  max-width: calc(100% - 80px);
}

.sendbird-channel-preview__leave--mobile .sendbird-modal__close {
  display: none;
}

.sendbird-channel-preview__leave--mobile .sendbird-channel-preview__leave-label--mobile {
  text-decoration: none;
}

.sendbird-channel-preview__leave--mobile .sendbird-modal__header span {
  display: inline-block;
  width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird__offline .sendbird-channel-preview {
  cursor: not-allowed;
}

.sendbird-channel-preview {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  width: 320px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px 12px 16px;
}

.sendbird-theme--light .sendbird-channel-preview {
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-channel-preview {
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird--mobile-mode .sendbird-channel-preview {
  width: 100%;
}

.sendbird-channel-preview:focus {
  outline: none;
}

.sendbird-channel-preview .sendbird-channel-preview__avatar {
  width: 56px;
  height: 56px;
}

.sendbird-channel-preview .sendbird-channel-preview__content {
  width: 100%;
  height: 100%;
}

[dir="ltr"] .sendbird-channel-preview .sendbird-channel-preview__content {
  margin-left: 16px;
}

[dir="rtl"] .sendbird-channel-preview .sendbird-channel-preview__content {
  margin-right: 16px;
}

.sendbird--mobile-mode .sendbird-channel-preview .sendbird-channel-preview__content {
  position: relative;
  max-width: calc(100% - 72px);
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper {
  display: flex;
  justify-content: space-between;
  width: 216px;
  height: 16px;
}

.sendbird--mobile-mode .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper {
  width: auto;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir="ltr"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__broadcast-icon {
  padding-right: 4px;
}

[dir="rtl"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__broadcast-icon {
  padding-left: 4px;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__channel-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 128px;
  word-break: break-all;
  white-space: nowrap;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__total-members {
  margin-top: 2px;
}

[dir="ltr"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__total-members {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__total-members {
  margin-right: 4px;
}

[dir="ltr"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__frozen-icon {
  padding-left: 4px;
}

[dir="rtl"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__frozen-icon {
  padding-right: 4px;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__last-message-at {
  margin-bottom: 4px;
  white-space: nowrap;
}

[dir="ltr"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__last-message-at {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__last-message-at {
  margin-right: 4px;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__last-message-at.sendbird-message-status {
  max-width: 74px;
  justify-content: flex-end;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower {
  margin-top: 8px;
  width: 216px;
  height: 32px;
  display: flex;
  justify-content: space-between;
}

.sendbird--mobile-mode .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower {
  width: 100%;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__last-message {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__unread-message-count {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

[dir="ltr"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__unread-message-count {
  margin-left: 8px;
}

[dir="rtl"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__unread-message-count {
  margin-right: 8px;
}

.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__unread-message-count .sendbird-channel-preview__content__lower__unread-message-count__mention {
  display: inline-flex;
  align-items: center;
}

[dir="ltr"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__unread-message-count .sendbird-channel-preview__content__lower__unread-message-count__mention {
  margin-right: 4px;
}

[dir="rtl"] .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__unread-message-count .sendbird-channel-preview__content__lower__unread-message-count__mention {
  margin-left: 4px;
}

.sendbird-channel-preview .sendbird-channel-preview__action {
  position: absolute;
  top: 12px;
  display: inline-block;
}

[dir="ltr"] .sendbird-channel-preview .sendbird-channel-preview__action {
  right: 12px;
}

[dir="rtl"] .sendbird-channel-preview .sendbird-channel-preview__action {
  left: 12px;
}

.sendbird-channel-preview .sendbird-channel-preview__action .sendbird-iconbutton {
  display: none;
}

.sendbird-theme--light .sendbird-channel-preview:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-channel-preview:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-channel-preview:hover .sendbird-channel-preview__action .sendbird-iconbutton {
  display: inline-block;
}

.sendbird-channel-preview:hover .sendbird-channel-preview__content__upper__last-message-at {
  display: none;
}

.sendbird-channel-preview:hover .sendbird-channel-preview__content__lower__unread-message-count {
  display: none;
}

[dir="ltr"] .sendbird-channel-preview--active {
  padding: 12px 16px 12px 12px;
}

[dir="rtl"] .sendbird-channel-preview--active {
  padding: 12px 12px 12px 16px;
}

.sendbird-theme--light .sendbird-channel-preview--active {
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-primary-100);
}

[dir="ltr"] .sendbird-theme--light .sendbird-channel-preview--active {
  border-left: solid 4px var(--sendbird-light-primary-300);
}

[dir="rtl"] .sendbird-theme--light .sendbird-channel-preview--active {
  border-right: solid 4px var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-channel-preview--active {
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-700);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-channel-preview--active {
  border-left: solid 4px var(--sendbird-dark-primary-200);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-channel-preview--active {
  border-right: solid 4px var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-channel-preview--active .sendbird-channel-preview__content__upper__header__channel-name {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-channel-preview--active .sendbird-channel-preview__content__upper__header__channel-name {
  color: var(--sendbird-dark-primary-200);
}
.sendbird-badge {
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.sendbird-theme--light .sendbird-badge {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-badge {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-badge .sendbird-badge__text {
  margin: 0px 6px;
}
.sendbird-mention-user-label {
  display: inline-block;
  font-family: var(--sendbird-font-family-default);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  white-space: pre-line;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  height: 16px;
}

.sendbird-theme--light .sendbird-mention-user-label {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-mention-user-label {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-mention-user-label:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-mention-user-label.reverse {
  color: var(--sendbird-light-ondark-01);
}

.sendbird-theme--dark .sendbird-mention-user-label.reverse {
  color: var(--sendbird-dark-onlight-01);
}

.sendbird-mention-user-label.purple {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sendbird-theme--light .sendbird-mention-user-label.purple {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-mention-user-label.purple {
  color: var(--sendbird-dark-primary-200);
}
.sendbird--mobile-mode .sendbird-add-channel__modal .sendbird-modal__content {
  margin: 0 40px;
}

.sendbird-add-channel__rectangle-wrap {
  margin-top: 26px;
}

.sendbird-add-channel__rectangle-footer {
  margin-top: 32px;
}

[dir="ltr"] .sendbird-add-channel__rectangle-footer {
  text-align: right;
}

[dir="rtl"] .sendbird-add-channel__rectangle-footer {
  text-align: left;
}

.sendbird-add-channel__rectangle {
  width: 432px;
  height: 72px;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 25px 72px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.sendbird--mobile-mode .sendbird-add-channel__rectangle {
  min-width: 288px;
  width: 100%;
}

[dir="ltr"] .sendbird--mobile-mode .sendbird-add-channel__rectangle {
  padding-right: 0;
}

[dir="rtl"] .sendbird--mobile-mode .sendbird-add-channel__rectangle {
  padding-left: 0;
}

.sendbird-theme--light .sendbird-add-channel__rectangle {
  border: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-add-channel__rectangle {
  border: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-add-channel__rectangle .sendbird-icon {
  position: absolute;
  top: 24px;
}

[dir="ltr"] .sendbird-add-channel__rectangle .sendbird-icon {
  left: 22px;
}

[dir="rtl"] .sendbird-add-channel__rectangle .sendbird-icon {
  right: 22px;
}
.sendbird-create-channel--content {
  width: 480px;
  max-height: 552px;
}

.sendbird-create-channel--scroll {
  height: 360px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sendbird-channel-settings {
  height: 100%;
  width: 320px;
  display: inline-block;
  box-sizing: border-box;
}

.sendbird--mobile-mode .sendbird-channel-settings {
  width: 100%;
}

.sendbird-theme--light .sendbird-channel-settings {
  background-color: var(--sendbird-light-background-50);
}

[dir="ltr"] .sendbird-theme--light .sendbird-channel-settings {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-channel-settings {
  border-right: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-channel-settings {
  background-color: var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-channel-settings {
  border-left: 1px solid var(--sendbird-dark-background-600);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-channel-settings {
  border-right: 1px solid var(--sendbird-dark-background-600);
}

.sendbird-channel-settings .sendbird-channel-settings__header-icon {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__header-icon .sendbird-channel-settings__close-icon path {
  fill: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__header-icon .sendbird-channel-settings__close-icon path {
  fill: var(--sendbird-dark-ondark-01);
}

.sendbird-channel-settings .sendbird-channel-settings__members .sendbird-badge__text {
  margin-top: -2px;
}

.sendbird-channel-settings .sendbird-channel-settings__header {
  height: 64px;
  min-height: 64px;
  position: relative;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-channel-settings .sendbird-channel-settings__header {
  padding-left: 24px;
  padding-right: 20px;
}

[dir="rtl"] .sendbird-channel-settings .sendbird-channel-settings__header {
  padding-right: 24px;
  padding-left: 20px;
}

.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__header {
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__header {
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird-channel-settings .sendbird-channel-settings__scroll-area {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: calc(100% - 64px);
  overflow-y: auto;
}

.sendbird-channel-settings .sendbird-channel-settings__panel-item {
  cursor: pointer;
  position: relative;
}

[dir="ltr"] .sendbird-channel-settings .sendbird-channel-settings__panel-item {
  padding: 12px 22px 10px 56px;
}

[dir="rtl"] .sendbird-channel-settings .sendbird-channel-settings__panel-item {
  padding: 12px 56px 10px 22px;
}

.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__panel-item {
  border-top: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__panel-item {
  border-top: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-channel-settings .sendbird-channel-settings__panel-item:focus {
  outline: none;
}

.sendbird-channel-settings .sendbird-channel-settings__panel-item.sendbird-channel-settings__panel-item__disabled {
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__panel-item__leave-channel {
  border-top: 1px solid var(--sendbird-light-onlight-04);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__panel-item__leave-channel {
  border-top: 1px solid var(--sendbird-dark-ondark-04);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-channel-settings .sendbird-channel-settings__panel-icon-left,
.sendbird-channel-settings .sendbird-channel-settings__panel-icon-right {
  position: absolute;
  top: 10px;
}

[dir="ltr"] .sendbird-channel-settings .sendbird-channel-settings__panel-icon-left {
  left: 16px;
}

[dir="rtl"] .sendbird-channel-settings .sendbird-channel-settings__panel-icon-left {
  right: 16px;

  &.sendbird-channel-settings__panel-icon__leave {
    transform: rotate(180deg);
  }
}

[dir="ltr"] .sendbird-channel-settings .sendbird-channel-settings__panel-icon-right {
  right: 16px;
}

[dir="rtl"] .sendbird-channel-settings .sendbird-channel-settings__panel-icon-right {
  left: 16px;
}

[dir="ltr"] .sendbird-channel-settings .sendbird-channel-settings__panel-icon--open {
  transform: rotate(90deg);
}

[dir="rtl"] .sendbird-channel-settings .sendbird-channel-settings__panel-icon--open {
  transform: rotate(-90deg);
}

.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__panel-icon--chevron path {
  fill: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__panel-icon--chevron path {
  fill: var(--sendbird-dark-ondark-01);
}
.sendbird-input-toggle-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.sendbird-theme--light .sendbird-input-toggle-button--checked {
  background-color: var(--sendbird-light-primary-300);
  border: 1px solid var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-input-toggle-button--checked {
  background-color: var(--sendbird-dark-primary-200);
  border: 1px solid var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-input-toggle-button--unchecked {
  background-color: var(--sendbird-light-background-300);
  border: 1px solid var(--sendbird-light-background-300);
}

.sendbird-theme--dark .sendbird-input-toggle-button--unchecked {
  background-color: var(--sendbird-dark-background-400);
  border: 1px solid var(--sendbird-dark-background-400);
}

.sendbird-input-toggle-button--disabled {
  cursor: not-allowed;
}

.sendbird-theme--light .sendbird-input-toggle-button--disabled {
  background-color: var(--sendbird-light-background-200);
  border: 1px solid var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-input-toggle-button--disabled {
  background-color: var(--sendbird-dark-background-400);
  border: 1px solid var(--sendbird-dark-background-400);
}

.sendbird-input-toggle-button input {
  position: absolute;
  display: none;
}

.sendbird-input-toggle-button__inner-dot {
  position: absolute;
  border-radius: 50%;
  animation-iteration-count: 1;
}

.sendbird-theme--light .sendbird-input-toggle-button__inner-dot {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-input-toggle-button__inner-dot {
  background-color: var(--sendbird-dark-background-600);
}

/* Manage animation and position by status */
@keyframes sendbirdMoveToEnd {
  0% {
    inset-inline-end: 60%;
  }

  100% {
    inset-inline-end: 10%;
  }
}

@keyframes sendbirdMoveToStart {
  0% {
    inset-inline-end: 10%;
  }

  100% {
    inset-inline-end: 60%;
  }
}

.sendbird-input-toggle-button--turned-on .sendbird-input-toggle-button__inner-dot {
  animation-name: sendbirdMoveToEnd;
}

.sendbird-input-toggle-button--turned-off .sendbird-input-toggle-button__inner-dot {
  animation-name: sendbirdMoveToStart;
}

.sendbird-input-toggle-button--unchecked .sendbird-input-toggle-button__inner-dot {
  inset-inline-end: 60%;
}

.sendbird-input-toggle-button--checked .sendbird-input-toggle-button__inner-dot {
  inset-inline-end: 10%;
}

.sendbird-input-toggle-button--reversed .sendbird-input-toggle-button--turned-on .sendbird-input-toggle-button__inner-dot {
  animation-name: sendbirdMoveToStart;
}

.sendbird-input-toggle-button--reversed .sendbird-input-toggle-button--turned-off .sendbird-input-toggle-button__inner-dot {
  animation-name: sendbirdMoveToEnd;
}

.sendbird-input-toggle-button--reversed.sendbird-input-toggle-button--unchecked .sendbird-input-toggle-button__inner-dot {
  inset-inline-end: 10%;
}

.sendbird-input-toggle-button--reversed.sendbird-input-toggle-button--checked .sendbird-input-toggle-button__inner-dot {
  inset-inline-end: 60%;
}
.sendbird-channel-profile {
  position: relative;
  text-align: center;
  padding: 32px 40px 27px 40px;
}

.sendbird-theme--light .sendbird-channel-profile {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-channel-profile {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-channel-profile .sendbird-channel-profile--inner {
  height: 92px;
}

.sendbird-channel-profile .sendbird-channel-profile__avatar {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.sendbird-channel-profile .sendbird-channel-profile__title {
  display: block;
  max-width: 240px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 18px;
}

.sendbird--mobile-mode .sendbird-channel-profile .sendbird-channel-profile__title {
  max-width: 100%;
}

.sendbird-channel-profile .sendbird-channel-profile__edit {
  position: absolute;
  top: 10px;
}

[dir="ltr"] .sendbird-channel-profile .sendbird-channel-profile__edit {
  right: 16px;
}

[dir="rtl"] .sendbird-channel-profile .sendbird-channel-profile__edit {
  left: 16px;
}

.channel-profile-form .channel-profile-form__img-section {
  position: relative;
}

.channel-profile-form .channel-profile-form__avatar-button {
  position: absolute;
  top: 54px;
}

[dir="ltr"] .channel-profile-form .channel-profile-form__avatar-button {
  left: 96px;
}

[dir="rtl"] .channel-profile-form .channel-profile-form__avatar-button {
  right: 96px;
}

.channel-profile-form .channel-profile-form__avatar {
  margin-top: 16px;
}

.channel-profile-form .channel-profile-form__name-section {
  margin-top: 12px;
}

.channel-profile-form .channel-profile-form__name-section .sendbird-input {
  height: 40px;
}
.sendbird-channel-settings__leave-label--mobile {
  text-decoration: none;
}

.sendbird-channel-settings__leave--mobile .sendbird-modal__content {
  max-width: calc(100% - 80px);
}

.sendbird-channel-settings__leave--mobile .sendbird-modal__close {
  display: none;
}

.sendbird-channel-settings__leave--mobile .sendbird-channel-preview__leave-label--mobile {
  text-decoration: none;
}

.sendbird-channel-settings__leave--mobile .sendbird-modal__header span {
  display: inline-block;
  width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sendbird-channel-settings__operator .sendbird-channel-settings__accordion-icon {
  position: absolute;
}

[dir="ltr"] .sendbird-channel-settings__operator .sendbird-channel-settings__accordion-icon {
  left: 14px;
}

[dir="rtl"] .sendbird-channel-settings__operator .sendbird-channel-settings__accordion-icon {
  right: 14px;
}

.sendbird-channel-settings__operator .sendbird-accordion__panel-heder {
  position: relative;
}

.sendbird-more-members__popup-scroll {
  max-height: 420px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sendbird--mobile-mode .sendbird-more-members__popup-scroll {
  max-height: 100%;
}

[dir="ltr"] .sendbird-more-members__popup-scroll .sendbird-user-list-item__operator {
  right: 72px;
}

[dir="rtl"] .sendbird-more-members__popup-scroll .sendbird-user-list-item__operator {
  left: 72px;
}

.sendbird-more-members__popup-scroll .sendbird-user-list-item__title {
  max-width: 240px;
}

.sendbird-channel-settings__empty-list {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 4px;
  display: block;
}

.sendbird-channel-settings-accordion__footer {
  padding: 16px 12px;
  display: flex;
}

[dir="ltr"] .sendbird-channel-settings-accordion__footer {
  justify-content: left;
}

[dir="rtl"] .sendbird-channel-settings-accordion__footer {
  justify-content: right;
}

[dir="ltr"] .sendbird-channel-settings-accordion__footer .sendbird-button:nth-child(2) {
  margin-left: 8px;
}

[dir="rtl"] .sendbird-channel-settings-accordion__footer .sendbird-button:nth-child(2) {
  margin-right: 8px;
}

.sendbird-channel-settings__freeze {
  max-width: 320px;
  position: relative;
}

[dir="ltr"] .sendbird-channel-settings__freeze {
  padding: 12px 22px 10px 56px;
}

[dir="rtl"] .sendbird-channel-settings__freeze {
  padding: 12px 56px 10px 22px;
}

.sendbird--mobile-mode .sendbird-channel-settings__freeze {
  max-width: 100%;
}

.sendbird-theme--light .sendbird-channel-settings__freeze {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-channel-settings__freeze {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-channel-settings__frozen-icon {
  position: absolute;
  top: 12px;
}

[dir="ltr"] .sendbird-channel-settings__frozen-icon {
  right: 20px;
}

[dir="rtl"] .sendbird-channel-settings__frozen-icon {
  left: 20px;
}

.sendbird-channel-settings__frozen-icon .sendbird-icon {
  cursor: pointer;
}

.sendbird-channel-settings__members-list .sendbird-badge,
.sendbird-channel-settings__members .sendbird-badge {
  position: absolute;
  top: 12px;
}

[dir="ltr"] .sendbird-channel-settings__members-list .sendbird-badge,
[dir="ltr"] .sendbird-channel-settings__members .sendbird-badge {
  right: 44px;
}

[dir="rtl"] .sendbird-channel-settings__members-list .sendbird-badge,
[dir="rtl"] .sendbird-channel-settings__members .sendbird-badge {
  left: 44px;
}

.sendbird-theme--light .sendbird-channel-settings__members-list .sendbird-badge,
.sendbird-theme--light .sendbird-channel-settings__members .sendbird-badge {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-channel-settings__members-list .sendbird-badge,
.sendbird-theme--dark .sendbird-channel-settings__members .sendbird-badge {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-channel-settings__members-list .sendbird-badge .sendbird-label,
.sendbird-theme--light .sendbird-channel-settings__members .sendbird-badge .sendbird-label {
  color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-channel-settings__members-list .sendbird-badge .sendbird-label,
.sendbird-theme--dark .sendbird-channel-settings__members .sendbird-badge .sendbird-label {
  color: var(--sendbird-dark-ondark-02);
}
.sendbird-more-members__popup-scroll {
  max-height: 420px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sendbird--mobile-mode .sendbird-more-members__popup-scroll {
  max-height: 100%;
}

[dir="ltr"] .sendbird-more-members__popup-scroll .sendbird-user-list-item__operator {
  right: 72px;
}

[dir="rtl"] .sendbird-more-members__popup-scroll .sendbird-user-list-item__operator {
  left: 72px;
}

.sendbird-more-members__popup-scroll .sendbird-user-list-item__title {
  max-width: 240px;
}

.sendbird-channel-settings__badge {
  position: absolute;
}

[dir="ltr"] .sendbird-channel-settings__badge {
  right: 48px;
}

[dir="rtl"] .sendbird-channel-settings__badge {
  left: 48px;
}

.sendbird-theme--light .sendbird-channel-settings__badge {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-channel-settings__badge {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-channel-settings__badge .sendbird-label {
  color: var(--sendbird-light-onlight-02);
}

.sendbird-theme--dark .sendbird-channel-settings__badge .sendbird-label {
  color: var(--sendbird-dark-ondark-02);
}

.sendbird-channel-settings__user-panel .sendbird-channel-settings-accordion__footer {
  padding-top: 14px;
  padding-bottom: 14px;
}

[dir="ltr"] .sendbird-channel-settings__user-panel .sendbird-channel-settings-accordion__footer {
  padding-left: 14px;
}

[dir="rtl"] .sendbird-channel-settings__user-panel .sendbird-channel-settings-accordion__footer {
  padding-right: 14px;
}

.sendbird-theme--light .sendbird-channel-settings__user-panel .sendbird-channel-settings-accordion__footer {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-channel-settings__user-panel .sendbird-channel-settings-accordion__footer {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-message-search-pannel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100%;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-message-search-pannel {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message-search-pannel {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird--mobile-mode .sendbird-message-search-pannel {
  width: 100%;
}

.sendbird-message-search-pannel .sendbird-message-search-pannel__input__container__input-area {
  position: absolute;
  top: 14px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
  font-size: 14px;
  width: calc(100% - 90px);
}

[dir="ltr"] .sendbird-message-search-pannel .sendbird-message-search-pannel__input__container__input-area {
  left: 48px;
}

[dir="rtl"] .sendbird-message-search-pannel .sendbird-message-search-pannel__input__container__input-area {
  right: 48px;
}

.sendbird-theme--light .sendbird-message-search-pannel .sendbird-message-search-pannel__input__container__input-area {
  color: var(--sendbird-light-onlight-01);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message-search-pannel .sendbird-message-search-pannel__input__container__input-area {
  color: var(--sendbird-dark-ondark-01);
  background-color: var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-message-search-pannel .sendbird-message-search-pannel__header .sendbird-ui-header__middle__title {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-message-search-pannel .sendbird-message-search-pannel__header .sendbird-ui-header__middle__title {
  margin-right: 4px;
}

.sendbird-message-search-pannel .sendbird-message-search-pannel__input {
  display: flex;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
}

.sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container {
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 44px;
}

.sendbird-theme--light .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container {
  border: 1px solid var(--sendbird-light-onlight-03);
}

.sendbird-theme--dark .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container {
  border: 1px solid var(--sendbird-dark-ondark-03);
}

.sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__search-icon {
  position: absolute;
  top: 10px;
}

[dir="ltr"] .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__search-icon {
  left: 16px;
}

[dir="rtl"] .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__search-icon {
  right: 16px;
}

.sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__spinner {
  position: absolute;
  top: 12px;
}

[dir="ltr"] .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__spinner {
  right: 12px;
}

[dir="rtl"] .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__spinner {
  left: 12px;
}

.sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__reset-input-button {
  position: absolute;
  top: 12px;
}

[dir="ltr"] .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__reset-input-button {
  right: 12px;
}

[dir="rtl"] .sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__reset-input-button {
  left: 12px;
}

.sendbird-message-search-pannel .sendbird-message-search-pannel__input .sendbird-message-search-pannel__input__container .sendbird-message-search-pannel__input__container__reset-input-button:hover {
  cursor: pointer;
}

.sendbird-message-search-pannel .sendbird-message-search-pannel__message-search {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.sendbird-message-search {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sendbird-theme--light .sendbird-message-search {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message-search {
  background-color: var(--sendbird-dark-background-600);
}
.sendbird-message-search-item {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  position: relative;
  cursor: pointer;
}

.sendbird-theme--light .sendbird-message-search-item {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message-search-item {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-theme--light .sendbird-message-search-item:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-message-search-item:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-message-search-item .sendbird-message-search-item__left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 80px;
}

.sendbird-message-search-item .sendbird-message-search-item__right {
  display: flex;
  flex-direction: column;
  width: calc(100% - 106px);
}

.sendbird-message-search-item .sendbird-message-search-item__right .sendbird-message-search-item__right__sender-name {
  position: absolute;
  top: 12px;
  display: inline;
  max-width: 150px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.sendbird-message-search-item .sendbird-message-search-item__right .sendbird-message-search-item__right__message-text {
  box-sizing: border-box;
  display: flex;
  width: inherit;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 36px;
  word-break: break-word;
}

.sendbird-message-search-item .sendbird-message-search-item__right .sendbird-message-search-item__right__message-created-at {
  position: absolute;
  top: 12px;
}

[dir="ltr"] .sendbird-message-search-item .sendbird-message-search-item__right .sendbird-message-search-item__right__message-created-at {
  right: 18px;
}

[dir="rtl"] .sendbird-message-search-item .sendbird-message-search-item__right .sendbird-message-search-item__right__message-created-at {
  left: 18px;
}

.sendbird-message-search-item .sendbird-message-search-item__right-footer {
  width: 16px;
}

.sendbird-theme--light .sendbird-message-search-item.sendbird-message-search-item--selected {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-message-search-item.sendbird-message-search-item--selected {
  background-color: var(--sendbird-dark-background-700);
}

.sendbird-theme--light .sendbird-message-search-item.sendbird-message-search-item--selected .sendbird-message-search-item__left {
  border-inline-start: 4px solid var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-message-search-item.sendbird-message-search-item--selected .sendbird-message-search-item__left {
  border-inline-start: 4px solid var(--sendbird-dark-primary-200);
}
.sendbird-message-search-file-item {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  cursor: pointer;
}

.sendbird-theme--light .sendbird-message-search-file-item {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-message-search-file-item {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-theme--light .sendbird-message-search-file-item:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-message-search-file-item:hover {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-message-search-file-item .sendbird-message-search-file-item__left {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 80px;
}

.sendbird-message-search-file-item .sendbird-message-search-file-item__right {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 106px);
}

.sendbird-message-search-file-item .sendbird-message-search-file-item__right .sendbird-message-search-file-item__right__sender-name {
  position: absolute;
  top: 12px;
  display: inline-block;
  max-width: 146px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-message-search-file-item .sendbird-message-search-file-item__right .sendbird-message-search-file-item__right__content {
  position: absolute;
  top: 36px;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sendbird-message-search-file-item .sendbird-message-search-file-item__right .sendbird-message-search-file-item__right__content .sendbird-message-search-file-item__right__content__type-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 8px;
}

[dir="ltr"] .sendbird-message-search-file-item .sendbird-message-search-file-item__right .sendbird-message-search-file-item__right__content .sendbird-message-search-file-item__right__content__type-icon {
  margin-right: 4px;
}

[dir="rtl"] .sendbird-message-search-file-item .sendbird-message-search-file-item__right .sendbird-message-search-file-item__right__content .sendbird-message-search-file-item__right__content__type-icon {
  margin-left: 4px;
}

.sendbird-theme--light .sendbird-message-search-file-item .sendbird-message-search-file-item__right .sendbird-message-search-file-item__right__content .sendbird-message-search-file-item__right__content__type-icon {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-message-search-file-item .sendbird-message-search-file-item__right .sendbird-message-search-file-item__right__content .sendbird-message-search-file-item__right__content__type-icon {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-message-search-file-item .sendbird-message-search-file-item__right .sendbird-message-search-file-item__right__content .sendbird-message-search-file-item__right__content__url {
  height: 16px;
  position: relative;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
}

.sendbird-message-search-file-item .sendbird-message-search-file-item__message-created-at {
  position: absolute;
  top: 12px;
}

[dir="ltr"] .sendbird-message-search-file-item .sendbird-message-search-file-item__message-created-at {
  right: 18px;
}

[dir="rtl"] .sendbird-message-search-file-item .sendbird-message-search-file-item__message-created-at {
  left: 18px;
}

.sendbird-message-search-file-item .sendbird-message-search-file-item__right-footer {
  width: 16px;
}

.sendbird-theme--light .sendbird-message-search-file-item.sendbird-message-search-file-item--selected {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-message-search-file-item.sendbird-message-search-file-item--selected {
  background-color: var(--sendbird-dark-background-700);
}

[dir="ltr"] .sendbird-theme--light .sendbird-message-search-file-item.sendbird-message-search-file-item--selected .sendbird-message-search-file-item__left {
  border-left: 4px solid var(--sendbird-light-primary-300);
}

[dir="rtl"] .sendbird-theme--light .sendbird-message-search-file-item.sendbird-message-search-file-item--selected .sendbird-message-search-file-item__left {
  border-right: 4px solid var(--sendbird-light-primary-300);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-message-search-file-item.sendbird-message-search-file-item--selected .sendbird-message-search-file-item__left {
  border-left: 4px solid var(--sendbird-dark-primary-200);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-message-search-file-item.sendbird-message-search-file-item--selected .sendbird-message-search-file-item__left {
  border-right: 4px solid var(--sendbird-dark-primary-200);
}
.sendbird-thread-ui {
  position: relative;
  max-width: 320px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.sendbird-theme--light .sendbird-thread-ui {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

[dir="ltr"] .sendbird-theme--light .sendbird-thread-ui {
  border-right: 1px solid var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-thread-ui {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-thread-ui {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-thread-ui {
  border-right: 1px solid var(--sendbird-dark-ondark-04);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-thread-ui {
  border-left: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-thread-ui__header {
  width: 320px;
}

.sendbird-theme--light .sendbird-thread-ui__header {
  border-top: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-thread-ui__header {
  border-top: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-thread-ui--scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sendbird-theme--light .sendbird-thread-ui__parent-message-info {
  border-top: 1px solid var(--sendbird-light-onlight-04);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-thread-ui__parent-message-info {
  border-top: 1px solid var(--sendbird-dark-ondark-04);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-thread-ui__parent-message-info .sendbird-word__mention .sendbird-label {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-thread-ui__parent-message-info .sendbird-word__mention .sendbird-label {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-thread-ui__reply-counts {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 11px;
}

.sendbird-theme--light .sendbird-thread-ui__reply-counts {
  border-top: 1px solid var(--sendbird-light-onlight-04);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-thread-ui__reply-counts {
  border-top: 1px solid var(--sendbird-dark-ondark-04);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-thread-ui__thread-list {
  padding: 16px;
}

.sendbird-thread-ui__message-input {
  padding: 0px 16px;
  margin-bottom: 24px;
}

.sendbird-thread-ui__thread-list.sendbird-place-holder {
  box-sizing: border-box;
}
.sendbird-parent-message-info {
  position: relative;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info {
  padding: 12px 12px 12px 16px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info {
  padding: 12px 16px 12px 12px;
}

.sendbird-parent-message-info__sender {
  position: relative;
  min-width: 40px;
  min-height: 40px;
}

.sendbird-parent-message-info__content {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info__content {
  margin-left: 12px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info__content {
  margin-right: 12px;
}

.sendbird-parent-message-info__content__info {
  position: relative;
  max-width: 188px;
  height: 16px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sendbird-parent-message-info__content__info__sender-name,
.sendbird-parent-message-info__content__info__sender-name--use-reaction {
  position: relative;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info__content__info__sender-name,
.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info__content__info__sender-name--use-reaction {
  margin-right: 6px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info__content__info__sender-name,
.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info__content__info__sender-name--use-reaction {
  margin-left: 6px;
}

.sendbird-parent-message-info__content__info__sender-name {
  max-width: 142px;
}

.sendbird-parent-message-info__content__info__sender-name--use-reaction {
  max-width: 110px;
}

.sendbird-parent-message-info__content__info__sent-at {
  position: relative;
  max-width: 52px;
  height: 12px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  word-break: keep-all;
}

.sendbird-parent-message-info__content__body {
  position: relative;
  max-width: 210px;
  overflow: hidden;
}

.sendbird-parent-message-info__content__body.sendbird-thumbnail-message-item-body.incoming {
  min-width: 200px;
  height: 148px;
}

.sendbird-parent-message-info__content__reactions {
  position: relative;
  max-width: 240px;
  width: 100%;
  height: 100%;
}

.sendbird-parent-message-info__reaction-menu,
.sendbird-parent-message-info__context-menu {
  position: relative;
  display: none;
}

.sendbird-parent-message-info .sendbird-text-message-item-body.reactions {
  border-radius: 16px;
}

.sendbird-theme--light .sendbird-parent-message-info .sendbird-emoji-reactions {
  border: 1px solid var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-parent-message-info .sendbird-emoji-reactions {
  border: 1px solid var(--sendbird-dark-background-600);
}

.sendbird-parent-message-info__menu-container,
.sendbird-parent-message-info:hover .sendbird-parent-message-info__context-menu,
.sendbird-parent-message-info:hover .sendbird-parent-message-info__reaction-menu,
.sendbird-parent-message-info__context-menu.sendbird-mouse-hover,
.sendbird-parent-message-info__reaction-menu.sendbird-mouse-hover {
  display: inline-flex;
}

.sendbird-parent-message-info__menu-container {
  flex-direction: row;
  position: absolute;
  top: 6px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info__menu-container {
  right: 12px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info__menu-container {
  left: 12px;
}

.sendbird-theme--light .sendbird-parent-message-info .sendbird-parent-message-info__content__body {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-parent-message-info .sendbird-parent-message-info__content__body {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-theme--light .sendbird-parent-message-info:hover,
.sendbird-theme--light .sendbird-parent-message-info:hover .sendbird-parent-message-info__content__body {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-parent-message-info:hover,
.sendbird-theme--dark .sendbird-parent-message-info:hover .sendbird-parent-message-info__content__body {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird--mobile-mode .sendbird-parent-message-info.sendbird-thread-ui__parent-message-info:hover {
  background-color: transparent;
}

.sendbird-theme--light .sendbird-parent-message-info:hover .sendbird-emoji-reactions {
  border: 1px solid var(--sendbird-light-background-100);
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-parent-message-info:hover .sendbird-emoji-reactions {
  border: 1px solid var(--sendbird-dark-background-500);
  background-color: var(--sendbird-dark-background-500);
}

.parent-message-info--suggested-mention-list {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 200px;
}

.parent-message-info--suggested-mention-list .sendbird-mention-suggest-list__user-item {
  padding-left: 16px;
  padding-right: 16px;
}

.parent-message-info--suggested-mention-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__nickname {
  max-width: 166px;
}

.parent-message-info--suggested-mention-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__user-id {
  max-width: 68px;
}
.sendbird-parent-message-info-item {
  margin-top: 8px;
  position: relative;
}

.sendbird-parent-message-info-item__text-message,
.sendbird-parent-message-info-item__og-field {
  display: inline-block;
  white-space: pre-line;
  word-break: break-all;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info-item__text-message,
.sendbird-conversation__messages[dir="ltr"] .sendbird-parent-message-info-item__og-field {
  padding-right: 4px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info-item__text-message,
.sendbird-conversation__messages[dir="rtl"] .sendbird-parent-message-info-item__og-field {
  padding-left: 4px;
}

.sendbird-parent-message-info-item__og-field {
  display: inline-flex;
  flex-direction: column;
  margin-top: 4px;
}

.sendbird-parent-message-info-item__og-field__content {
  display: inline-flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  padding: 8px 12px;
}

.sendbird-parent-message-info-item__file-message {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
}

.sendbird-parent-message-info-item__file-message__file-name {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sendbird-parent-message-info-item__thumbnail-message {
  display: block;
  height: 148px;
  position: relative;
  width: 200px;
}

.sendbird-parent-message-info-item__multiple-files-message-wrapper {
  padding: 0;
}

.sendbird-theme--light .sendbird-parent-message-info-item__multiple-files-message-wrapper {
  background-color: transparent;
}

.sendbird-theme--dark .sendbird-parent-message-info-item__multiple-files-message-wrapper {
  background-color: transparent;
}

.sendbird-parent-message-info-item__thumbnail-message__thumbnail {
  border-radius: 16px;
  position: absolute;
}

.sendbird-parent-message-info-item__thumbnail-message__placeholder {
  align-items: center;
  display: flex;
  height: 148px;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.sendbird-parent-message-info-item__thumbnail-message__placeholder__icon {
  align-items: center;
  background-color: var(--sendbird-light-background-50);
  border-radius: 50%;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
}

.sendbird-parent-message-info-item__thumbnail-message__image-cover {
  background-color: var(--sendbird-light-overlay-01);
  border-radius: 16px;
  display: none;
  height: 148px;
  position: absolute;
  top: 0;
  width: 100%;
}

.sendbird-parent-message-info-item__thumbnail-message__video {
  border-radius: 16px;
  height: 148px;
  position: absolute;
  width: 100%;
}

.sendbird-parent-message-info-item__thumbnail-message__icon-wrapper {
  align-items: center;
  display: flex;
  height: 148px;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.sendbird-parent-message-info-item__thumbnail-message__icon-wrapper__icon {
  align-items: center;
  background-color: var(--sendbird-light-ondark-01);
  border-radius: 50%;
  display: inline-flex;
  height: 56px;
  justify-content: center;
  width: 56px;
}

.sendbird-parent-message-info-item__thumbnail-message:hover {
  cursor: pointer;
}

.sendbird-parent-message-info-item__thumbnail-message:hover .sendbird-parent-message-info-item__thumbnail-message__image-cover {
  display: inline-flex;
}

.sendbird-parent-message-info-item__voice-message__item {
  height: 50px;
  min-width: 136px;
}
.sendbird-thread-list-item .sendbird-separator {
  margin: 4px 0px;
}

.sendbird-thread-list .sendbird-message-content__middle__sender-name {
  white-space: nowrap;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-thread-list .sendbird-message-content.incoming .sendbird-message-content__middle {
  max-width: 200px;
}

.sendbird-thread-list .sendbird-thumbnail-message-item-body.outgoing {
  min-width: 200px;
  min-height: 148px;
  height: 148px;
}

.sendbird-thread-list .sendbird-message-content .sendbird-message-content__middle {
  max-width: 230px;
}

.sendbird-thread-list .sendbird-message-status__icon.sendbird-message-status--sent {
  display: none;
}

.sendbird-thread-list .sendbird-mention-suggest-list {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.sendbird-thread-list .sendbird-mention-suggest-list__user-item {
  padding-left: 16px;
  padding-right: 16px;
}

.sendbird-thread-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__nickname {
  max-width: 134px;
}

.sendbird-thread-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__user-id {
  max-width: 46px;
}
.sendbird-thread-list-item-content {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.sendbird-thread-list-item-content.incoming {
  justify-content: flex-start;
}

.sendbird-thread-list-item-content.outgoing {
  justify-content: flex-end;
}

.sendbird-thread-list-item-content .sendbird-thread-list-item-content__middle {
  max-width: 200px;
}

.sendbird--mobile-mode .sendbird-thread-list-item-content .sendbird-thread-list-item-content__middle {
  max-width: calc(100vw - 100px);
}

.sendbird-thread-list-item-content .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__quote-message.use-quote {
  margin-top: -8px;
  bottom: -8px;
}

.sendbird-thread-list-item-content__middle__body-container {
  width: 100%;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__left {
  position: relative;
  display: inline-flex;
  min-width: 40px;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__left .sendbird-thread-list-item-content__left__avatar {
  position: absolute;
  bottom: 2px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__left .sendbird-thread-list-item-content__left__avatar {
  left: 0px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__left .sendbird-thread-list-item-content__left__avatar {
  right: 0px;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at {
  position: absolute;
  bottom: 6px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  min-width: 80px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at {
  right: -84px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at {
  left: -84px;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at.sendbird-mouse-hover {
  display: none;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__sender-name {
  position: relative;
  margin-bottom: 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__sender-name {
  margin-left: 12px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__sender-name {
  margin-right: 12px;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__quote-message {
  position: relative;
  width: 100%;
  display: inline-flex;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__quote-message.outgoing {
  justify-content: flex-end;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__quote-message.incoming {
  justify-content: flex-start;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__quote-message:hover {
  cursor: pointer;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__right {
  position: relative;
  display: inline-flex;
  width: 50px;
  padding-top: 18px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__right {
  margin-left: 4px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__right {
  margin-right: 4px;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__right.use-reactions {
  width: 70px;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__right.chain-top {
  padding-top: 2px;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__right.chain-top.use-quote {
  padding-top: 18px;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__right .sendbird-thread-list-item-content-menu {
  position: relative;
  flex-direction: row;
  height: 32px;
  display: none;
}

.sendbird-thread-list-item-content.incoming .sendbird-thread-list-item-content__right .sendbird-thread-list-item-content-menu.sendbird-mouse-hover {
  display: inline-flex;
}

.sendbird-thread-list-item-content.incoming:hover .sendbird-thread-list-item-content__right .sendbird-thread-list-item-content-menu {
  display: inline-flex;
}

.sendbird-thread-list-item-content.incoming:hover .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at {
  display: none;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__left {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-end;
  width: 50px;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__left.use-reactions {
  width: 70px;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__left.use-quote .sendbird-thread-list-item-content-menu {
  top: 18px;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__left .sendbird-thread-list-item-content-menu {
  position: absolute;
  top: 2px;
  flex-direction: row;
  display: none;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__left .sendbird-thread-list-item-content-menu {
  right: 4px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__left .sendbird-thread-list-item-content-menu {
  left: 4px;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__left .sendbird-thread-list-item-content-menu.sendbird-mouse-hover {
  display: inline-flex;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__quote-message {
  position: relative;
  width: 100%;
  display: inline-flex;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__quote-message.outgoing {
  justify-content: flex-end;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__quote-message.incoming {
  justify-content: flex-start;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container {
  position: relative;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at {
  position: absolute;
  bottom: 2px;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  box-sizing: content-box;
  min-width: 80px;
  min-height: 16px;
}

.sendbird-conversation__messages[dir="ltr"] .sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at {
  left: -84px;
}

.sendbird-conversation__messages[dir="rtl"] .sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at {
  right: -84px;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at.sendbird-mouse-hover {
  display: none;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at .sendbird-thread-list-item-content__middle__body-container__created-at__component-container {
  position: relative;
  display: inline-flex;
}

.sendbird-thread-list-item-content.outgoing .sendbird-thread-list-item-content__right {
  display: none;
}

.sendbird-thread-list-item-content.outgoing:hover .sendbird-thread-list-item-content__left .sendbird-thread-list-item-content-menu {
  display: inline-flex;
}

.sendbird-thread-list-item-content.outgoing:hover .sendbird-thread-list-item-content__middle .sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__body-container__created-at {
  display: none;
}

.sendbird-thread-list-item-content__middle__body-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.sendbird-thread-list-item-content__middle__body-container .sendbird-thread-list-item-content__middle__message-item-body {
  width: 100%;
  box-sizing: border-box;
}

.sendbird-thread-list-item-content-reactions {
  position: relative;
  width: 100%;
  max-width: 400px;
  border-radius: 0px 0px 16px 16px;
}

.sendbird-theme--light .sendbird-thread-list-item-content-reactions {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-thread-list-item-content-reactions {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-thread-list-item-content-reactions.primary {
  background-color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-thread-list-item-content-reactions.primary {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-thread-list-item-content-reactions.mouse-hover, .sendbird-theme--light .sendbird-thread-list-item-content-reactions:hover {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-thread-list-item-content-reactions.mouse-hover, .sendbird-theme--dark .sendbird-thread-list-item-content-reactions:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-theme--light .sendbird-thread-list-item-content-reactions.mouse-hover.primary, .sendbird-theme--light .sendbird-thread-list-item-content-reactions:hover.primary {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-thread-list-item-content-reactions.mouse-hover.primary, .sendbird-theme--dark .sendbird-thread-list-item-content-reactions:hover.primary {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-thread-list-item-content__middle__thread-replies {
  margin-top: 4px;
}

.sendbird-thread-list-item-content__middle__message-item-body.sendbird-og-message-item-body,
.sendbird-thread-list-item-content__middle__message-item-body.sendbird-thumbnail-message-item-body {
  min-width: 200px;
  max-width: 200px;
}

.sendbird-thread-list-item-content__middle__message-item-body.sendbird-thumbnail-message-item-body {
  height: 148px;
}

.sendbird-thread-list-item-content__middle__message-item-body .sendbird-thumbnail-message-item-body__placeholder,
.sendbird-thread-list-item-content__middle__message-item-body .sendbird-thumbnail-message-item-body__icon-wrapper,
.sendbird-thread-list-item-content__middle__message-item-body .sendbird-thumbnail-message-item-body__video {
  height: 148px;
}

.sendbird-voice-message-item-body.sendbird-thread-list-item-content__middle__message-item-body {
  min-width: 136px;
  height: 50px;
}
.sendbird-thread-message-input .sendbird-mention-suggest-list {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.sendbird-thread-message-input .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item {
  padding-left: 16px;
  padding-right: 16px;
}

.sendbird-thread-message-input .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__nickname {
  max-width: 134px;
}

.sendbird-thread-message-input .sendbird-mention-suggest-list .sendbird-mention-suggest-list__user-item .sendbird-mention-suggest-list__user-item__user-id {
  max-width: 46px;
}

.sendbird-theme--light .sendbird-thread-message-input--voice-message {
  border-top: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-thread-message-input--voice-message {
  border-top: 1px solid var(--sendbird-dark-ondark-04);
}
.sb_mobile {
  height: 100%;
}

.sb_mobile__panelwrap {
  height: 100%;
}

.sb-show-main {
  padding: 0 !important;
}

.sb_mobile__panelwrap .sendbird-thread {
  width: 100%;
  height: 100%;
}

.sb_mobile__panelwrap .sendbird-thread .sendbird-thread-ui {
  max-width: 100%;
}

.sb_mobile__panelwrap .sendbird-thread .sendbird-thread-ui .sendbird-thread-ui__header {
  width: 100%;
}
.sendbird-app__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
}

.sendbird-app__wrap .sendbird-app__searchpanel-wrap {
  width: 320px;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-app__wrap .sendbird-app__searchpanel-wrap {
  border-top: 1px solid var(--sendbird-light-onlight-04);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

[dir="ltr"] .sendbird-theme--light .sendbird-app__wrap .sendbird-app__searchpanel-wrap {
  border-right: 1px solid var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-app__wrap .sendbird-app__searchpanel-wrap {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-app__wrap .sendbird-app__searchpanel-wrap {
  border-top: 1px solid var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-app__wrap .sendbird-app__searchpanel-wrap {
  border-right: 1px solid var(--sendbird-dark-background-600);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-app__wrap .sendbird-app__searchpanel-wrap {
  border-left: 1px solid var(--sendbird-dark-background-600);
}

.sendbird-app__wrap .sendbird-app__conversation-wrap {
  flex: 1 1;
  position: relative;
  width: calc(100% - 320px);
}

.sendbird-app__wrap .sendbird-app__channellist-wrap {
  max-width: 320px;
}

.sendbird-theme--light .sendbird-app__wrap .sendbird-app__channellist-wrap {
  border-top: 1px solid var(--sendbird-light-onlight-04);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

[dir="ltr"] .sendbird-theme--light .sendbird-app__wrap .sendbird-app__channellist-wrap {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-app__wrap .sendbird-app__channellist-wrap {
  border-right: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-app__wrap .sendbird-app__channellist-wrap {
  border-top: 1px solid var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-app__wrap .sendbird-app__channellist-wrap {
  border-left: 1px solid var(--sendbird-dark-background-600);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-app__wrap .sendbird-app__channellist-wrap {
  border-right: 1px solid var(--sendbird-dark-background-600);
}

.sendbird-theme--light .sendbird-app__wrap .sendbird-app__conversation--settings-open .sendbird-chat-header__info {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-app__wrap .sendbird-app__conversation--settings-open .sendbird-chat-header__info {
  background-color: var(--sendbird-dark-background-700);
}

[dir="ltr"] .sendbird-app__wrap .sendbird-chat-header__search {
  margin-right: 16px;
}

[dir="rtl"] .sendbird-app__wrap .sendbird-chat-header__search {
  margin-left: 16px;
}

.sendbird-theme--light .sendbird-app__wrap .sendbird-app__conversation--search-open .sendbird-chat-header__search {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-app__wrap .sendbird-app__conversation--search-open .sendbird-chat-header__search {
  background-color: var(--sendbird-dark-background-700);
}

[dir="ltr"] .sendbird-app__wrap .sendbird-channel-settings {
  border-left: none;
}

[dir="rtl"] .sendbird-app__wrap .sendbird-channel-settings {
  border-right: none;
}

.sendbird-theme--light .sendbird-app__wrap .sendbird-channel-settings {
  border-top: 1px solid var(--sendbird-light-onlight-04);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

[dir="ltr"] .sendbird-theme--light .sendbird-app__wrap .sendbird-channel-settings {
  border-right: 1px solid var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-app__wrap .sendbird-channel-settings {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-app__wrap .sendbird-channel-settings {
  border-top: 1px solid var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-app__wrap .sendbird-channel-settings {
  border-right: 1px solid var(--sendbird-dark-background-600);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-app__wrap .sendbird-channel-settings {
  border-left: 1px solid var(--sendbird-dark-background-600);
}

.sendbird-app__wrap .sendbird-channel-settings .sendbird-channel-settings__header {
  height: 64px;
}
.sendbird-openchannel-conversation {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.sendbird-theme--light .sendbird-openchannel-conversation {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-openchannel-conversation {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-openchannel-conversation .sendbird-openchannel-conversation-scroll {
  overflow-y: auto;
  flex: 1 1;
  width: 100%;
}

.sendbird-openchannel-conversation .sendbird-openchannel-footer {
  padding: 12px 24px 24px 24px;
}

.sendbird-openchannel-conversation .sendbird-openchannel-conversation-scroll-container {
  display: flex;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}

.sendbird-openchannel-conversation .sendbird-openchannel-conversation-scroll-container .sendbird-openchannel-conversation-scroll-padding {
  flex: 1 1;
}
.sendbird-frozen-channel-notification {
  position: relative;
  margin-top: 8px;
  margin-right: 24px;
  margin-left: 24px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.sendbird-theme--light .sendbird-frozen-channel-notification {
  background-color: var(--sendbird-light-information-100);
}

.sendbird-theme--dark .sendbird-frozen-channel-notification {
  background-color: var(--sendbird-dark-information-100);
}
.sendbird-oepnchannel-header__icon_back {
  position: absolute;
  top: 18px;
}

[dir="ltr"] .sendbird-oepnchannel-header__icon_back {
  left: 12px;
}

[dir="rtl"] .sendbird-oepnchannel-header__icon_back {
  right: 12px;
}

.sendbird-openchannel-conversation-header {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-openchannel-conversation-header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-openchannel-conversation-header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-openchannel-conversation-header .sendbird-openchannel-conversation-header__left__title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
}

.sendbird--mobile-mode .sendbird-openchannel-conversation-header .sendbird-openchannel-conversation-header__left__title {
  max-width: calc(100% - 160px);
}

.sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  position: absolute;
  top: 16px;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  left: 20px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  right: 20px;
}

[dir="ltr"] .sendbird--mobile-mode .sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  left: 50px;
}

[dir="rtl"] .sendbird--mobile-mode .sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  right: 50px;
}

.sendbird-openchannel-conversation-header__left__title {
  position: absolute;
  top: 12px;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__left__title {
  left: 60px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__left__title {
  right: 60px;
}

[dir="ltr"] .sendbird--mobile-mode .sendbird-openchannel-conversation-header__left__title {
  left: 90px;
}

[dir="rtl"] .sendbird--mobile-mode .sendbird-openchannel-conversation-header__left__title {
  right: 90px;
}

.sendbird-openchannel-conversation-header__left__sub-title {
  position: absolute;
  top: 36px;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__left__sub-title {
  left: 60px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__left__sub-title {
  right: 60px;
}

[dir="ltr"] .sendbird--mobile-mode .sendbird-openchannel-conversation-header__left__sub-title {
  left: 90px;
}

[dir="rtl"] .sendbird--mobile-mode .sendbird-openchannel-conversation-header__left__sub-title {
  right: 90px;
}

.sendbird-openchannel-conversation-header__right {
  position: absolute;
  top: 0px;
  width: 64px;
  height: 64px;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__right {
  right: 0px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__right {
  left: 0px;
}

.sendbird-openchannel-conversation-header__right__trigger {
  position: absolute;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir="ltr"] .sendbird-openchannel-conversation-header__right__trigger {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-conversation-header__right__trigger {
  left: 16px;
}

.sendbird-openchannel-conversation-header__left__cover-image--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.sendbird-theme--light .sendbird-openchannel-conversation-header__left__cover-image--icon {
  background-color: var(--sendbird-light-background-300);
}

.sendbird-theme--dark .sendbird-openchannel-conversation-header__left__cover-image--icon {
  background-color: var(--sendbird-dark-background-300);
}
.sendbird-openchannel-conversation-scroll {
  position: relative;
  width: 100%;
}

.sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container {
  position: sticky;
  width: 100%;
  bottom: 0px;
  height: 100%;
  overflow: hidden;
}

.sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__padding {
  height: 8px;
}

.sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__item-container {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__item-container.no-messages {
  width: 100%;
  height: 100%;
}

.sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container .sendbird-openchannel-conversation-scroll__container__item-container.no-messages .sendbird-openchannel-conversation-scroll__container__place-holder {
  width: 100%;
  height: 100%;
}

.sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 8px;
  width: 40px;
  height: 40px;
  border-radius: 24px;
  z-index: 1;
}

[dir="ltr"] .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
  left: calc(50% - 20px);
}

[dir="rtl"] .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
  right: calc(50% - 20px);
}

.sendbird-theme--light .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
  background-color: var(--sendbird-light-primary-300);
  box-shadow: var(--sendbird-light-shadow-05);
}

.sendbird-theme--dark .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
  background-color: var(--sendbird-dark-primary-200);
  box-shadow: var(--sendbird-dark-shadow-05);
}

.sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:hover {
  background-color: var(--sendbird-light-primary-400);
}

.sendbird-theme--dark .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:hover {
  background-color: var(--sendbird-dark-primary-300);
}

.sendbird-theme--light .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:active {
  background-color: var(--sendbird-light-primary-500);
}

.sendbird-theme--dark .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button:active {
  background-color: var(--sendbird-dark-primary-400);
}
.sendbird-open-channel-msg-hoc {
  min-width: 380px;
}
.sendbird-openchannel-user-message {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sendbird-theme--light .sendbird-openchannel-user-message:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-user-message:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-openchannel-user-message:hover .sendbird-openchannel-user-message__context-menu {
  display: block;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__left {
  position: relative;
  display: flex;
  width: 52px;
  min-width: 52px;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__left .sendbird-openchannel-user-message__left__avatar {
  position: relative;
  display: flex;
  margin-top: 8px;
}

[dir="ltr"] .sendbird-openchannel-user-message .sendbird-openchannel-user-message__left .sendbird-openchannel-user-message__left__avatar {
  margin-left: 24px;
}

[dir="rtl"] .sendbird-openchannel-user-message .sendbird-openchannel-user-message__left .sendbird-openchannel-user-message__left__avatar {
  margin-right: 24px;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__right {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

[dir="ltr"] .sendbird-openchannel-user-message .sendbird-openchannel-user-message__right {
  margin: 8px 24px 8px 12px;
}

[dir="rtl"] .sendbird-openchannel-user-message .sendbird-openchannel-user-message__right {
  margin: 8px 12px 8px 24px;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__top {
  position: relative;
  display: flex;
  margin-bottom: 2px;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__top .sendbird-openchannel-user-message__right__top__sender-name {
  position: relative;
  display: flex;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__top .sendbird-openchannel-user-message__right__top__sent-at {
  position: relative;
  display: flex;
}

[dir="ltr"] .sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__top .sendbird-openchannel-user-message__right__top__sent-at {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__top .sendbird-openchannel-user-message__right__top__sent-at {
  margin-right: 4px;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__bottom {
  position: relative;
  display: flex;
  width: 100%;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__bottom .sendbird-openchannel-user-message__right__bottom__message {
  position: relative;
  word-break: break-all;
  white-space: pre-line;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__tail {
  position: relative;
  top: 2px;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__context-menu {
  position: absolute;
  display: none;
}

[dir="ltr"] .sendbird-openchannel-user-message .sendbird-openchannel-user-message__context-menu {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-user-message .sendbird-openchannel-user-message__context-menu {
  left: 16px;
}

.sendbird-theme--light .sendbird-openchannel-user-message .sendbird-openchannel-user-message__context-menu .sendbird-openchannel-user-message__context-menu--icon {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-openchannel-user-message .sendbird-openchannel-user-message__context-menu .sendbird-openchannel-user-message__context-menu--icon {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-openchannel-user-message .sendbird-openchannel-user-message__context-menu .sendbird-openchannel-user-message__context-menu--icon:hover {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-openchannel-user-message .sendbird-openchannel-user-message__context-menu .sendbird-openchannel-user-message__context-menu--icon:hover {
  background-color: var(--sendbird-dark-background-700);
}
.sendbird-openchannel__mobile-menu-hyperlink {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-stretch: normal;
  font-style: normal;
  line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;
}
.sendbird-openchannel-admin-message {
  position: relative;
  margin: 16px 24px;
  padding: 12px 16px;
  border-radius: 4px;
}

.sendbird-theme--light .sendbird-openchannel-admin-message {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-admin-message {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-openchannel-admin-message:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-admin-message:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-openchannel-admin-message .sendbird-openchannel-admin-message__text {
  word-wrap: break-word;
}
.sendbird-openchannel-og-message {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sendbird-theme--light .sendbird-openchannel-og-message:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-og-message:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-openchannel-og-message:hover .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__context-menu {
  display: block;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__left {
  position: relative;
  display: flex;
  width: 52px;
  min-width: 52px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__left .sendbird-openchannel-og-message__top__left__avatar {
  position: absolute;
  display: flex;
  top: 8px;
}

[dir="ltr"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__left .sendbird-openchannel-og-message__top__left__avatar {
  left: 24px;
}

[dir="rtl"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__left .sendbird-openchannel-og-message__top__left__avatar {
  right: 24px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

[dir="ltr"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right {
  margin: 8px 24px 8px 12px;
}

[dir="rtl"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right {
  margin: 8px 12px 8px 24px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right .sendbird-openchannel-og-message__top__right__title {
  position: relative;
  display: flex;
  margin-bottom: 2px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right .sendbird-openchannel-og-message__top__right__title .sendbird-openchannel-og-message__top__right__title__sender-name {
  position: relative;
  display: flex;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right .sendbird-openchannel-og-message__top__right__title .sendbird-openchannel-og-message__top__right__title__sent-at {
  position: relative;
  display: flex;
}

[dir="ltr"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right .sendbird-openchannel-og-message__top__right__title .sendbird-openchannel-og-message__top__right__title__sent-at {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right .sendbird-openchannel-og-message__top__right__title .sendbird-openchannel-og-message__top__right__title__sent-at {
  margin-right: 4px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right .sendbird-openchannel-og-message__top__right__description .sendbird-openchannel-og-message__top__right__description__message {
  position: relative;
  display: inline;
  white-space: pre-wrap;
  word-break: break-all;
}

.sendbird-theme--light .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right .sendbird-openchannel-og-message__top__right__description .sendbird-openchannel-og-message__top__right__description__message .sendbird-word .sendbird-word__url {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__right .sendbird-openchannel-og-message__top__right__description .sendbird-openchannel-og-message__top__right__description__message .sendbird-word .sendbird-word__url {
  color: var(--sendbird-dark-primary-200);
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__context-menu {
  position: absolute;
  display: none;
}

[dir="ltr"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__context-menu {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__context-menu {
  left: 16px;
}

.sendbird-theme--light .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__context-menu .sendbird-openchannel-og-message__top__context-menu--icon {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__context-menu .sendbird-openchannel-og-message__top__context-menu--icon {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__context-menu .sendbird-openchannel-og-message__top__context-menu--icon:hover {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top .sendbird-openchannel-og-message__top__context-menu .sendbird-openchannel-og-message__top__context-menu--icon:hover {
  background-color: var(--sendbird-dark-background-700);
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom {
  position: relative;
  display: inline;
  margin-bottom: 8px;
  width: 100%;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 360px;
  padding: 12px 12px 4px 12px;
  border-radius: 8px;
}

[dir="ltr"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag {
  left: 64px;
}

[dir="rtl"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag {
  right: 64px;
}

.sendbird-theme--light .sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag {
  border: solid 1px var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag {
  border: solid 1px var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__url {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  max-width: 336px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__title {
  position: relative;
  display: inline-block;
  white-space: pre-wrap;
  overflow-wrap: normal;
  margin-bottom: 8px;
  max-width: 336px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__title:hover {
  cursor: pointer;
}

.sendbird-theme--light .sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__title:hover {
  color: var(--sendbird-light-primary-300);
}

.sendbird-theme--dark .sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__title:hover {
  color: var(--sendbird-dark-primary-200);
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__description {
  position: relative;
  display: block;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-height: 32px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 336px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__thumbnail {
  box-sizing: border-box;
  position: relative;
  display: block;
  margin-top: 4px;
  margin-bottom: 8px;
  width: 100%;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__thumbnail:hover {
  cursor: pointer;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__thumbnail .sendbird-openchannel-og-message__bottom__og-tag__thumbnail__image {
  border-radius: 8px;
  width: 100%;
  min-width: 200px;
  max-width: 336px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__bottom .sendbird-openchannel-og-message__bottom__og-tag .sendbird-openchannel-og-message__bottom__og-tag__thumbnail .sendbird-openchannel-og-message__bottom__og-tag__thumbnail__image--placeholder {
  border-radius: 8px;
  width: 100%;
  min-width: 200px;
  max-width: 336px;
}

.sendbird-openchannel-og-message .sendbird-openchannel-og-message__top__right__tail {
  position: relative;
  top: 2px;
}

[dir="ltr"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top__right__tail {
  left: 64px;
}

[dir="rtl"] .sendbird-openchannel-og-message .sendbird-openchannel-og-message__top__right__tail {
  right: 64px;
}

.sendbird-openchannel-og-message--word {
  display: inline;
  margin: 0px 2px;
  word-break: break-all;
}
.sendbird-openchannel-thumbnail-message {
  position: relative;
  display: flex;
  flex-direction: row;
}

.sendbird-theme--light .sendbird-openchannel-thumbnail-message:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-thumbnail-message:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-openchannel-thumbnail-message:hover .sendbird-openchannel-thumbnail-message__context-menu {
  display: block;
}

.sendbird-theme--light .sendbird-openchannel-thumbnail-message:hover .sendbird-openchannel-thumbnail-message__context-menu .sendbird-openchannel-thumbnail-message__context-menu--icon {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-openchannel-thumbnail-message:hover .sendbird-openchannel-thumbnail-message__context-menu .sendbird-openchannel-thumbnail-message__context-menu--icon {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-openchannel-thumbnail-message:hover .sendbird-openchannel-thumbnail-message__context-menu .sendbird-openchannel-thumbnail-message__context-menu--icon:hover {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-openchannel-thumbnail-message:hover .sendbird-openchannel-thumbnail-message__context-menu .sendbird-openchannel-thumbnail-message__context-menu--icon:hover {
  background-color: var(--sendbird-dark-background-700);
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__left {
  position: relative;
  display: flex;
  width: 52px;
  min-width: 52px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__left .sendbird-openchannel-thumbnail-message__left__avatar {
  position: absolute;
  display: inline;
  margin-top: 8px;
}

[dir="ltr"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__left .sendbird-openchannel-thumbnail-message__left__avatar {
  margin-left: 28px;
}

[dir="rtl"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__left .sendbird-openchannel-thumbnail-message__left__avatar {
  margin-right: 28px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
  width: calc(100% - 64px);
}

[dir="ltr"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right {
  margin-left: 12px;
}

[dir="rtl"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right {
  margin-right: 12px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__title {
  position: relative;
  display: inline;
  margin-bottom: 4px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__title .sendbird-openchannel-thumbnail-message__right__title__sender-name {
  position: relative;
  display: inline;
}

[dir="ltr"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__title .sendbird-openchannel-thumbnail-message__right__title__sender-name {
  margin-right: 4px;
}

[dir="rtl"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__title .sendbird-openchannel-thumbnail-message__right__title__sender-name {
  margin-left: 4px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__title .sendbird-openchannel-thumbnail-message__right__title__sent-at {
  position: relative;
  display: inline;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body {
  position: relative;
  display: flex;
  border-radius: 8px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body:hover .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__overlay {
  opacity: 1;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap {
  position: relative;
  box-sizing: content-box;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__overlay {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  max-width: 360px;
  top: 0px;
  border-radius: 8px;
  cursor: pointer;
}

[dir="ltr"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__overlay {
  left: 0px;
}

[dir="rtl"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__overlay {
  right: 0px;
}

.sendbird-theme--light .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__overlay {
  background-color: var(--sendbird-light-overlay-02);
}

.sendbird-theme--dark .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__overlay {
  background-color: var(--sendbird-dark-overlay-02);
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video {
  max-width: 360px;
  max-height: 360px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video:hover {
  cursor: pointer;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video .sendbird-openchannel-thumbnail-message__right__body__wrap__video__video {
  max-width: 360px;
  max-height: 360px;
  border-radius: 8px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video .sendbird-openchannel-thumbnail-message__right__body__wrap__video__icon {
  position: absolute;
  top: calc(50% - 28px);
}

[dir="ltr"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video .sendbird-openchannel-thumbnail-message__right__body__wrap__video__icon {
  left: calc(50% - 28px);
}

[dir="rtl"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video .sendbird-openchannel-thumbnail-message__right__body__wrap__video__icon {
  right: calc(50% - 28px);
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video--icon {
  padding: 107px 152px 107px 152px;
  border-radius: 8px;
}

.sendbird-theme--light .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video--icon {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__video--icon {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__image {
  border-radius: 8px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__image--icon {
  padding: 107px 152px 107px 152px;
  border-radius: 8px;
}

.sendbird-theme--light .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__image--icon {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__image--icon {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__unknown {
  padding: 107px 152px 107px 152px;
  border-radius: 8px;
}

.sendbird-theme--light .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__unknown {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__body .sendbird-openchannel-thumbnail-message__right__body__wrap .sendbird-openchannel-thumbnail-message__right__body__wrap__unknown {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__right .sendbird-openchannel-thumbnail-message__right__tail {
  position: relative;
  top: 2px;
}

.sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__context-menu {
  position: absolute;
  display: none;
  top: 8px;
}

[dir="ltr"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__context-menu {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-thumbnail-message .sendbird-openchannel-thumbnail-message__context-menu {
  left: 16px;
}
.sendbird-openchannel-file-message {
  position: relative;
  display: flex;
  flex-direction: row;
}

.sendbird-theme--light .sendbird-openchannel-file-message:hover {
  background-color: var(--sendbird-light-background-100);
}

.sendbird-theme--dark .sendbird-openchannel-file-message:hover {
  background-color: var(--sendbird-dark-background-400);
}

.sendbird-openchannel-file-message:hover .sendbird-openchannel-file-message__context-menu {
  display: block;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__left {
  position: relative;
  display: flex;
  width: 52px;
  min-width: 52px;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__left .sendbird-openchannel-file-message__left__avatar {
  position: absolute;
  display: block;
  margin-top: 8px;
}

[dir="ltr"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__left .sendbird-openchannel-file-message__left__avatar {
  margin-left: 24px;
}

[dir="rtl"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__left .sendbird-openchannel-file-message__left__avatar {
  margin-right: 24px;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__right {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  width: calc(100% - 64px);
}

[dir="ltr"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right {
  margin-left: 12px;
}

[dir="rtl"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right {
  margin-right: 12px;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__title {
  position: relative;
  display: flex;
  margin-top: 8px;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__title .sendbird-openchannel-file-message__right__title__sender-name {
  position: relative;
  display: flex;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__title .sendbird-openchannel-file-message__right__title__sent-at {
  position: relative;
  display: flex;
}

[dir="ltr"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__title .sendbird-openchannel-file-message__right__title__sent-at {
  margin-left: 4px;
}

[dir="rtl"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__title .sendbird-openchannel-file-message__right__title__sent-at {
  margin-right: 4px;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body {
  position: relative;
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 8px;
  max-width: 360px;
}

.sendbird-theme--light .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body {
  background-color: var(--sendbird-light-background-100);
  border: solid 1px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body {
  background-color: var(--sendbird-dark-background-500);
  border: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body .sendbird-openchannel-file-message__right__body__icon {
  position: relative;
  display: inline-block;
  margin: 8px;
  border-radius: 8.6px;
}

.sendbird-theme--light .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body .sendbird-openchannel-file-message__right__body__icon {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body .sendbird-openchannel-file-message__right__body__icon {
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body .sendbird-openchannel-file-message__right__body__file-name {
  position: relative;
  display: flex;
  margin-top: 22px;
  overflow: hidden;
  height: 20px;
  max-height: 20px;
}

[dir="ltr"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body .sendbird-openchannel-file-message__right__body__file-name {
  margin-right: 16px;
  margin-left: 4px;
}

[dir="rtl"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body .sendbird-openchannel-file-message__right__body__file-name {
  margin-left: 16px;
  margin-right: 4px;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__right .sendbird-openchannel-file-message__right__body .sendbird-openchannel-file-message__right__body__file-name .sendbird-openchannel-file-message__right__body__file-name__label {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sendbird-openchannel-file-message .sendbird-openchannel-file-message__context-menu {
  position: absolute;
  display: none;
  top: 8px;
}

[dir="ltr"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__context-menu {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-file-message .sendbird-openchannel-file-message__context-menu {
  left: 16px;
}

.sendbird-theme--light .sendbird-openchannel-file-message .sendbird-openchannel-file-message__context-menu .sendbird-openchannel-file-message__context-menu__icon {
  background-color: var(--sendbird-light-background-200);
}

.sendbird-theme--dark .sendbird-openchannel-file-message .sendbird-openchannel-file-message__context-menu .sendbird-openchannel-file-message__context-menu__icon {
  background-color: var(--sendbird-dark-background-500);
}

.sendbird-theme--light .sendbird-openchannel-file-message .sendbird-openchannel-file-message__context-menu .sendbird-openchannel-file-message__context-menu__icon:hover {
  background-color: var(--sendbird-light-primary-100);
}

.sendbird-theme--dark .sendbird-openchannel-file-message .sendbird-openchannel-file-message__context-menu .sendbird-openchannel-file-message__context-menu__icon:hover {
  background-color: var(--sendbird-dark-background-700);
}
.sendbird-openchannel-settings {
  height: 100%;
  width: 320px;
  display: inline-block;
  overflow-y: scroll;
  position: relative;
}

.sendbird--mobile-mode .sendbird-openchannel-settings {
  width: 100%;
}

.sendbird-theme--light .sendbird-openchannel-settings {
  background-color: var(--sendbird-light-background-50);
}

[dir="ltr"] .sendbird-theme--light .sendbird-openchannel-settings {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}

[dir="rtl"] .sendbird-theme--light .sendbird-openchannel-settings {
  border-right: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-openchannel-settings {
  background-color: var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-theme--dark .sendbird-openchannel-settings {
  border-left: 1px solid var(--sendbird-dark-ondark-04);
}

[dir="rtl"] .sendbird-theme--dark .sendbird-openchannel-settings {
  border-right: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-openchannel-settings .sendbird-participants-accordion__footer {
  padding-top: 16px;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-participants-accordion__footer {
  text-align: left;
  padding-left: 16px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-participants-accordion__footer {
  text-align: right;
  padding-right: 16px;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__operator-accordion-icon {
  position: absolute;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__operator-accordion-icon {
  left: 14px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__operator-accordion-icon {
  right: 14px;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__accordion-icon,
.sendbird-openchannel-settings .sendbird-openchannel-settings__panel-icon__delete {
  position: absolute;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__accordion-icon,
[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__panel-icon__delete {
  left: 16px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__accordion-icon,
[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__panel-icon__delete {
  right: 16px;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__participant {
  height: 100%;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__participant-list {
  padding-top: 64px;
  height: calc(100% - 64px);
  overflow-y: scroll;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__header {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__header {
  left: 0;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__header {
  right: 0;
}

.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__header {
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__header {
  background-color: var(--sendbird-dark-background-600);
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-participants-accordion,
[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__delete-channel {
  text-align: left;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-participants-accordion,
[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__delete-channel {
  text-align: right;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__members .sendbird-badge__text {
  margin-top: -2px;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__header {
  height: 64px;
  min-height: 64px;
  position: relative;
  padding: 20px 24px;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__header {
  text-align: left;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__header {
  text-align: right;
}

.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__header {
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__header {
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__close-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__close-icon {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__close-icon {
  left: 16px;
}

.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__close-icon path {
  fill: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__close-icon path {
  fill: var(--sendbird-dark-ondark-01);
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__placeholder {
  margin-top: 40%;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__url {
  padding: 16px;
  box-sizing: border-box;
  position: relative;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__url:hover .sendbird-openchannel-settings__copy-icon {
  display: inline-block;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__copy-icon {
  cursor: pointer;
  position: absolute;
  display: none;
  top: 10px;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__copy-icon {
  right: 18px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__copy-icon {
  left: 18px;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value {
  margin-top: 8px;
}

.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value {
  color: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value {
  color: var(--sendbird-dark-ondark-01);
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value,
.sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-label {
  display: block;
  word-wrap: break-word;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value,
[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-label {
  text-align: left;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value,
[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-label {
  text-align: right;
}

.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__url {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__url {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item {
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item {
  padding: 12px 22px 10px 56px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item {
  padding: 12px 56px 10px 22px;
}

.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item:focus {
  outline: none;
}

.sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item.sendbird-channel-settings__panel-item__disabled {
  cursor: not-allowed;
}

.sendbird-openchannel-settings .sendbird-participants-accordion__member {
  position: relative;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-participants-accordion__member {
  padding: 12px 0px 12px 56px;
  text-align: left;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-participants-accordion__member {
  padding: 12px 56px 12px 0px;
  text-align: right;
}

.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-label {
  max-width: 190px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.self-operator,
.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.operator,
.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.user-id {
  position: absolute;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.self-operator,
[dir="ltr"] .sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.operator,
[dir="ltr"] .sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.user-id {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.self-operator,
[dir="rtl"] .sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.operator,
[dir="rtl"] .sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.user-id {
  left: 16px;
}

.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__action {
  display: none;
}

.sendbird-openchannel-settings .sendbird-participants-accordion__member:hover .sendbird-participants-accordion__member__title.operator,
.sendbird-openchannel-settings .sendbird-participants-accordion__member:hover .sendbird-participants-accordion__member__title.user-id {
  display: none;
}

.sendbird-openchannel-settings .sendbird-participants-accordion__member:hover .sendbird-participants-accordion__member__action {
  display: inline-block;
}

.sendbird-openchannel-settings .sendbird-participants-accordion__member-avatar {
  position: absolute;
  top: 10px;
  cursor: pointer;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-participants-accordion__member-avatar {
  left: 16px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-participants-accordion__member-avatar {
  right: 16px;
}

.sendbird-openchannel-settings .sendbird-participants-accordion__member__action {
  position: absolute;
  display: none;
  top: 4px;
  cursor: pointer;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-participants-accordion__member__action {
  right: 8px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-participants-accordion__member__action {
  left: 8px;
}

.sendbird-openchannel-settings .sendbird-openchannel-operator-list__footer,
.sendbird-openchannel-settings .sendbird-openchannel-participant-list__footer,
.sendbird-openchannel-settings .sendbird-channel-settings-muted-participant-list__footer,
.sendbird-openchannel-settings .sendbird-channel-settings-banned-user-list__footer {
  padding-top: 16px;
  display: flex;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-operator-list__footer,
[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-participant-list__footer,
[dir="ltr"] .sendbird-openchannel-settings .sendbird-channel-settings-muted-participant-list__footer,
[dir="ltr"] .sendbird-openchannel-settings .sendbird-channel-settings-banned-user-list__footer {
  justify-content: left;
  padding-left: 12px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-operator-list__footer,
[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-participant-list__footer,
[dir="rtl"] .sendbird-openchannel-settings .sendbird-channel-settings-muted-participant-list__footer,
[dir="rtl"] .sendbird-openchannel-settings .sendbird-channel-settings-banned-user-list__footer {
  justify-content: right;
  padding-right: 12px;
}

[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-operator-list__footer .sendbird-button:nth-child(2),
[dir="ltr"] .sendbird-openchannel-settings .sendbird-openchannel-participant-list__footer .sendbird-button:nth-child(2),
[dir="ltr"] .sendbird-openchannel-settings .sendbird-channel-settings-muted-participant-list__footer .sendbird-button:nth-child(2),
[dir="ltr"] .sendbird-openchannel-settings .sendbird-channel-settings-banned-user-list__footer .sendbird-button:nth-child(2) {
  margin-left: 8px;
}

[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-operator-list__footer .sendbird-button:nth-child(2),
[dir="rtl"] .sendbird-openchannel-settings .sendbird-openchannel-participant-list__footer .sendbird-button:nth-child(2),
[dir="rtl"] .sendbird-openchannel-settings .sendbird-channel-settings-muted-participant-list__footer .sendbird-button:nth-child(2),
[dir="rtl"] .sendbird-openchannel-settings .sendbird-channel-settings-banned-user-list__footer .sendbird-button:nth-child(2) {
  margin-right: 8px;
}

.sendbird-participants-accordion__member-avatar__avatar {
  position: absolute;
}

.sendbird-more-users__popup-scroll {
  max-height: 420px;
  overflow: scroll;
}
.sendbird-openchannel-profile {
  position: relative;
  text-align: center;
  padding: 32px 40px 27px 40px;
}

.sendbird-theme--light .sendbird-openchannel-profile {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-openchannel-profile {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-openchannel-profile .sendbird-openchannel-profile--inner {
  height: 116px;
}

.sendbird-openchannel-profile .sendbird-openchannel-profile__avatar {
  margin-bottom: 16px;
  text-align: center;
}

.sendbird-openchannel-profile .sendbird-openchannel-profile__title {
  display: inline-block;
  max-width: 240px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 18px;
}

.sendbird-openchannel-profile .sendbird-openchannel-profile__edit {
  position: absolute;
  top: 10px;
}

[dir="ltr"] .sendbird-openchannel-profile .sendbird-openchannel-profile__edit {
  right: 16px;
}

[dir="rtl"] .sendbird-openchannel-profile .sendbird-openchannel-profile__edit {
  left: 16px;
}
.sendbird-theme--light .sendbird-accordion.sendbird-accordion--closed {
  border-bottom: 0px;
}

.sendbird-theme--dark .sendbird-accordion.sendbird-accordion--closed {
  border-bottom: 0px;
}

.sendbird-accordion {
  position: relative;
  box-sizing: border-box;
}

.sendbird-theme--light .sendbird-accordion {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}

.sendbird-theme--dark .sendbird-accordion {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}

.sendbird-accordion .sendbird-accordion__member {
  position: relative;
  height: 40px;
  box-sizing: border-box;
}

[dir="ltr"] .sendbird-accordion .sendbird-accordion__member {
  padding: 12px 0px 12px 44px;
}

[dir="rtl"] .sendbird-accordion .sendbird-accordion__member {
  padding: 12px 44px 12px 0px;
}

.sendbird-accordion .sendbird-accordion__footer {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
}

.sendbird-accordion__list {
  padding-top: 8px;
  padding-bottom: 16px;
}

.sendbird-accordion__panel-header {
  cursor: pointer;
  position: relative;
}

[dir="ltr"] .sendbird-accordion__panel-header {
  padding: 12px 22px 10px 56px;
}

[dir="rtl"] .sendbird-accordion__panel-header {
  padding: 12px 56px 10px 22px;
}

.sendbird-theme--light .sendbird-accordion__panel-header {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}

.sendbird-theme--dark .sendbird-accordion__panel-header {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-accordion__panel-header:focus {
  outline: none;
}

.sendbird-accordion__panel-header.sendbird-accordion__panel-item__disabled {
  cursor: not-allowed;
}

.sendbird-accordion__panel-icon-left,
.sendbird-accordion__panel-icon-right {
  position: absolute;
  top: 10px;
}

[dir="ltr"] .sendbird-accordion__panel-icon-left {
  left: 16px;
}

[dir="rtl"] .sendbird-accordion__panel-icon-left {
  right: 16px;
}

[dir="ltr"] .sendbird-accordion__panel-icon-right {
  right: 16px;
}

[dir="rtl"] .sendbird-accordion__panel-icon-right {
  left: 16px;
  transform: rotate(180deg);
}

[dir="ltr"] .sendbird-accordion__panel-icon--open {
  transform: rotate(90deg);
}

[dir="rtl"] .sendbird-accordion__panel-icon--open {
  transform: rotate(-90deg);
}

.sendbird-theme--light .sendbird-accordion__panel-icon--chevron path {
  fill: var(--sendbird-light-onlight-01);
}

.sendbird-theme--dark .sendbird-accordion__panel-icon--chevron path {
  fill: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-accordion__panel-icon__leave path {
  fill: var(--sendbird-light-error-300);
}

.sendbird-theme--dark .sendbird-accordion__panel-icon__leave path {
  fill: var(--sendbird-dark-error-300);
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-list {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 12px 0;
}
.ant-list-item-content {
  color: rgba(0, 0, 0, 0.65);
}
.ant-list-item-meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1 1;
  -ms-flex-align: start;
      align-items: flex-start;
  font-size: 0;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  -ms-flex: 1 0;
      flex: 1 0;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #1890ff;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-action {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ant-list-vertical .ant-list-item {
  -ms-flex-align: initial;
      align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  -ms-flex: 1;
      flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-tree.ant-tree-directory {
  position: relative;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #e6f7ff;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #1890ff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #1890ff;
}
.ant-tree-checkbox {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree {
  /* see https://github.com/ant-design/ant-design/issues/16259 */
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 16.67%;
  left: 0;
  width: 100%;
  height: 66.67%;
}
.ant-tree ol,
.ant-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-tree li span[draggable],
.ant-tree li span[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.ant-tree li.drag-over > span[draggable] {
  color: white;
  background-color: #1890ff;
  opacity: 0.8;
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: #1890ff;
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: #1890ff;
}
.ant-tree li.filter-node > span {
  color: #f5222d !important;
  font-weight: 500 !important;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #1890ff;
  font-size: 14px;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
  opacity: 0;
}
.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #e6f7ff;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff;
}
.ant-tree li span.ant-tree-checkbox {
  top: initial;
  height: 24px;
  margin: 0 4px 0 2px;
  padding: 4px 0;
}
.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-tree li span.ant-tree-iconEle:empty {
  display: none;
}
.ant-tree li span.ant-tree-switcher {
  position: relative;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-tree li:last-child > span.ant-tree-switcher::before,
.ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
.ant-tree > li:first-child {
  padding-top: 7px;
}
.ant-tree > li:last-child {
  padding-bottom: 7px;
}
.ant-tree-child-tree > li:first-child {
  padding-top: 8px;
}
.ant-tree-child-tree > li:last-child {
  padding-bottom: 0;
}
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree.ant-tree-show-line li {
  position: relative;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: rgba(0, 0, 0, 0.45);
  background: #fff;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  height: calc(100% - 22px);
  margin: 22px 0 0;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
.ant-tree.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
  width: calc(100% - 46px);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-form {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-required::before {
  display: none;
}
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.85);
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item label {
  position: relative;
}
.ant-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-control {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  display: table;
  content: '';
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-children {
  position: relative;
}
.ant-form-item-with-help {
  margin-bottom: 5px;
}
.ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-explain,
.ant-form-extra {
  clear: both;
  min-height: 22px;
  margin-top: -2px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-explain {
  margin-bottom: -1px;
}
.ant-form-extra {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form .has-feedback .ant-input {
  padding-right: 30px;
}
form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}
form .has-feedback .ant-input-affix-wrapper .ant-input {
  padding-right: 49px;
}
form .has-feedback .ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input {
  padding-right: 68px;
}
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
form .ant-mentions,
form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}
form .ant-upload {
  background: transparent;
}
form input[type='radio'],
form input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}
form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
form .ant-input-number-handler-wrap {
  z-index: 2;
}
form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}
form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}
form :not(.ant-input-group-wrapper) > .ant-input-group,
form .ant-input-group-wrapper {
  display: inline-block;
  vertical-align: middle;
}
form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group,
form:not(.ant-form-vertical) .ant-input-group-wrapper {
  position: relative;
  top: -1px;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label label::after,
.ant-col-24.ant-form-item-label label::after,
.ant-col-xl-24.ant-form-item-label label::after {
  display: none;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
.ant-form-vertical .ant-form-item-control {
  line-height: 1.5;
}
.ant-form-vertical .ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}
.ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  .ant-form-item-label,
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item-label label::after {
    display: none;
  }
  .ant-col-xs-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label label::after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .has-feedback {
  display: inline-block;
}
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.has-error.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: #faad14;
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
          box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
          box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
          box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #faad14;
}
.has-warning .ant-input-prefix {
  color: #faad14;
}
.has-warning .ant-input-group-addon {
  color: #faad14;
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .has-feedback {
  color: #faad14;
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: #faad14;
  -webkit-animation-name: diffZoomIn3 !important;
          animation-name: diffZoomIn3 !important;
}
.has-warning .ant-select-selection {
  border-color: #faad14;
}
.has-warning .ant-select-selection:hover {
  border-color: #faad14;
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
          box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-calendar-picker-icon::after,
.has-warning .ant-time-picker-icon::after,
.has-warning .ant-picker-icon::after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: #faad14;
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: #faad14;
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
          box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
          box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #faad14;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #f5222d;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
          box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
          box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
          box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #f5222d;
}
.has-error .ant-input-prefix {
  color: #f5222d;
}
.has-error .ant-input-group-addon {
  color: #f5222d;
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .has-feedback {
  color: #f5222d;
}
.has-error.has-feedback .ant-form-item-children-icon {
  color: #f5222d;
  -webkit-animation-name: diffZoomIn2 !important;
          animation-name: diffZoomIn2 !important;
}
.has-error .ant-select-selection {
  border-color: #f5222d;
}
.has-error .ant-select-selection:hover {
  border-color: #f5222d;
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
          box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #f5222d;
}
.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.has-error .ant-calendar-picker-icon::after,
.has-error .ant-time-picker-icon::after,
.has-error .ant-picker-icon::after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: #f5222d;
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #f5222d;
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
          box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
          box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
          box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #f5222d;
}
.has-error .ant-transfer-list {
  border-color: #f5222d;
}
.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #1890ff;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 24px;
}
.ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: 5px;
}
.show-help-enter,
.show-help-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
          animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
          animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ant-row::before,
.ant-row::after {
  display: table;
  content: '';
}
.ant-row::after {
  clear: both;
}
.ant-row + .ant-row::before {
  clear: both;
}
.ant-row-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.ant-row-flex::before,
.ant-row-flex::after {
  display: -ms-flexbox;
  display: flex;
}
.ant-row-flex-start {
  -ms-flex-pack: start;
      justify-content: flex-start;
}
.ant-row-flex-center {
  -ms-flex-pack: center;
      justify-content: center;
}
.ant-row-flex-end {
  -ms-flex-pack: end;
      justify-content: flex-end;
}
.ant-row-flex-space-between {
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.ant-row-flex-space-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.ant-row-flex-top {
  -ms-flex-align: start;
      align-items: flex-start;
}
.ant-row-flex-middle {
  -ms-flex-align: center;
      align-items: center;
}
.ant-row-flex-bottom {
  -ms-flex-align: end;
      align-items: flex-end;
}
.ant-col {
  position: relative;
  min-height: 1px;
}
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  position: relative;
  padding-right: 0;
  padding-left: 0;
}
.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  float: left;
}
.ant-col-24 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  -ms-flex-order: 24;
      order: 24;
}
.ant-col-23 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  -ms-flex-order: 23;
      order: 23;
}
.ant-col-22 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  -ms-flex-order: 22;
      order: 22;
}
.ant-col-21 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  -ms-flex-order: 21;
      order: 21;
}
.ant-col-20 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  -ms-flex-order: 20;
      order: 20;
}
.ant-col-19 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  -ms-flex-order: 19;
      order: 19;
}
.ant-col-18 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  -ms-flex-order: 18;
      order: 18;
}
.ant-col-17 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  -ms-flex-order: 17;
      order: 17;
}
.ant-col-16 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  -ms-flex-order: 16;
      order: 16;
}
.ant-col-15 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  -ms-flex-order: 15;
      order: 15;
}
.ant-col-14 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  -ms-flex-order: 14;
      order: 14;
}
.ant-col-13 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  -ms-flex-order: 13;
      order: 13;
}
.ant-col-12 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  -ms-flex-order: 12;
      order: 12;
}
.ant-col-11 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  -ms-flex-order: 11;
      order: 11;
}
.ant-col-10 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  -ms-flex-order: 10;
      order: 10;
}
.ant-col-9 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  -ms-flex-order: 9;
      order: 9;
}
.ant-col-8 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  -ms-flex-order: 8;
      order: 8;
}
.ant-col-7 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  -ms-flex-order: 7;
      order: 7;
}
.ant-col-6 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  -ms-flex-order: 6;
      order: 6;
}
.ant-col-5 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  -ms-flex-order: 5;
      order: 5;
}
.ant-col-4 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  -ms-flex-order: 4;
      order: 4;
}
.ant-col-3 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  -ms-flex-order: 3;
      order: 3;
}
.ant-col-2 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  -ms-flex-order: 2;
      order: 2;
}
.ant-col-1 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  -ms-flex-order: 1;
      order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  -ms-flex-order: 0;
      order: 0;
}
.ant-col-xs-1,
.ant-col-xs-2,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  float: left;
}
.ant-col-xs-24 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  -ms-flex-order: 24;
      order: 24;
}
.ant-col-xs-23 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  -ms-flex-order: 23;
      order: 23;
}
.ant-col-xs-22 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  -ms-flex-order: 22;
      order: 22;
}
.ant-col-xs-21 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  -ms-flex-order: 21;
      order: 21;
}
.ant-col-xs-20 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  -ms-flex-order: 20;
      order: 20;
}
.ant-col-xs-19 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  -ms-flex-order: 19;
      order: 19;
}
.ant-col-xs-18 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  -ms-flex-order: 18;
      order: 18;
}
.ant-col-xs-17 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  -ms-flex-order: 17;
      order: 17;
}
.ant-col-xs-16 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  -ms-flex-order: 16;
      order: 16;
}
.ant-col-xs-15 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  -ms-flex-order: 15;
      order: 15;
}
.ant-col-xs-14 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  -ms-flex-order: 14;
      order: 14;
}
.ant-col-xs-13 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  -ms-flex-order: 13;
      order: 13;
}
.ant-col-xs-12 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  -ms-flex-order: 12;
      order: 12;
}
.ant-col-xs-11 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  -ms-flex-order: 11;
      order: 11;
}
.ant-col-xs-10 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  -ms-flex-order: 10;
      order: 10;
}
.ant-col-xs-9 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  -ms-flex-order: 9;
      order: 9;
}
.ant-col-xs-8 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  -ms-flex-order: 8;
      order: 8;
}
.ant-col-xs-7 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  -ms-flex-order: 7;
      order: 7;
}
.ant-col-xs-6 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  -ms-flex-order: 6;
      order: 6;
}
.ant-col-xs-5 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  -ms-flex-order: 5;
      order: 5;
}
.ant-col-xs-4 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  -ms-flex-order: 4;
      order: 4;
}
.ant-col-xs-3 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  -ms-flex-order: 3;
      order: 3;
}
.ant-col-xs-2 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  -ms-flex-order: 2;
      order: 2;
}
.ant-col-xs-1 {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  -ms-flex-order: 1;
      order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  -ms-flex-order: 0;
      order: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-1,
  .ant-col-sm-2,
  .ant-col-sm-3,
  .ant-col-sm-4,
  .ant-col-sm-5,
  .ant-col-sm-6,
  .ant-col-sm-7,
  .ant-col-sm-8,
  .ant-col-sm-9,
  .ant-col-sm-10,
  .ant-col-sm-11,
  .ant-col-sm-12,
  .ant-col-sm-13,
  .ant-col-sm-14,
  .ant-col-sm-15,
  .ant-col-sm-16,
  .ant-col-sm-17,
  .ant-col-sm-18,
  .ant-col-sm-19,
  .ant-col-sm-20,
  .ant-col-sm-21,
  .ant-col-sm-22,
  .ant-col-sm-23,
  .ant-col-sm-24 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    float: left;
  }
  .ant-col-sm-24 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    -ms-flex-order: 24;
        order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    -ms-flex-order: 23;
        order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    -ms-flex-order: 22;
        order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    -ms-flex-order: 21;
        order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    -ms-flex-order: 20;
        order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    -ms-flex-order: 19;
        order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    -ms-flex-order: 18;
        order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    -ms-flex-order: 17;
        order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    -ms-flex-order: 16;
        order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    -ms-flex-order: 15;
        order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    -ms-flex-order: 14;
        order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    -ms-flex-order: 13;
        order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    -ms-flex-order: 0;
        order: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-1,
  .ant-col-md-2,
  .ant-col-md-3,
  .ant-col-md-4,
  .ant-col-md-5,
  .ant-col-md-6,
  .ant-col-md-7,
  .ant-col-md-8,
  .ant-col-md-9,
  .ant-col-md-10,
  .ant-col-md-11,
  .ant-col-md-12,
  .ant-col-md-13,
  .ant-col-md-14,
  .ant-col-md-15,
  .ant-col-md-16,
  .ant-col-md-17,
  .ant-col-md-18,
  .ant-col-md-19,
  .ant-col-md-20,
  .ant-col-md-21,
  .ant-col-md-22,
  .ant-col-md-23,
  .ant-col-md-24 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    float: left;
  }
  .ant-col-md-24 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    -ms-flex-order: 24;
        order: 24;
  }
  .ant-col-md-23 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    -ms-flex-order: 23;
        order: 23;
  }
  .ant-col-md-22 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    -ms-flex-order: 22;
        order: 22;
  }
  .ant-col-md-21 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    -ms-flex-order: 21;
        order: 21;
  }
  .ant-col-md-20 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    -ms-flex-order: 20;
        order: 20;
  }
  .ant-col-md-19 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    -ms-flex-order: 19;
        order: 19;
  }
  .ant-col-md-18 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    -ms-flex-order: 18;
        order: 18;
  }
  .ant-col-md-17 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    -ms-flex-order: 17;
        order: 17;
  }
  .ant-col-md-16 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    -ms-flex-order: 16;
        order: 16;
  }
  .ant-col-md-15 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    -ms-flex-order: 15;
        order: 15;
  }
  .ant-col-md-14 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    -ms-flex-order: 14;
        order: 14;
  }
  .ant-col-md-13 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    -ms-flex-order: 13;
        order: 13;
  }
  .ant-col-md-12 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .ant-col-md-11 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .ant-col-md-10 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .ant-col-md-9 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .ant-col-md-8 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .ant-col-md-7 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .ant-col-md-6 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .ant-col-md-5 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .ant-col-md-4 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .ant-col-md-3 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .ant-col-md-2 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .ant-col-md-1 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    -ms-flex-order: 0;
        order: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-1,
  .ant-col-lg-2,
  .ant-col-lg-3,
  .ant-col-lg-4,
  .ant-col-lg-5,
  .ant-col-lg-6,
  .ant-col-lg-7,
  .ant-col-lg-8,
  .ant-col-lg-9,
  .ant-col-lg-10,
  .ant-col-lg-11,
  .ant-col-lg-12,
  .ant-col-lg-13,
  .ant-col-lg-14,
  .ant-col-lg-15,
  .ant-col-lg-16,
  .ant-col-lg-17,
  .ant-col-lg-18,
  .ant-col-lg-19,
  .ant-col-lg-20,
  .ant-col-lg-21,
  .ant-col-lg-22,
  .ant-col-lg-23,
  .ant-col-lg-24 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    float: left;
  }
  .ant-col-lg-24 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    -ms-flex-order: 24;
        order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    -ms-flex-order: 23;
        order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    -ms-flex-order: 22;
        order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    -ms-flex-order: 21;
        order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    -ms-flex-order: 20;
        order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    -ms-flex-order: 19;
        order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    -ms-flex-order: 18;
        order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    -ms-flex-order: 17;
        order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    -ms-flex-order: 16;
        order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    -ms-flex-order: 15;
        order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    -ms-flex-order: 14;
        order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    -ms-flex-order: 13;
        order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    -ms-flex-order: 0;
        order: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-1,
  .ant-col-xl-2,
  .ant-col-xl-3,
  .ant-col-xl-4,
  .ant-col-xl-5,
  .ant-col-xl-6,
  .ant-col-xl-7,
  .ant-col-xl-8,
  .ant-col-xl-9,
  .ant-col-xl-10,
  .ant-col-xl-11,
  .ant-col-xl-12,
  .ant-col-xl-13,
  .ant-col-xl-14,
  .ant-col-xl-15,
  .ant-col-xl-16,
  .ant-col-xl-17,
  .ant-col-xl-18,
  .ant-col-xl-19,
  .ant-col-xl-20,
  .ant-col-xl-21,
  .ant-col-xl-22,
  .ant-col-xl-23,
  .ant-col-xl-24 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    float: left;
  }
  .ant-col-xl-24 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    -ms-flex-order: 24;
        order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    -ms-flex-order: 23;
        order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    -ms-flex-order: 22;
        order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    -ms-flex-order: 21;
        order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    -ms-flex-order: 20;
        order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    -ms-flex-order: 19;
        order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    -ms-flex-order: 18;
        order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    -ms-flex-order: 17;
        order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    -ms-flex-order: 16;
        order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    -ms-flex-order: 15;
        order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    -ms-flex-order: 14;
        order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    -ms-flex-order: 13;
        order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    -ms-flex-order: 0;
        order: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-1,
  .ant-col-xxl-2,
  .ant-col-xxl-3,
  .ant-col-xxl-4,
  .ant-col-xxl-5,
  .ant-col-xxl-6,
  .ant-col-xxl-7,
  .ant-col-xxl-8,
  .ant-col-xxl-9,
  .ant-col-xxl-10,
  .ant-col-xxl-11,
  .ant-col-xxl-12,
  .ant-col-xxl-13,
  .ant-col-xxl-14,
  .ant-col-xxl-15,
  .ant-col-xxl-16,
  .ant-col-xxl-17,
  .ant-col-xxl-18,
  .ant-col-xxl-19,
  .ant-col-xxl-20,
  .ant-col-xxl-21,
  .ant-col-xxl-22,
  .ant-col-xxl-23,
  .ant-col-xxl-24 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    float: left;
  }
  .ant-col-xxl-24 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    -ms-flex-order: 24;
        order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    -ms-flex-order: 23;
        order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    -ms-flex-order: 22;
        order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    -ms-flex-order: 21;
        order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    -ms-flex-order: 20;
        order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    -ms-flex-order: 19;
        order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    -ms-flex-order: 18;
        order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    -ms-flex-order: 17;
        order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    -ms-flex-order: 16;
        order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    -ms-flex-order: 15;
        order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    -ms-flex-order: 14;
        order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    -ms-flex-order: 13;
        order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    -ms-flex-order: 0;
        order: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}
.ant-collapse-anim-active {
  -webkit-transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-collapse-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-inactive {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  margin: -1px;
  background-color: inherit;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #1890ff;
}
.ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  float: left;
  width: 100%;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-affix-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.5;
}
.ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input:not(:last-child) {
  padding-right: 49px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding-right: 22px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: #333;
}
.ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  vertical-align: 0;
}
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-input-clear-icon + i {
  margin-left: 6px;
}
.ant-input-textarea-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 8px 0 0;
}
.ant-input-textarea-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-textarea-clear-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-input-textarea-clear-icon + i {
  margin-left: 6px;
}
.ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.ant-input-search-enter-button input {
  border-right: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-time-picker-panel {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-time-picker-panel-inner {
  position: relative;
  left: -2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-time-picker-panel-input {
  width: 100%;
  max-width: 154px;
  margin: 0;
  padding: 0;
  line-height: normal;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input-wrap {
  position: relative;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-time-picker-panel-input-invalid {
  border-color: #f5222d;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
.ant-time-picker-panel-select {
  position: relative;
  float: left;
  width: 56px;
  max-height: 192px;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
}
.ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-time-picker-panel-select:last-child {
  border-right: 0;
}
.ant-time-picker-panel-select:only-child {
  width: 100%;
}
.ant-time-picker-panel-select ul {
  width: 56px;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}
.ant-time-picker-panel-select li {
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 32px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-time-picker-panel-select li:focus {
  color: #1890ff;
  font-weight: 600;
  outline: none;
}
.ant-time-picker-panel-select li:hover {
  background: #e6f7ff;
}
li.ant-time-picker-panel-select-option-selected {
  font-weight: 600;
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
li.ant-time-picker-panel-select-option-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  font-weight: inherit;
}
.ant-time-picker-panel-combobox {
  zoom: 1;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-time-picker {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 128px;
  outline: none;
  cursor: text;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-time-picker-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-time-picker-open {
  opacity: 0;
}
.ant-time-picker-icon,
.ant-time-picker-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 14px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-time-picker-clear .ant-time-picker-clock-icon {
  display: block;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
}
.ant-time-picker-clear {
  z-index: 2;
  background: #fff;
  opacity: 0;
  pointer-events: none;
}
.ant-time-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-time-picker:hover .ant-time-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-time-picker-large .ant-time-picker-input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-small .ant-time-picker-input {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-small .ant-time-picker-icon,
.ant-time-picker-small .ant-time-picker-clear {
  right: 7px;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .ant-input {
      line-height: 1.5;
    }
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.36s;
  transition: all 0.36s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 24px;
  color: #fff;
  font-size: 12px;
}
.ant-switch-loading-icon,
.ant-switch::after {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-switch::after {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
          box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
.ant-switch:not(.ant-switch-disabled):active::before,
.ant-switch:not(.ant-switch-disabled):active::after {
  width: 24px;
}
.ant-switch-loading-icon {
  z-index: 1;
  display: none;
  font-size: 12px;
  background: transparent;
}
.ant-switch-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #1890ff;
}
.ant-switch:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-switch:focus:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 14px;
}
.ant-switch-small .ant-switch-inner {
  margin-right: 3px;
  margin-left: 18px;
  font-size: 12px;
}
.ant-switch-small::after {
  width: 12px;
  height: 12px;
}
.ant-switch-small:active::before,
.ant-switch-small:active::after {
  width: 16px;
}
.ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-right: 18px;
  margin-left: 3px;
}
.ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
.ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  font-weight: bold;
  -webkit-transform: scale(0.66667);
      -ms-transform: scale(0.66667);
          transform: scale(0.66667);
}
.ant-switch-checked {
  background-color: #1890ff;
}
.ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: 6px;
}
.ant-switch-checked::after {
  left: 100%;
  margin-left: -1px;
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
}
.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  cursor: not-allowed;
}
.ant-switch-loading::before,
.ant-switch-disabled::before,
.ant-switch-loading::after,
.ant-switch-disabled::after {
  cursor: not-allowed;
}
@-webkit-keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform: rotate(0deg) scale(0.66667);
            transform: rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.66667);
            transform: rotate(360deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform: rotate(0deg) scale(0.66667);
            transform: rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.66667);
            transform: rotate(360deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-calendar-picker-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-calendar-picker {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-calendar-picker-input {
  outline: none;
}
.ant-calendar-picker-input.ant-input {
  line-height: 1.5;
}
.ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-picker-clear {
  z-index: 2;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.ant-calendar-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
}
.ant-input-disabled + .ant-calendar-picker-icon {
  cursor: not-allowed;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
.ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-input {
  width: 100%;
  height: 22px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-calendar-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-calendar-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-week-number {
  width: 286px;
}
.ant-calendar-week-number-cell {
  text-align: center;
}
.ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-header a:hover {
  color: #40a9ff;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-century-btn::before,
.ant-calendar-header .ant-calendar-prev-decade-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-century-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-century-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-century-btn:hover::before,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-next-year-btn:hover::before,
.ant-calendar-header .ant-calendar-next-century-btn:hover::after,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-month-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-month-btn:hover::before,
.ant-calendar-header .ant-calendar-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-next-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-body {
  padding: 8px 12px;
}
.ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
  text-align: center;
  border: 0;
}
.ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
.ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
.ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-date-panel {
  position: relative;
  outline: none;
}
.ant-calendar-date:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-date:active {
  color: #fff;
  background: #40a9ff;
}
.ant-calendar-today .ant-calendar-date {
  color: #1890ff;
  font-weight: bold;
  border-color: #1890ff;
}
.ant-calendar-selected-day .ant-calendar-date {
  background: #bae7ff;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
}
.ant-calendar-disabled-cell .ant-calendar-date {
  position: relative;
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  content: '';
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  content: ' ';
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-footer:empty {
  border-top: 0;
}
.ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
.ant-calendar-footer-extra {
  text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
.ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar .ant-calendar-ok-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 22px;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
.ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-calendar-range-part {
  position: relative;
  width: 50%;
}
.ant-calendar-range-left {
  float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-right {
  float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
.ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: rgba(0, 0, 0, 0.45);
  line-height: 34px;
  text-align: center;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  pointer-events: none;
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-calendar-range .ant-calendar-today :not(.ant-calendar-disabled-cell) :not(.ant-calendar-last-month-cell) :not(.ant-calendar-next-month-btn-day) .ant-calendar-date {
  color: #1890ff;
  background: #bae7ff;
  border-color: #1890ff;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background: #1890ff;
  border: 1px solid transparent;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #1890ff;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  line-height: 24px;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input:-moz-placeholder-shown, .ant-calendar-range .ant-calendar-time-picker-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder, .ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:placeholder-shown,
.ant-calendar-range .ant-calendar-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
.ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #e6f7ff;
  border: 0;
  border-radius: 0;
  content: '';
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
div.ant-calendar-range-quick-selector {
  text-align: left;
}
div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header,
.ant-calendar-range .ant-calendar-decade-panel-header {
  border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body,
.ant-calendar-range .ant-calendar-decade-panel-body {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
.ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
}
.ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 1050;
  width: 100%;
}
.ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
}
.ant-calendar-time-picker-combobox {
  width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
.ant-calendar-time-picker-input-wrap {
  display: none;
}
.ant-calendar-time-picker-select {
  position: relative;
  float: left;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
.ant-calendar-time-picker-select ul {
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-calendar-time-picker-select li {
  width: 100%;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  list-style: none;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: '';
}
.ant-calendar-time-picker-select li:hover {
  background: #e6f7ff;
}
.ant-calendar-time-picker-select li:focus {
  color: #1890ff;
  font-weight: 600;
  outline: none;
}
li.ant-calendar-time-picker-select-option-selected {
  font-weight: 600;
  background: #f5f5f5;
}
li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-month-panel > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-month-panel-hidden {
  display: none;
}
.ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-month-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-body {
  -ms-flex: 1;
      flex: 1 1;
}
.ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-month-panel-cell {
  text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-year-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-year-panel > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-year-panel-hidden {
  display: none;
}
.ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-year-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-body {
  -ms-flex: 1;
      flex: 1 1;
}
.ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-year-panel-cell {
  text-align: center;
}
.ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-decade-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
      -ms-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
      -ms-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-body {
  -ms-flex: 1;
      flex: 1 1;
}
.ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
.ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
.ant-calendar-week-number-cell {
  opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #e6f7ff;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #bae7ff;
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: rgba(0, 0, 0, 0.65);
  background: transparent;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tag {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
:root .ant-tag .anticon-close {
  font-size: 12px;
}
.ant-tag .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1890ff;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #1890ff;
}
.ant-tag-checkable:active {
  background-color: #096dd9;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-number {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-number:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #40a9ff;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  -webkit-transition: opacity 0.24s linear 0.1s;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  -webkit-transform: scale(0.58333333) rotate(0deg);
      -ms-transform: scale(0.58333333) rotate(0deg);
          transform: scale(0.58333333) rotate(0deg);
  min-width: auto;
  margin-right: 0;
}
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 12px;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 4px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-selection {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-selection:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-selection__clear::before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 24px;
}
.ant-select-no-arrow .ant-select-selection__rendered {
  margin-right: 11px;
}
.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 30px;
}
.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '.';
  pointer-events: none;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
  height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin-left: 7px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 4px;
  outline: 0;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
  padding: 1px;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding: 4px 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #1890ff;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-description {
  margin: 0;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-carousel {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  width: 3px;
  height: auto;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
}
.ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-upload {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  zoom: 1;
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  clear: both;
}
.ant-upload-list {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  zoom: 1;
}
.ant-upload-list::before,
.ant-upload-list::after {
  display: table;
  content: '';
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 28px;
}
.ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
  opacity: 0;
}
.ant-upload-list-item-card-actions.picture {
  top: 25px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  padding-right: 6px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
:root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #e6f7ff;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #f5222d;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 18px;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 36px;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card-container {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
}
.ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  padding-left: 0;
}
.ant-upload-list .ant-upload-success-icon {
  color: #52c41a;
  font-weight: bold;
}
.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-enter {
  -webkit-animation-name: uploadAnimateIn;
          animation-name: uploadAnimateIn;
}
.ant-upload-list .ant-upload-animate-leave {
  -webkit-animation-name: uploadAnimateOut;
          animation-name: uploadAnimateOut;
}
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
          animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
          animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-progress {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
          animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #f5222d;
}
.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #f5222d;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1;
  white-space: normal;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-cascader {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 4px 4px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 4px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #e6f7ff;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fafafa;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
}
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
:root .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #f5222d;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-select-tree-checkbox {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
}
.ant-select-tree li {
  margin: 8px 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-select-tree li.filter-node > span {
  font-weight: 500;
}
.ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 0;
  padding: 3px 5px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #e6f7ff;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #bae7ff;
}
.ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
.ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree li span.ant-select-tree-switcher,
.ant-select-tree li span.ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #1890ff;
  font-size: 14px;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #1890ff;
  font-size: 14px;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
.ant-select-tree-child-tree {
  display: none;
}
.ant-select-tree-child-tree-open {
  display: block;
}
li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-dropdown {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-select-tree-dropdown .ant-select-dropdown-search {
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  padding: 4px;
  background: #fff;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 4px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
}
.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
.ant-select-tree-dropdown .ant-select-not-found {
  display: block;
  padding: 7px 16px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-card-hoverable {
  cursor: pointer;
}
.ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #e8e8e8;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
.ant-card-head::before,
.ant-card-head::after {
  display: table;
  content: '';
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  -ms-flex: 1;
      flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-body {
  padding: 24px;
  zoom: 1;
}
.ant-card-body::before,
.ant-card-body::after {
  display: table;
  content: '';
}
.ant-card-body::after {
  clear: both;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
          box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom: 1;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #1890ff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #1890ff;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
.ant-card-meta::before,
.ant-card-meta::after {
  display: table;
  content: '';
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
}
@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 40px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 38px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #1890ff;
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
  border-top: 2px solid transparent;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: #1890ff;
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
  padding: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  width: 16px;
  height: 16px;
  height: 14px;
  margin-right: -5px;
  margin-left: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
  -webkit-transition: none !important;
  transition: none !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
.ant-tabs-extra-content {
  line-height: 45px;
}
.ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-tabs.ant-tabs-large .ant-tabs-extra-content {
  line-height: 56px;
}
.ant-tabs.ant-tabs-small .ant-tabs-extra-content {
  line-height: 37px;
}
.ant-tabs.ant-tabs-card .ant-tabs-extra-content {
  line-height: 40px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
  height: 100%;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
  width: 90%;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-right: 1px;
  border-right: 0;
  border-radius: 4px 0 0 4px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-left: 1px;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  height: auto;
  border-top: 0;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  padding-top: 1px;
  padding-bottom: 0;
  color: #1890ff;
}
.ant-tabs {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  overflow: hidden;
  zoom: 1;
}
.ant-tabs::before,
.ant-tabs::after {
  display: table;
  content: '';
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 0;
  height: 2px;
  background-color: #1890ff;
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav-container {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  display: table;
  content: '';
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  margin-bottom: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  top: 1px;
  bottom: auto;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
  margin-top: -1px;
  margin-bottom: 0;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
      -ms-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  font-size: 12px;
}
.ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab-next {
  right: 2px;
}
.ant-tabs-tab-prev {
  left: 0;
}
:root .ant-tabs-tab-prev {
  -webkit-filter: none;
          filter: none;
}
.ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}
.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
.ant-tabs-nav {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav::before,
.ant-tabs-nav::after {
  display: table;
  content: ' ';
}
.ant-tabs-nav::after {
  clear: both;
}
.ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  margin: 0 32px 0 0;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav .ant-tabs-tab::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-top: 2px solid transparent;
  border-radius: 4px 4px 0 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #40a9ff;
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: #096dd9;
}
.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  font-weight: 500;
}
.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  font-size: 16px;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
  font-size: 14px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 8px 16px;
}
.ant-tabs-content::before {
  display: block;
  overflow: hidden;
  content: '';
}
.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  width: 100%;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  opacity: 1;
  -webkit-transition: opacity 0.45s;
  transition: opacity 0.45s;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
}
.ant-tabs .ant-tabs-left-bar,
.ant-tabs .ant-tabs-right-bar {
  height: 100%;
  border-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
.ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
  text-align: center;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
  width: auto;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  height: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
  width: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 2px;
  height: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
  right: 0;
  bottom: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-content,
.ant-tabs .ant-tabs-right-content {
  width: auto;
  margin-top: 0 !important;
  overflow: hidden;
}
.ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: 1px;
}
.ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar {
  float: right;
  margin-bottom: 0;
  margin-left: -1px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  left: 1px;
}
.ant-tabs .ant-tabs-right-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  -webkit-transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
  -webkit-transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.no-flex > .ant-tabs-content > .ant-tabs-content-animated,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  -webkit-transform: none !important;
      -ms-transform: none !important;
          transform: none !important;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs-left-content > .ant-tabs-content-animated,
.ant-tabs-right-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  -webkit-transform: none !important;
      -ms-transform: none !important;
          transform: none !important;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  clear: both;
}
.ant-table-body {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-layout-fixed table {
  table-layout: fixed;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 1em;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  -webkit-transform: scale(0.91666667) rotate(0deg);
      -ms-transform: scale(0.91666667) rotate(0deg);
          transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 1em;
  line-height: 1em;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.15em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  height: 0.5em;
  line-height: 0.5em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  margin-top: 0.125em;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
  /* stylelint-disable-next-line */
  -webkit-background-clip: border-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: table;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: all 0.3s, border 0s;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #e6f7ff;
}
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  position: relative;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #e8e8e8;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 4px 4px 0 0;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
.ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
.ant-table-header table {
  border-radius: 4px 4px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
.ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: #1890ff;
  font-weight: bold;
  text-shadow: 0 0 2px #bae7ff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color: #1890ff;
}
.ant-table-filter-dropdown-link:hover {
  color: #40a9ff;
}
.ant-table-filter-dropdown-link:active {
  color: #096dd9;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  display: inline-block;
  width: 17px;
  height: 17px;
  color: inherit;
  line-height: 13px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #40a9ff;
}
.ant-table-row-expand-icon:active {
  color: #096dd9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expanded::after {
  content: '-';
}
.ant-table-row-collapsed::after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced::after {
  content: '.';
}
.ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-table-row-cell-ellipsis .ant-table-column-title {
  display: block;
}
.ant-table-row-cell-break-word {
  word-wrap: break-word;
  word-break: break-word;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) {
  color: transparent;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) > * {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 0 0 1px 0;
}
.ant-table-hide-scrollbar {
  scrollbar-color: transparent transparent;
  min-width: unset;
}
.ant-table-hide-scrollbar::-webkit-scrollbar {
  min-width: inherit;
  background-color: transparent;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 1px 1px 1px 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header.ant-table-hide-scrollbar .ant-table-thead > tr:only-child > th:last-child {
  border-right-color: transparent;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: 1;
  overflow: hidden;
  border-radius: 0;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  -webkit-box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
          box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 4px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  -webkit-box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
          box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 4px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-table colgroup > col.ant-table-selection-col {
  width: 60px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
/**
* Another fix of Firefox:
*/
@supports (-moz-appearance: meterbar) {
  .ant-table-thead > tr > th.ant-table-column-has-actions {
    background-clip: padding-box;
  }
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-content > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -8px -13px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-content > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-footer {
  background-color: transparent;
  border-top: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-footer::before {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: transparent;
  border-radius: 4px 4px 0 0;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th.ant-table-row-cell-last,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -8px -9px;
}
.ant-table-small.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  border-radius: 0 0 4px 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-radio-group {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-radio-wrapper {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-input:focus + .ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
          box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 4px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #1890ff;
}
.ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;
  -webkit-box-shadow: -1px 0 0 0 #1890ff;
          box-shadow: -1px 0 0 0 #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #1890ff !important;
  opacity: 0.1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1890ff;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #40a9ff;
  border-color: #40a9ff;
  -webkit-box-shadow: -1px 0 0 0 #40a9ff;
          box-shadow: -1px 0 0 0 #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #096dd9;
  border-color: #096dd9;
  -webkit-box-shadow: -1px 0 0 0 #096dd9;
          box-shadow: -1px 0 0 0 #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  -webkit-box-shadow: none;
          box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pagination {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: none;
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #1890ff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1890ff;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}
.ant-pagination-item-active a {
  color: #1890ff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #40a9ff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #40a9ff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  -webkit-transform: scale(1) rotate(0deg);
      -ms-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  color: #1890ff;
  letter-spacing: -1px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgba(0, 0, 0, 0.65);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #40a9ff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: 24px;
  padding: 1px 7px;
  width: 44px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
  color: rgba(0, 0, 0, 0.45);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  -webkit-box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
      -ms-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
          box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
      -ms-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

